import React from 'react';
import { useTranslation } from 'react-i18next';



export const CheckBox = ({ fieldName, handleChange, checked, label, disabled, inline }) => {
    const { t } = useTranslation();
    const emptyAction = () => {
    };
    return (
        <div onClick={!disabled ? handleChange : emptyAction} id={fieldName} className={inline ? "inline" : ""}>
            <input type="checkbox" className="control-input"
                checked={checked}
                disabled={disabled}
                readOnly={true} />
            <label className="control-label mx-1" forhtml={fieldName}>
                {t(label)}
            </label>
        </div>
    );
};
