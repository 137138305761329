import history from 'history.js';
import { ReactComponent as SupportIcon } from "icons/support-icon.svg";
import React from 'react';
import { Trans } from 'react-i18next';
import { Button } from 'reactstrap';
import ContactUsForm from './ContactUsForm';

export class ErrorBoundary extends React.Component {    
    constructor(props) {
        super(props);
        this.state = { hasError: false, isContactUsOpen: false };
        this.setShowContactUsModal = this.setShowContactUsModal.bind(this);
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
    }
    setShowContactUsModal(value) {
        this.setState({ ...this.state, isContactUsOpen: value })
    }
    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI

            return (
                <div className='d-flex align-items-center justify-content-center h-100 w-100 flex-column'>
                    <img alt="" src="img/500 Error.svg" className='thumb350' />
                    <h2 className='mt-2'>
                        <Trans i18nKey='Something_went_wrong'></Trans>
                    </h2>
                    <div className='d-flex mt-3'>
                        {this.props.showLinks &&
                            <>
                                <Button color='link' className='m-end-3' onClick={() => { history.push("/") }}>
                                    <Trans i18nKey="NavigateToHome"></Trans>
                                </Button>

                                <Button color='primary' onClick={() => this.setShowContactUsModal(true)}>
                                    <SupportIcon color='white' fill='white' />
                                    <Trans i18nKey="ContactSupport"></Trans>                                    
                                </Button>
                            </>
                        }
                    </div>
                    <ContactUsForm
                        formType={"SUPPORT"}
                        isOpen={this.state.isContactUsOpen}
                        setIsOpen={this.setShowContactUsModal}
                    />
                </div>);


        }

        return this.props.children;
    }
}