
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import httpService from "../../utils/httpService";
const userSelect = (state) => state.user;
export const isAuthenticate = createSelector(
    [userSelect],
    user => user.info && user.info.id
)

export const userHasAnyOrganization = createSelector(
    [userSelect],
    user => !user || !user.info || !user.organizations ? undefined : user.organizations.length > 0
)

export const currentUserIsAdmin = createSelector(
    [userSelect],
    user => {
        return user.info && user.info.roleName === "Administrator";
    }
)

export const userIsAdmin = createSelector(
    state => state,
    state => id => {
        return state.member.entities[id] && state.roles.entities[state.member.entities[id]._pivot_roleId].name === "Administrator";
    }
)

export const getPermissions = createSelector(
    [userSelect],
    user => {
        return user.info && user.info.permissions ? user.info.permissions : []
    }
)

export const meProjectsHasManualWorklogAccess = createSelector(
    [userSelect],
    user => () => {
        return user.projects ? user.projects.filter(a => a.permission && a.permission.enableLogManually &&
            a._pivot_status.toLowerCase() === "active" && a.status.toLowerCase() === "active") : [];
    }
)
export const userCanRemoveTimeSlotForThisProject = createSelector(
    [userSelect],
    user => (projectId) => {
        const project = user.projects.find(a => a.id === projectId);
        return project.permission.userCanRemoveTimeSlot;
    }
)
export const userCanRemoveScreenshotForThisProject = createSelector(
    [userSelect],
    user => (projectId) => {
        const project = user.projects.find(a => a.id === projectId);
        return project.permission.userCanRemoveScreenshot;
    }
)

export const getMeHeadlessInfo = createAsyncThunk('me/headlessInfo', async () => {
    const userLogs = await httpService.get(`/me/headless`);
    return userLogs.data;
})

export const changeMeHeadlessInfo = createAsyncThunk('me/headlessInfo/change', async ({ headlessToken }, { rejectWithValue }) => {
    try {
        const userLogs = await httpService.put(`/me/headless`, { headlessToken });
        return userLogs.data;
    } catch (err) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
        return rejectWithValue(error.response.data)
    }

})

export const getUserHeadlessInfo = createAsyncThunk('user/headlessInfo', async ({ userId }) => {
    const userLogs = await httpService.get(`/user/${userId}/headless`);
    return userLogs.data;
})

export const hasPermission = createSelector(
    [userSelect],
    user => (permission) => user.info.permissions.some(a => a.toLowerCase() === permission.toLowerCase())
)