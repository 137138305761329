import { APPS, PermissionsList } from "components/Common/constants";
import { ReactComponent as AccessRolesIcon } from 'icons/menu-icons/access-roles-icon.svg';
import { ReactComponent as MemberIcon } from 'icons/menu-icons/member-icon.svg';
import { ReactComponent as PersonalIcon } from 'icons/user-icon.svg';
import React from 'react';

export const usePeopleMenu = () => {
    const getItems = () => {
        const menuItems = [

            {
                name: 'User Management',
                heading: "User Management",
                customCssClass: 'menu-saved-views-header',
                appName: APPS.People.frontName,
                translate:"sidebar.User_Management",
                permissions: [PermissionsList.ORGANIZATION_USERMANAGEMENT_VIEW],
            },
            {
                name: 'Users',
                appName: APPS.People.frontName,
                permissions: [PermissionsList.ORGANIZATION_USERMANAGEMENT_VIEW],
                icon: <PersonalIcon />,
                translate: 'sidebar.Users',
                path: '/organization/user-management',

            },
            {
                name: 'Teams',
                appName: APPS.People.frontName,
                permissions: [PermissionsList.ORGANIZATION_USERMANAGEMENT_TEAM],
                icon: <MemberIcon style={{ fill: 'var(--sidebar-color)' }} />,
                translate: 'sidebar.Teams',
                path: '/organization/teams',

            },
            {
                name: 'Access Management',
                heading: "Access Management",
                customCssClass: 'menu-saved-views-header',
                appName: APPS.People.frontName,
                translate:"sidebar.Access_Management",
                permissions: [PermissionsList.ORGANIZATION_USERMANAGEMENT_ROLE],
            }, {
                name: 'Access Roles',
                appName: APPS.People.frontName,
                permissions: [PermissionsList.ORGANIZATION_USERMANAGEMENT_ROLE],
                icon: <AccessRolesIcon />,
                translate: 'sidebar.Access_Roles',
                path: '/organization/access-roles'
            },
        ];

        return menuItems;
    }
    return { getItems }
}