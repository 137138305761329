
// Helpers to change class attribute
const updateElementClass = (el, stat, name) => el && el.classList[stat ? 'add' : 'remove'](name)
const updateBodyClass = (stat, name) => updateElementClass(document.body, stat, name);

var r = document.querySelector(':root');
function getVariableValue(variableName) {
    // Get the styles (properties and values) for the root
    var rs = getComputedStyle(r);
    return rs.getPropertyValue(variableName);
}
function changeVariableValue(condition, variableName, value) {
    if (!condition) {
        return;
    }
    // Set the value of variable variableName (for example --width) to another value
    r.style.setProperty(variableName, value);
}
/**
    When a setting value is changed, detect its value and add/remove
    a classname related with that setting from the target element.
    Export this method to talk directly with the middleware
*/
export const updateClasses = state => {
    updateBodyClass(state.user.language === 'fa', 'right-to-left')
    updateBodyClass(state.user.language !== 'fa', 'left-to-right')

    updateBodyClass(state.settings.isFixed, 'layout-fixed')
    // updateBodyClass(state.settings.isBoxed, 'layout-boxed')
    // updateBodyClass(state.settings.isCollapsed, 'aside-collapsed')
    // updateBodyClass(state.settings.isCollapsedText, 'aside-collapsed-text')
    // updateBodyClass(state.settings.isFloat, 'aside-float')
    // updateBodyClass(state.settings.asideHover, 'aside-hover')
    // updateBodyClass(state.settings.offsidebarOpen, 'offsidebar-open')
    updateBodyClass(state.settings.asideToggled, 'aside-toggled')
    changeVariableValue(state.settings.appsMenuIsHidden, '--sidebar-width', getVariableValue("--sidebar-width-without-app-menu"))
    changeVariableValue(!state.settings.appsMenuIsHidden, '--sidebar-width', getVariableValue("--sidebar-width-with-app-menu"))
    changeVariableValue(state.settings.subMenuIsHidden, '--sidebar-width', state.settings.appsMenuIsHidden ? 0 : getVariableValue("--sidebar-width-without-sub-menu"))

    // layout horizontal
    // updateBodyClass(state.settings.horizontal, 'layout-h')
    // apply change to the sidebar element
    // updateElementClass(document.querySelector('.sidebar'), state.settings.asideScrollbar, 'show-scrollbar')

}

/*
    Hook into setting changes in order to change layout.
*/
const settings = store => next => action => {

    let result = next(action)
    if (action.type === 'setting/toggleSetting' || action.type === 'setting/changeSetting') {
        updateClasses(store.getState())
    }
    return result
}

export default settings;