import { APPS, PermissionsList } from 'components/Common/constants';
import React, { lazy } from 'react';
import { Redirect, Switch } from 'react-router';
import PrivateRoute from 'routes/PrivateRoute';

const myTime = lazy(() => import("apps/time/src/MyWorkDiary/MyWorkDiary"));
const Download = lazy(() => import("apps/time/src/Download/Download"));
const Upgrade = lazy(() =>
    import("apps/organization/src/Subscription/Upgrade")
);

const frontName = APPS.Time.frontName;
export const TimeRoutesList = [
    `/${frontName}`,
    `/${frontName}/mytime`,
    `/${frontName}/download`,
    `/${frontName}/upgrade`,
    "/tracker",
    "/timetracker",
    "/myworkdiary",
    `/${frontName}`,
]
const waitFor = (Tag) => (props) => <Tag {...props} />;

const TimeRoutes = () => {
    return (
        <>
            <Switch>

                <PrivateRoute
                    permissions={[PermissionsList.TIME_MYWORKDAIRY_VIEW]}
                    exact
                    path={`/${frontName}/mytime`}
                    component={waitFor(myTime)}
                />
                <PrivateRoute
                    permissions={[PermissionsList.TIME_DOWNLOAD_VIEW]}
                    exact
                    path="/time/download"
                    component={waitFor(Download)}
                />
                <PrivateRoute
                    permissions={[PermissionsList.ORGANIZATION_SUBSCRIPTION_VIEW]}
                    exact
                    path="/time/upgrade"
                    component={waitFor(Upgrade)}
                />
                <Redirect
                    path="/timetracker"
                    to={`/${frontName}/mytime`}
                />
                <Redirect
                    path="/tracker"
                    to={`/${frontName}/mytime`}
                />

                <Redirect
                    path="/myworkdiary"
                    to={`/${frontName}/mytime`}
                />
                <Redirect
                    path="/tracker/myworkdiary"
                    to={`/${frontName}/mytime`}
                />
                <Redirect
                    path="/timetracker/myworkdiary"
                    to={`/${frontName}/mytime`}
                />
                <Redirect
                    path={`/${frontName}/myworkdiary`}
                    to={`/${frontName}/mytime`}
                />
                <Redirect
                    path={`/${frontName}`}
                    to={`/${frontName}/mytime`}
                />


            </Switch>
        </>
    )
}

export default TimeRoutes
