import { useDashboardAppMenu } from 'apps/dashboard/appMenu'
import { useGoalAppMenu } from 'apps/goals/appMenu'
import { useTimeAppMenu } from 'apps/time/appMenu'

import { usePeopleAppMenu } from 'apps/peopleApp/appMenu'
import { useProjectAppMenu } from 'apps/projects/appMenu'
import { PermissionsList } from 'components/Common/constants'
import PermissionComponent from 'components/Common/PermissionComponent'
import { ReactComponent as BillingIcon } from 'icons/menu-icons/billing-icon.svg'
import { ReactComponent as MemberIcon } from 'icons/menu-icons/member-icon.svg'
import { ReactComponent as SettingsIcon } from 'icons/menu-icons/settings-icon.svg'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { useAppHelper } from 'utils/useAppHelper'

export const AppsMenu = () => {
    const { t } = useTranslation();
    const routeActive = (paths) => {
        if ("/" + currentAppRoute === paths) {
            return true;
        }
        return false;
    }
    // const [appActiveMatch, setAppActiveMatch] = useState(false);

    const { hasUserThisApp } = useAppHelper();
    const location = useLocation();
    const appsMenuIsHidden = useSelector(state => state.settings.appsMenuIsHidden);
    // const [tooltipsOpen, setTooltipsOpen] = useState({});

    const goalAppMenu = useGoalAppMenu();
    const timeAppMenu = useTimeAppMenu();
    const peopleAppMenu = usePeopleAppMenu();
    const projectAppMenu = useProjectAppMenu();
    const dashboardAppMenu = useDashboardAppMenu();
    const appsMenuItems = [
        ...peopleAppMenu.getItems(),
        ...dashboardAppMenu.getItems(),
        ...timeAppMenu.getItems(),
        ...projectAppMenu.getItems(),
        ...goalAppMenu.getItems(),

    ];

    const userAppMenuItems = appsMenuItems.filter(a => hasUserThisApp(a.appRoute));
    const [currentAppRoute, setCurrentAppRoute] = useState(false)
    const userAppMenuItemsJson = JSON.stringify([...userAppMenuItems.map(a => a.appRoute), 'organization/user-management',
        'organization/teams', 'organization/access-roles', 'organization'])
    useEffect(() => {
        const userAppsRoutes = JSON.parse(userAppMenuItemsJson);
        userAppsRoutes.sort(function (a, b) {
            // ASC  -> a.length - b.length
            // DESC -> b.length - a.length
            return a.length - b.length;
        }).forEach(path => {
            const isActive = window.location.pathname.startsWith("/" + path);
            if (isActive) setCurrentAppRoute(path);
        });
    }, [location.pathname, userAppMenuItemsJson])

    return (
        <div className={`apps-menu text-center ${appsMenuIsHidden ? 'd-none' : ''}`}>
            <ul className="p-start-0">
                <li className='apps-menu-header'>
                </li>
                {userAppMenuItems.map(menu => (
                    <Link to={`/${menu.defaultRoute}`} key={menu.title} id={menu.title}>
                        <>
                            {/* <Tooltip placement="auto" isOpen={tooltipsOpen[menu.title]} target={menu.title} toggle={() => setTooltipsOpen({ ...tooltipsOpen, [menu.title]: !tooltipsOpen[menu.title] })}>
                                {t(menu.title)}
                            </Tooltip> */}
                            <li className={`apps-menu-item ${(routeActive(`/${menu.appRoute}`) ? 'active' : '')}`} >
                                {menu.icon}
                                <span>{t(menu.title)}</span>
                            </li>
                        </>
                    </Link>
                ))}
            </ul>
            <div className='apps-menu-bottom'>
                <PermissionComponent permissions={[
                    PermissionsList.ORGANIZATION_USERMANAGEMENT_TEAM,
                    PermissionsList.ORGANIZATION_USERMANAGEMENT_VIEW,
                    PermissionsList.ORGANIZATION_USERMANAGEMENT_ROLE
                ]}>
                    <Link to="/organization/user-management">
                        <div className={`apps-menu-bottom-item 
                        ${((routeActive(`/${'organization/user-management'}`) || routeActive(`/${'organization/teams'}`) ||
                                routeActive(`/${'organization/access-roles'}`))
                                ? 'active' : '')}`}>
                            <MemberIcon style={{ fill: 'var(--sidebar-color)' }} />
                            <span>{t("sidebar.Users")}</span>
                        </div>
                    </Link>
                </PermissionComponent>
                <PermissionComponent permissions={[PermissionsList.ORGANIZATION_SUBSCRIPTION_VIEW]}>
                    <Link to="/organization/subscription">
                        <div className={`apps-menu-bottom-item ${(routeActive(`/${'/organization/subscription'}`) ? 'active' : '')}`}>
                            <BillingIcon />
                            <span>{t("Billing")}</span>
                        </div>
                    </Link>
                </PermissionComponent>
                <PermissionComponent permissions={[PermissionsList.ORGANIZATION_SETTING_UPADTE]}>

                    <Link to="/organization">
                        <div className={`apps-menu-bottom-item ${(routeActive(`/${'organization'}`) ? 'active' : '')}`}>
                            <SettingsIcon />
                            <span>{t("sidebar.Settings")}</span>
                        </div>
                    </Link>
                </PermissionComponent>
            </div>
        </div >
    )
}
