import { createAsyncThunk, createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { normalize, schema } from 'normalizr'
import httpService from "utils/httpService"

const projectsAdapter = createEntityAdapter()

const projectSchema = new schema.Entity('projects')
const emptyWorkLog = { today: 0, thisWeek: 0, thisMonth: 0, total: 0 };
const deleteRemovedTeamMembers = projects => {
    projects.forEach(project => {
        project.team = project.team.filter(a => a.status !== 'removed');
    });
}

export const loadProjects = createAsyncThunk('projects', async (_) => {
    const response = await httpService.get(`/organizations/projects`);
    deleteRemovedTeamMembers(response.data);
    const workLogs = await httpService.get('/workLogs/projects/all');
    response.data.forEach(project => {
        let workLog = workLogs.data.allProjectLogs.find(a => a.projectId === project.id);
        if (!workLog) {
            workLog = emptyWorkLog;
        }
        project.workLog = workLog;
    });
    const normalized = normalize(response.data, [projectSchema])
    return normalized.entities
})

export const loadProjectsWorkLogs = createAsyncThunk('projects/loadWorklogs', async () => {
    const workLogs = await httpService.get('/workLogs/projects/all');
    return workLogs.data.allProjectLogs;
})

export const addProject = createAsyncThunk('projects/add', async (project, { rejectWithValue }) => {
    try {
        const response = await httpService.post('/projects', project)
        response.data.data.userId = project.userId;
        response.data.data.roleInProject = "admin";
        return { data: response.data.data, team: [] }
    } catch (err) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
        return rejectWithValue(error.response.data)
    }
})

export const addDemoProject = createAsyncThunk('projects/addDemo', async (_, { rejectWithValue }) => {
    try {
        const response = await httpService.post('/projects/demo')
        // response.data.data.userId = project.userId;
        // response.data.data.roleInProject = "admin";
        return { data: response.data.data }
    } catch (err) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
        return rejectWithValue(error.response.data)
    }
})

export const updateProject = createAsyncThunk('projects/update', async (project) => {
    const response = await httpService.put(`/projects/${project.id}`, project)
    return response.data
})

export const addMemberToProjectTeam = createAsyncThunk('projects/teams/addMember', async (requestModel) => {

    const members = requestModel.members;
    const projects = requestModel.projects;

    const defaultRoleInProject = 'member'
    for (let index = 0; index < members.length; index++) {
        const memberId = members[index];
        var dataToRequest = {
            user_id: memberId,
            role: defaultRoleInProject
        };
        for (let projectIndex = 0; projectIndex < projects.length; projectIndex++) {
            await httpService.post(`/projects/${projects[projectIndex]}/teams`, dataToRequest)
        }

    }

    return {
        members: members.map(a => { return { id: parseInt(a), status: "active", role: defaultRoleInProject } }),
        projects: projects
    }
})


export const addContractToMember = createAsyncThunk('projects/teams/rate', async (requestModel) => {

    const projectId = requestModel.projectId;
    const dataToRequest = requestModel.dataToRequest;

    await httpService.put(`/projects/${projectId}/teams/rate`, dataToRequest)

    return {
        projectId: projectId,
        memberIds: dataToRequest.userIds,
        contract: { rate: dataToRequest.rate, isOverwritten: dataToRequest.isOverwritten }
    }
})

export const getProjectPermissions = createAsyncThunk('project/permissions', async ({ projectId }) => {
    const projectPermissions = await httpService.get(`/permissions/project/${projectId}`);
    return projectPermissions.data;
})


async function changeAccessToProject(projectId, memberId, status) {

    var dataToRequest = {
        user_id: memberId,
        status: status
    };
    await httpService.put(`/projects/${projectId}/teams`,
        dataToRequest
    )

    return {
        memberId: memberId,
        projectId: projectId,
        status: status
    }
}

async function changeRoleInProject(projectId, memberId, role) {

    var dataToRequest = {
        user_id: memberId,
        role: role
    };
    await httpService.put(`/projects/${projectId}/teams`,
        dataToRequest
    )

    return {
        memberId: memberId,
        projectId: projectId,
        role: role
    }
}

export const pauseMemberFromProjectTeam = createAsyncThunk('projects/teams/pauseAccess', async ({ projectId, memberId }) => {
    const newStatus = 'pause';
    return await changeAccessToProject(projectId, memberId, newStatus)
})

export const resumeMemberFromProjectTeam = createAsyncThunk('projects/teams/resumeAccess', async ({ projectId, memberId }) => {
    const newStatus = 'active';
    return await changeAccessToProject(projectId, memberId, newStatus)
})

export const makeUserAdminInProjectTeam = createAsyncThunk('projects/teams/grantAdminAccess', async ({ projectId, memberId }) => {
    const newRole = 'admin';
    return await changeRoleInProject(projectId, memberId, newRole)
})

export const makeUserMemberInProjectTeam = createAsyncThunk('projects/teams/removeAdminAccess', async ({ projectId, memberId }) => {
    const newRole = 'member';
    return await changeRoleInProject(projectId, memberId, newRole)
})

export const removeMemberFromProjectTeam = createAsyncThunk('projects/teams/removeMember', async ({ projectId, memberId }) => {
    var dataToRequest = {
        user_id: memberId,
        role: 'member'
    };
    await httpService.delete(`/projects/${projectId}/teams`, {
        data: dataToRequest
    })

    dataToRequest = {
        user_id: memberId,
        role: 'admin'
    };
    await httpService.delete(`/projects/${projectId}/teams`, {
        data: dataToRequest
    })

    return {
        memberId: memberId,
        projectId: projectId
    }
})

export const uploadProjectLogo = createAsyncThunk('projects/uploadLogo', async ({ projectId, logo }) => {
    const formData = new FormData();
    formData.append('avatar', logo);
    const response = await httpService.post(`/projects/${projectId}/picture`, formData)
    return { projectId, photo: response.data.data.photo }
})
export const removeProjectLogo = createAsyncThunk('projects/removeLogo', async (projectId) => {
    const response = await httpService.delete(`/projects/${projectId}/picture`)
    return { projectId, photo: response.data.data.photo }
})

export const bookmarProject = createAsyncThunk('projects/bookmark', async (projectId) => {
    await httpService.post(`/projects/${projectId}/bookmark`)
    return { projectId }
});
export const deleteBookmarkProject = createAsyncThunk('projects/deleteBookmark', async (projectId) => {
    await httpService.delete(`/projects/${projectId}/bookmark`)
    return { projectId }
});

const projectSlice = createSlice({
    name: 'project',
    initialState: projectsAdapter.getInitialState(),
    reducers: {
        resetProjects(state) {
            projectsAdapter.setAll(state, []);
        }
    },
    extraReducers: builder => {
        builder.addCase(loadProjects.fulfilled, (state, action) => {
            projectsAdapter.setAll(state, action.payload.projects || [])
        }).addCase(addProject.fulfilled, (state, action) => {
            projectsAdapter.addOne(state, {
                ...action.payload.data, team: action.payload.team, workLog: emptyWorkLog
            })
        }).addCase(addDemoProject.fulfilled, (state, action) => {
            projectsAdapter.addOne(state, {
                ...action.payload.data, workLog: emptyWorkLog, team: []
            })
        }).addCase(updateProject.fulfilled, (state, action) => {
            projectsAdapter.updateOne(state, {
                id: action.payload.data.id,
                changes: action.payload.data
            })
        }).addCase(bookmarProject.fulfilled, (state, action) => {
            projectsAdapter.updateOne(state, {
                id: action.payload.projectId,
                changes: { bookmark: true }
            })
        }).addCase(deleteBookmarkProject.fulfilled, (state, action) => {
            projectsAdapter.updateOne(state, {
                id: action.payload.projectId,
                changes: { bookmark: false }
            })
        }).addCase(loadProjectsWorkLogs.fulfilled, (state, action) => {
            projectsAdapter.updateMany(state, action.payload.map(worklog => {
                return {
                    id: worklog.projectId, changes: {
                        workLog: worklog
                    }
                }
            }))
        }).addCase(addMemberToProjectTeam.fulfilled, (state, action) => {
            const { members, projects } = action.payload;


            projectsAdapter.updateMany(state, projects.map(projectId => {
                return {
                    id: projectId, changes: {
                        team: [...state.entities[projectId].team, ...members]
                    }
                }
            }))

        }).addCase(addContractToMember.fulfilled, (state, action) => {
            const { memberIds, contract, projectId } = action.payload;

            const newTeam = state.entities[projectId].team;
            const teamMembers = newTeam.filter(a => memberIds.some(b => parseInt(b) === parseInt(a.id)));
            teamMembers.map(a => a.contract = contract);
            projectsAdapter.updateOne(state, {
                id: projectId, changes: {
                    team: newTeam
                }
            })

        }).addCase(uploadProjectLogo.fulfilled, (state, action) => {
            const { projectId, photo } = action.payload
            projectsAdapter.updateOne(state, {
                id: projectId,
                changes: {
                    photo: photo + "?rand=" + Date.now()
                }
            })
        }).addCase(removeProjectLogo.fulfilled, (state, action) => {
            const { projectId, photo } = action.payload
            projectsAdapter.updateOne(state, {
                id: projectId,
                changes: {
                    photo: photo
                }
            })
        }).addCase(removeMemberFromProjectTeam.fulfilled, (state, action) => {
            const { memberId, projectId } = action.payload
            const newTeamList = state.entities[projectId].team.filter(a => parseInt(a.id) !== parseInt(memberId));
            projectsAdapter.updateOne(state, {
                id: projectId,
                changes: {
                    team: newTeamList
                }
            })
        }).addMatcher(isAnyOf(pauseMemberFromProjectTeam.fulfilled, resumeMemberFromProjectTeam.fulfilled), (state, action) => {
            const { memberId, projectId, status } = action.payload;
            const changedMember = state.entities[projectId].team.find(a => parseInt(a.id) === parseInt(memberId));
            changedMember.status = status;
        }).addMatcher(isAnyOf(makeUserAdminInProjectTeam.fulfilled, makeUserMemberInProjectTeam.fulfilled), (state, action) => {
            const { memberId, projectId, role } = action.payload;
            try {
                const pausedMember = state.entities[projectId].team.find(a => parseInt(a.id) === parseInt(memberId));
                pausedMember.role = role;
            } catch (error) {
                console.error(error)
            }

        }).addMatcher(isAnyOf(
            loadProjects.pending,
            addProject.pending,
            addDemoProject.pending,
            updateProject.pending,
            addMemberToProjectTeam.pending,
            addContractToMember.pending,
            removeMemberFromProjectTeam.pending,
            pauseMemberFromProjectTeam.pending
        ), (state) => { state.loading = true })
            .addMatcher(isAnyOf(
                loadProjects.rejected,
                addProject.rejected,
                addDemoProject.rejected,
                updateProject.rejected,
                addMemberToProjectTeam.rejected,
                addContractToMember.rejected,
                removeMemberFromProjectTeam.rejected,
                pauseMemberFromProjectTeam.rejected,
                loadProjects.fulfilled,
                addProject.fulfilled,
                addDemoProject.fulfilled,
                updateProject.fulfilled,
                addMemberToProjectTeam.fulfilled,
                addContractToMember.fulfilled,
                removeMemberFromProjectTeam.fulfilled,
                pauseMemberFromProjectTeam.fulfilled
            )
                , (state) => { state.loading = false })
    }
})
export const { resetProjects } = projectSlice.actions;

export default projectSlice.reducer
