
import { createSelector } from "reselect";
import { OrganizationProppertiesDefinitions } from "components/Common/constants";
const currentOrganization = (state) => state.organization.currentOrganization;
export const getOrganizationProperties = createSelector(
    [currentOrganization],
    currentOrganization => currentOrganization && currentOrganization.entity && currentOrganization.entity.properties ? currentOrganization.entity.properties : {}
)

export const getScreenshotEnabledInOrganization = createSelector(
    [currentOrganization],
    currentOrganization => {
        return currentOrganization && currentOrganization.entity &&
            currentOrganization.entity.properties &&
            currentOrganization.entity.properties[OrganizationProppertiesDefinitions.TIMETRACKER_SCREENSHOT_ENABLED];
    }
)