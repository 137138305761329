import { createAsyncThunk, createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { normalize, schema } from 'normalizr';
import { TeamListDto, TeamModel } from 'apps/people/models/TeamModel';
import httpService from "utils/httpService";

const teamAdapter = createEntityAdapter<TeamModel>()

const teamEntity = new schema.Entity<TeamModel>('teams')

export const loadAllTeams = createAsyncThunk('teams/get', async (_) => {
    const response = await httpService.get<TeamListDto>(`/teams`);
    const normalized = normalize(response.data.teams, [teamEntity])
    return normalized.entities
})

export const addTeam = createAsyncThunk('teams/add', async (team: any) => {
    const response = await httpService.post(`/teams`, team)
    return response.data
})

export const updateTeam = createAsyncThunk('teams/edit', async (team: any, { rejectWithValue }) => {
    try {
        const response = await httpService.put(`/teams/${team.id}`, team)
        return response.data
    } catch (err: any) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
        return rejectWithValue(error.response.data)
    }

})

const teamSlice = createSlice({
    name: 'team',
    initialState: teamAdapter.getInitialState(),
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(loadAllTeams.fulfilled, (state, action) => {
                teamAdapter.setAll(state, action.payload.teams || [])
            }).addCase(addTeam.fulfilled, (state, action) => {
                teamAdapter.addOne(state, { ...action.payload.data.team })
            }).addCase(updateTeam.fulfilled, (state, action) => {
                teamAdapter.updateOne(state, {
                    id: action.payload.data.team.id,
                    changes: action.payload.data.team
                })
            }).addMatcher(isAnyOf(
                loadAllTeams.pending, addTeam.pending, updateTeam.pending
            ), (state: any) => {
                state.loading = true
            })
            .addMatcher(isAnyOf(
                loadAllTeams.rejected, loadAllTeams.fulfilled,
                addTeam.rejected, addTeam.fulfilled,
                updateTeam.rejected, updateTeam.fulfilled
            ), (state: any) => {
                state.loading = false;
            })
    }
})

export default teamSlice.reducer