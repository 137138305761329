import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import httpService from "../../utils/httpService";
const initialState = {
    config: { subscription: { stripe: { publishableKey: "" } } }
}

export const loadServerConfig = createAsyncThunk('serverConfig/load', async () => {
    const response = await httpService.get('/config')
    return response.data;

})

const serverConfigSlice = createSlice({
    name: 'serverConfig',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(loadServerConfig.fulfilled, (state, action) => {
                state.config = action.payload
            })
    }
})

export default serverConfigSlice.reducer