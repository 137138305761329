import React, { useCallback, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Badge, Collapse } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";

import SidebarRun from "./Sidebar.run";

import useMenu from "../../Menu";
import { changeSetting } from "../../store/actions";
import { getPermissions } from "store/selectors";
import { AppsMenu } from "./AppsMenu";
/** Component to display headings on sidebar */
const SidebarItemHeader = ({ item }) => (
    <li className={`nav-heading ${item.customCssClass}`}>
        <span>
            <Trans i18nKey={item.translate}>{item.heading}</Trans>
        </span>
    </li>
);

const showIcon = (Icon) => {
    return <em className="m-end-1">{Icon}</em>;
};
/** Normal items for the sidebar */
const SidebarItem = ({ item, isActive }) => (
    <li className={"menu-item " + (isActive ? "active" : "") + " " + (item.className ? item.className : "") + " " + item.customCssClass}>
        <Link to={item.path} className="cut-text-200">
            {item.label && (
                <Badge
                    tag="div"
                    className="float-right"
                    color={item.label.color}
                >
                    {item.label.value}
                </Badge>
            )}
            {/* {item.icon && item.icon.contains("-svg-") && <em className={item.icon}></em>} */}
            {item.icon && typeof item.icon === "string" && (
                <em className={item.icon}></em>
            )}
            {item.icon && typeof item.icon !== "string" && showIcon(item.icon)}
            <span >
                <Trans i18nKey={item.translate}>{item.name}</Trans>
            </span>
        </Link>
    </li>
);

const SidebarCustomItem = ({ item, isActive }) => (
    <li className={" " + (isActive ? "active" : "") + " " + (item.className ? item.className : "") + " " + item.customCssClass}>
        {/* <div className="nav-item"> */}
        {item.customContent}
        {/* </div> */}
    </li>
);

/** Build a sub menu with items inside and attach collapse behavior */
const SidebarSubItem = ({ item, isActive, handler, children, isOpen }) => (
    <li className={isActive ? "active" : ""}>
        <div className="nav-item" onClick={handler}>
            {item.label && (
                <Badge
                    tag="div"
                    className="float-right"
                    color={item.label.color}
                >
                    {item.label.value}
                </Badge>
            )}
            {item.icon && <em className={item.icon}></em>}
            <span>
                <Trans i18nKey={item.translate}>{item.name}</Trans>
            </span>
        </div>
        <Collapse isOpen={isOpen}>
            <ul id={item.path} className="sidebar-nav sidebar-subnav">
                {children}
            </ul>
        </Collapse>
    </li>
);

/** Component used to display a header on menu when using collapsed/hover mode */
const SidebarSubHeader = ({ item }) => (
    <li className="sidebar-subnav-header">{item.name}</li>
);

const Sidebar = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const permissions = useSelector(getPermissions);
    const { Menu } = useMenu(
        permissions
    );
    const [collapse, setCollapse] = useState()


    const closeSidebar = useCallback(() => {
        dispatch(changeSetting({ name: 'asideToggled', value: false }));
    }, [dispatch]);
    /** prepare initial state of collapse menus. Doesnt allow same route names */
    const navigator = useCallback((route) => {
        history.push(route.replace("#", "")); // remove '#' in case of use HashRouter
    }, [history]);
    const routeActive = useCallback((paths) => {
        paths = Array.isArray(paths) ? paths : [paths];
        return paths.some((p) => location.pathname === p);
    }, [location.pathname]);
    const menuJson = JSON.stringify(Menu.map(a => ({ heading: a.heading, path: a.path, submenu: a.submenu })));
    const buildCollapseList = useCallback(() => {
        const menu = JSON.parse(menuJson);
        let collapse = {};
        menu.filter(({ heading }) => !heading)
            .forEach(({ name, path, submenu }) => {
                collapse[name] = routeActive(
                    submenu ? submenu.map(({ path }) => path) : path
                );
            });
        setCollapse(collapse)
    }, [menuJson, routeActive]);
    useEffect(() => {
        // pass navigator to access router api
        SidebarRun(navigator, closeSidebar);
        // prepare the flags to handle menu collapsed states
        buildCollapseList();
        // Listen for routes changes in order to hide the sidebar on mobile
        history.listen(closeSidebar);
    }, [buildCollapseList, closeSidebar, navigator, history])



    const getSubRoutes = (item) => item.submenu.map(({ path }) => path);

    /** map menu config to string to determine which element to render */
    const itemType = (item) => {
        if (item.customContent) return "custom";
        if (item.heading) return "heading";
        if (!item.submenu) return "menu";
        if (item.submenu) return "submenu";
    };

    return (
        <aside
            className="aside-container "
            id="aside-container"
        >
            {/* START Sidebar (left) */}
            <div className="aside-inner">
                <nav data-sidebar-anyclick-close="" className="sidebar ">
                    <div className="sidebar-container">
                        <AppsMenu />
                        {Menu.length > 0 &&
                            <ul className="sidebar-nav app-inner-menu-container">
                                {/* START user info */}

                                {/* <li className="d-flex flex-row justify-content-center mb-3">

                                <OrganizationSelector></OrganizationSelector>
                            </li> */}
                                {/* END user info */}

                                {/* Iterates over all sidebar items */}
                                {Menu.map((item, i) => {
                                    // heading
                                    if (itemType(item) === "heading")
                                        return (
                                            <SidebarItemHeader
                                                item={item}
                                                key={i}
                                            />
                                        );
                                    else {
                                        if (itemType(item) === 'custom') {
                                            return (
                                                <SidebarCustomItem
                                                    isActive={routeActive(
                                                        item.path
                                                    )}
                                                    item={item}
                                                    key={i}
                                                />
                                            );
                                        }
                                        if (itemType(item) === "menu")
                                            return (
                                                <SidebarItem
                                                    isActive={routeActive(
                                                        item.path
                                                    )}
                                                    item={item}
                                                    key={i}
                                                />
                                            );
                                        if (itemType(item) === "submenu" && collapse)
                                            return [
                                                <SidebarSubItem
                                                    item={item}
                                                    isOpen={
                                                        collapse[
                                                        item.name
                                                        ]
                                                    }
                                                    handler={() => {
                                                        const newCollapse = { ...collapse };
                                                        newCollapse[item.name] = !collapse[item.name];
                                                        setCollapse(newCollapse);
                                                    }}
                                                    isActive={routeActive(
                                                        getSubRoutes(item)
                                                    )}
                                                    key={i}
                                                >
                                                    <SidebarSubHeader
                                                        item={item}
                                                        key={i}
                                                    />
                                                    {item.submenu.map(
                                                        (subitem, i) => (
                                                            <SidebarItem
                                                                key={i}
                                                                item={subitem}
                                                                isActive={routeActive(
                                                                    subitem.path
                                                                )}
                                                            />
                                                        )
                                                    )}
                                                </SidebarSubItem>,
                                            ];
                                    }
                                    return null; // unrecognized item
                                })}
                            </ul>
                        }
                    </div>
                </nav>
            </div>
            {/* END Sidebar (left) */}
        </aside>
    );
}


// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(withTranslation("translations")(withRouter(Sidebar)));
export default Sidebar;
