import React from 'react';
import UsersAvatars from 'components/Common/UserAvatars';

const TeamAvatars = ({ project }: { project: any }) => {
    return <UsersAvatars userIds={project.team.map((a: any) => a.id)} />
};

TeamAvatars.propTypes = {};

export default TeamAvatars;

