import React from 'react'
import UserAvatar from 'components/Common/UserAvatar';
const MemberLogo = ({ logo, name, status, thumbSize = 40 }: { logo: string, name: string, status: string, thumbSize?: number }) => {
    const IMG = () => (
        <UserAvatar src={logo} name={name} size={thumbSize} round={true} />
    )
    return (
        <>
            {status === 'pause' ?
                <div className="pausedMemberLogoContainer">
                    <IMG></IMG>
                    <div className="middle">
                        <i className="fa fa-pause text"></i>
                    </div>
                </div>
                : <IMG></IMG>}
        </>
    )
}
export default MemberLogo