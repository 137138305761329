import React from 'react'
import { WithContext as ReactTags } from 'react-tag-input';

const TagInput = ({
    tags, placeholder, suggestions, handleAddition, handleDelete, handleInputBlur,
    handleDrag,
    filterSuggesstionFields, creatable }) => {

    function handleFilterSuggestions(textInputValue, possibleSuggestionsArray) {
        var lowerCaseQuery = textInputValue.toLowerCase()
        return possibleSuggestionsArray.filter(function (suggestion) {
            const finded = filterSuggesstionFields.some(field => suggestion[field] && suggestion[field].toLowerCase().includes(lowerCaseQuery));
            return finded;
        })
    }
    const Keys = {
        TAB: 9,
        SPACE: 32,
        COMMA: 188,
    };
    return (
        <ReactTags
            delimiters={[Keys.TAB, Keys.SPACE, Keys.COMMA]}
            minQueryLength={0}
            tags={tags}
            placeholder={placeholder}
            renderSuggestion={({ text }, query) => <div style={{ textDecoration: '', textDecorationStyle: '' }}>{text}</div>}
            suggestions={suggestions}
            handleAddition={handleAddition}
            handleInputBlur={handleInputBlur}
            handleDelete={handleDelete}
            handleDrag={handleDrag}
            removeComponent={TagInputRemoveComponent}
            handleFilterSuggestions={creatable ? handleFilterSuggestions : null}

        >

        </ReactTags>
    )
}

class TagInputRemoveComponent extends React.Component {
    render() {

        let props = { ...this.props, onClick: this.props.onRemove }
        props.className += " fas fa-times-circle p-start-1"
        delete props.onRemove
        delete props.removeComponent;
        return (
            <i {...props}></i>
        )
    }
}

export default TagInput
