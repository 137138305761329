import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { getPermissions, isProjectAdmin } from 'store/selectors';
import PropTypes from 'prop-types'
import { Button } from 'reactstrap';
import { useHistory } from 'react-router';
import { ReactComponent as SupportIcon } from "icons/support-icon.svg";
import ContactUsForm from 'components/Layout/ContactUsForm';
import { Trans } from 'react-i18next';

class PermissionComponent extends Component {

    render() {
        const { permissions, userPermissions, projectId, children, isProjectAdmin, showAccessDeniedImage } = this.props;


        if (!permissions || !permissions.some || !userPermissions || !userPermissions.some) {
            return null;
        }
        if (!userPermissions.some(a => permissions.some(b => b.toLowerCase() === a.toLowerCase())) && (!projectId || !isProjectAdmin(projectId))) {
            return showAccessDeniedImage ? <AccessDenied showLinks={true} /> : null;
        }

        return <>{children}</>;
    }
}

PermissionComponent.propTypes = {
    permissions: PropTypes.array,
    projectId: PropTypes.number,
    showAccessDeniedImage: PropTypes.bool,
    children: PropTypes.any
}

const mapStateToProps = state => ({
    userPermissions: getPermissions(state),
    isProjectAdmin: projectId => isProjectAdmin(state)(projectId)
})

const mapDispatchToProps = () => ({
    actions: {
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PermissionComponent);


export const AccessDenied = ({ showLinks, resourceName = 'page' }) => {
    const [isContactUsOpen, setIsContactUsOpen] = useState(false);
    const history = useHistory();
    return (
        <div className='d-flex align-items-center justify-content-center h-100 w-100 flex-column'>
            <img alt="" src="img/401 Error.svg" className='thumb350' />
            <h2 className='mt-2'>You're not authorized to access this {resourceName}.</h2>
            <div className='d-flex mt-3'>
                {showLinks &&
                    <>
                        <Button color='link' className='m-end-3' onClick={() => { history.push("/") }}>
                            <Trans i18nKey="NavigateToHome"></Trans>
                        </Button>

                        <Button color='primary' onClick={() => setIsContactUsOpen(true)}>
                            <SupportIcon color='white' fill='white' />
                            <Trans i18nKey="ContactSupport"></Trans>
                        </Button>
                    </>
                }
            </div>
            <ContactUsForm
                formType={"SUPPORT"}
                isOpen={isContactUsOpen}
                setIsOpen={setIsContactUsOpen}
            />
        </div>);
}