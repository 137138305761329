import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    path: 'themes/theme-a.css'
};

const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        changeTheme(state, action) {
            state.path = action.payload;
        }
    }
})

export const { changeTheme } = themeSlice.actions

export default themeSlice.reducer