import React, { useMemo } from 'react';
import Avatar from 'react-avatar';
import { defaultAvatar } from './constants';

const UserAvatar = (props: any) => {
  const { src, ...others } = props;
  let srcToShow = src;
  if ([defaultAvatar.defaultAvatars.PROJECT, defaultAvatar.defaultAvatars.USER].some(a => a === srcToShow) && props.name) {
    srcToShow = null;
  }
  const othersJson = JSON.stringify(others);

  const memorizedAvatar = useMemo(() => <Avatar src={srcToShow} {...JSON.parse(othersJson)} />, [srcToShow, othersJson])
  return memorizedAvatar;
};

export default UserAvatar;
