import { combineReducers } from 'redux';
import organizationreducer from 'apps/organization/store/organization.slice'
import okrSavedViews from 'apps/goals/store/saveView.slice';
import teamsReducer from 'apps/people/store/team.slice';
import memberReducer from 'apps/people/store/member.slice'
import roleReducer from 'apps/people/store/role.slice'
import projectReducer from 'apps/time/store/project.slice'
import reportsReducer from 'apps/time/store/reports.slice.js';

import settingsReducer from './slices/settings.slice.js';
import themesReducer from './slices/themes.slice'
import userReducer from './slices/user.slice'

import productReducer from './slices/product.slice';
import serverConfigReducer from './slices/serverConfig.slice';
import notificationReducer from './slices/notification.slice';



export default combineReducers({
    //local
    theme: themesReducer,
    settings: settingsReducer,
    //configs
    serverConfig: serverConfigReducer,
    product: productReducer,
    //general
    notification: notificationReducer,
    //organization
    organization: organizationreducer,
    //people
    roles: roleReducer,
    teams: teamsReducer,
    member: memberReducer,
    //user
    user: userReducer,
    //time
    project: projectReducer,
    reports: reportsReducer,
    //okr
    okrSavedViews: okrSavedViews,
});
