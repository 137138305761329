import { createAsyncThunk } from '@reduxjs/toolkit'
import httpService from "utils/httpService";

export const createOKRGoal = createAsyncThunk('okr/goal', async (goal) => {
    const response = await httpService.post(`/okr/goal`, goal);
    return response.data
})

export const editOKRGoal = createAsyncThunk('okr/goal/edit', async (goal: any) => {
    const response = await httpService.put(`/okr/goal/${goal.id}`, goal);
    return response.data
})

export const createOKRSubgoal = createAsyncThunk('okr/subgoal', async (goal) => {
    const response = await httpService.post(`/okr/subgoal`, goal);
    return response.data
})

export const editOKRSubgoal = createAsyncThunk('okr/subgoal/edit', async (goal: any) => {
    const response = await httpService.put(`/okr/subgoal/${goal.id}`, goal);
    return response.data
})

export const getAllOKR = createAsyncThunk('okr', async () => {
    const response = await httpService.get(`/okr`);
    return response.data.goals
})

export const getOKRBaseData = createAsyncThunk('okr/base', async () => {
    const response = await httpService.get(`/okr/base`);
    return response.data.base
})

export const updateOkrStatus = createAsyncThunk('okr/updateStatus', async ({ okrId, status, transactionId }: { okrId: number, status: string, transactionId: string }) => {
    const response = await httpService.put(`/okr/goal/${okrId}/status`, { status, transactionId });
    return response.data
})

export const updateOkrValue = createAsyncThunk('okr/updateValue', async ({ okrId, newValue, transactionId }: { okrId: number, newValue: number, transactionId: string }) => {
    const response = await httpService.put(`/okr/goal/${okrId}/current`, { value: newValue, transactionId });
    return response.data
})

export const updateOkrDescription = createAsyncThunk('okr/updateDescription', async ({ okrId, description, transactionId }: { okrId: number, description: string, transactionId: string }) => {
    const response = await httpService.put(`/okr/goal/${okrId}/description`, { description, transactionId });
    return response.data
})

export const updateOkrOwner = createAsyncThunk('okr/updateOwner', async ({ okrId, ownerId }: { okrId: number, ownerId: number }) => {
    const response = await httpService.put(`/okr/goal/${okrId}/owner`, { ownerId });
    return response.data
})

export const getOkrReport = createAsyncThunk('okr/report', async (okrId: number) => {
    const response = await httpService.get(`/okr/report/${okrId}`);
    return response.data
})

export const deleteGoal = createAsyncThunk('okr', async ({ okrId }: { okrId: number }) => {
    const response = await httpService.delete(`/okr/${okrId}`);
    return response.data;
})