import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import httpService from "utils/httpService";

const savedOkrAdapter = createEntityAdapter({})

export const saveViewOKR = createAsyncThunk('okr/saveview/add', async (view) => {
    const response = await httpService.post(`/okr/saveview`, view);
    return response.data;
})

export const editViewOKR = createAsyncThunk('okr/saveview/edit', async (view: any) => {
    const response = await httpService.put(`/okr/saveview/${view.id}`, view);
    return response.data;
})

export const getAllOKRSavedView = createAsyncThunk('okr/saveview/get', async (_) => {
    const response = await httpService.get(`/okr/saveview`);
    return response.data;
})

export const deleteOKRSavedView = createAsyncThunk('okr/savevie/delete', async ({ id }: { id: number }) => {
    await httpService.delete(`/okr/saveview/${id}`);
    return { id };
})


const savedViewSlice = createSlice({
    name: 'okrSavedViews',
    initialState: savedOkrAdapter.getInitialState(),
    reducers: {

    },

    extraReducers: builder => {
        builder.addCase(getAllOKRSavedView.fulfilled, (state, action) => {
            savedOkrAdapter.setAll(state, action.payload.data || [])
        }).addCase(saveViewOKR.fulfilled, (state, action) => {
            savedOkrAdapter.addOne(state, { ...action.payload.data })
        }).addCase(editViewOKR.fulfilled, (state, action) => {
            savedOkrAdapter.updateOne(state, {
                id: action.payload.data.id,
                changes: action.payload.data
            })
        }).addCase(deleteOKRSavedView.fulfilled, (state, action) => {
            savedOkrAdapter.removeOne(state, action.payload.id);
        })
    }
})

export default savedViewSlice.reducer