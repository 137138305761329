import React from 'react'
import { useTranslation } from 'react-i18next';
import { getDisplayDate } from 'utils/dateHelper'

const SinceDate = ({ date }) => {
    const displayDate = getDisplayDate(date);
    const { t } = useTranslation();
    return (
        <div>
            {`${t("Since")}: ${displayDate}`}
        </div>
    )
}

export default SinceDate
