import { createSlice } from "@reduxjs/toolkit";

const initialState = {

};

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        changeProductSetting(state, action) {
            Object.keys(action.payload).forEach(a => {
                state[a] = action.payload[a];
            })
        }
    }
})

export const { changeProductSetting } = productSlice.actions

export default productSlice.reducer