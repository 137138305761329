import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'
import httpService from "utils/httpService";
const initialState = {
    projectDaily: [],
    projectWeekly: [],
    projectMonthly: [],
    userDaily: [],
    userWeekly: [],
    userMonthly: [],
};
export const projectDailyReport = createAsyncThunk('reports/project/daily', async (data) => {
    try {
        const response = await httpService.get(`/reports/projects/${data.projectId}/Daily/${data.year}/${data.month}`);
        const result = calculateTotal(response.data);
        return result;
    } catch (err) {
        console.trace(err);
    }
})

export const projectWeeklyReport = createAsyncThunk('reports/project/weekly', async (data) => {
    const response = await httpService.get(`/reports/projects/${data.projectId}/Weekly/${data.year}/${data.season}`);
    const result = calculateTotal(response.data);
    return result;
})
export const projectMonthlyReport = createAsyncThunk('reports/project/monthly', async (data) => {
    const response = await httpService.get(`/reports/projects/${data.projectId}/Monthly/${data.year}`);
    const result = calculateTotal(response.data);
    return result;
})

export const userDailyReport = createAsyncThunk('reports/user/daily', async (data) => {
    const response = await httpService.get(`/reports/users/${data.userId}/Daily/${data.year}/${data.month}`);
    const result = calculateTotal(response.data);
    return result;
})
export const userWeeklyReport = createAsyncThunk('reports/user/weekly', async (data) => {
    const response = await httpService.get(`/reports/users/${data.userId}/Weekly/${data.year}/${data.season}`);
    const result = calculateTotal(response.data);
    return result;
})
export const userMonthlyReport = createAsyncThunk('reports/user/monthly', async (data) => {
    const response = await httpService.get(`/reports/users/${data.userId}/Monthly/${data.year}`);
    const result = calculateTotal(response.data);
    return result;
})

function calculateTotal(data) {
    return data.map(a => { return { ...a, total: a.total + a.manual } })
}
const reportsSlice = createSlice({
    name: 'reports',
    initialState: initialState,
    reducers: {
        resetReports(state) {
            state.projectDaily = [];
            state.projectWeekly = [];
            state.projectMonthly = [];
            state.userDaily = [];
            state.userWeekly = [];
            state.userMonthly = [];
            state.loading = false;
        }
    },
    extraReducers: builder => {
        builder.addCase(projectDailyReport.fulfilled, (state, action) => {
            state.projectDaily = action.payload;
        }).addCase(projectWeeklyReport.fulfilled, (state, action) => {
            state.projectWeekly = action.payload;
        }).addCase(projectMonthlyReport.fulfilled, (state, action) => {
            state.projectMonthly = action.payload;
        })

            .addCase(userDailyReport.fulfilled, (state, action) => {
                state.userDaily = action.payload;
            }).addCase(userWeeklyReport.fulfilled, (state, action) => {
                state.userWeekly = action.payload;
            }).addCase(userMonthlyReport.fulfilled, (state, action) => {
                state.userMonthly = action.payload;
            })

            .addMatcher(isAnyOf(
                projectDailyReport.pending,
                projectWeeklyReport.pending,
                projectMonthlyReport.pending,

                userDailyReport.pending,
                userWeeklyReport.pending,
                userMonthlyReport.pending,
            ), (state) => { state.loading = true })
            .addMatcher(isAnyOf(
                projectDailyReport.rejected,
                projectWeeklyReport.rejected,
                projectMonthlyReport.rejected,

                userDailyReport.rejected,
                userWeeklyReport.rejected,
                userMonthlyReport.rejected,


                projectDailyReport.fulfilled,
                projectWeeklyReport.fulfilled,
                projectMonthlyReport.fulfilled,

                userDailyReport.fulfilled,
                userWeeklyReport.fulfilled,
                userMonthlyReport.fulfilled,
            )
                , (state) => { state.loading = false })
    }
})
export const { resetReports } = reportsSlice.actions;

export default reportsSlice.reducer