import { configureStore, createAction } from '@reduxjs/toolkit'
import reducers from './reducers';
import middlewares from './middlewares'
import { updateTheme } from './middlewares/themes.middleware.js';
import { persistedState, saveState } from './persisted.store';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

const resetAction = createAction('reset')

const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
        middlewares
    ),
    preloadedState: persistedState
});

store.subscribe(() => {
    saveState(store.getState());
});
updateTheme(store.getState())
export default store
export { resetAction }


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
