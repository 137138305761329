import { createSelector } from "@reduxjs/toolkit";

const serverConfig = (state: any) => state.serverConfig && state.serverConfig.config ? state.serverConfig.config : null;
export const isSystemModePublic = createSelector(
    [serverConfig],
    serverConfig => {
        return serverConfig && serverConfig.systemMode === 'public'
    }
)

export const getGoogleAnalytics = createSelector(
    [serverConfig],
    serverConfig => {
        return serverConfig ? serverConfig.googleAnalytics : null;
    }
)

export const getGoogleClientId = createSelector(
    [serverConfig],
    serverConfig => {
        return serverConfig ? serverConfig.googleClientId : null;
    }
)


export const getRecaptchaSiteKey = createSelector(
    [serverConfig],
    serverConfig => {
        return serverConfig && serverConfig.recaptchaEnabled ? serverConfig.recaptchaSiteKey : null;
    }
)

export const getScreenshotEnabled = createSelector(
    [serverConfig],
    serverConfig => {
        return serverConfig && serverConfig.tracking && serverConfig.tracking.screenshot && serverConfig.tracking.screenshot.screenshotEnabled;
    }
)

export const getSignupEnabled = createSelector(
    [serverConfig],
    serverConfig => {
        return serverConfig && serverConfig.signup && serverConfig.signup.signupEnabled;
    }
)

export const getGoogleOneTapLoginEnabled = createSelector(
    [serverConfig],
    serverConfig => {
        return serverConfig && serverConfig.googleOneTapLoginEnabled;
    }
)

export const getGoogleSSOButtonEnabled = createSelector(
    [serverConfig],
    serverConfig => {
        return serverConfig && serverConfig.googleSSOButtonEnabled;
    }
)

export const notificationsIsEnableInUI = createSelector(
    [serverConfig],
    serverConfig => {
        if (!serverConfig || !serverConfig.notificationConfig) {
            return false;
        }
        return serverConfig && serverConfig.notificationConfig && serverConfig.notificationConfig.notificationsIsEnableInUI;
    }
)

export const deeplinkBaseUrl = createSelector(
    [serverConfig],
    serverConfig => {
        if (!serverConfig || !serverConfig.desktopAppConfig) {
            return "Teamsuite";
        }
        return serverConfig && serverConfig.desktopAppConfig && serverConfig.desktopAppConfig.deeplinkBaseUrl;
    }
)

export const getHotjarConfig = createSelector(
    [serverConfig],
    serverConfig => {
        if (!serverConfig || !serverConfig.hotjar) {
            return null;
        }
        return serverConfig.hotjar;
    }
)