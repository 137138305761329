import { useAccountMenu } from 'apps/account/menu';
import { useDashboardMenu } from 'apps/dashboard/menu';
import { useGoalMenu } from 'apps/goals/menu';
import { useOrganizationMenu } from 'apps/organization/menu';
import { usePeopleMenu } from 'apps/people/menu';
import { usePeopleApp_Menu } from 'apps/peopleApp/menu';
import { useProjectsMenu } from 'apps/projects/menu';
import { useSignupLoginMenu } from 'apps/signupLogin/menu';
import { useTimeMenu } from 'apps/time/menu';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { changeSetting } from 'store/actions';
import { userHasAnyOrganization } from 'store/selectors';
import { APPS } from './components/Common/constants';

import { useAppHelper } from './utils/useAppHelper';
export default function useMenu(permissions) {
    const { currentApp } = useAppHelper();
    const dispatch = useDispatch();
    const goalMenu = useGoalMenu();
    const organizationMenu = useOrganizationMenu();
    const peopleMenu = usePeopleMenu();

    const timeMenu = useTimeMenu();
    const signupLoginMenu = useSignupLoginMenu();
    const dashboardMenu = useDashboardMenu();

    const subMenuIsHidden = useSelector(state => state.settings.subMenuIsHidden);

    const hasAnyOrganization = useSelector(userHasAnyOrganization);
    let Menu = [];
    Menu.push(...signupLoginMenu.getItems());

    const { id } = useParams();
    const peopleAppMenu = usePeopleApp_Menu();

    if (currentApp.frontName === APPS.PeopleApp.frontName) {
        if (id) {
            Menu.push(...peopleAppMenu.getInnerMenuItems(id));
        } else {
            Menu.push(...peopleAppMenu.getItems());
        }
    }

    const projectsMenu = useProjectsMenu();

    if (currentApp.frontName === APPS.Projects.frontName) {
        if (id) {
            Menu.push(...projectsMenu.getInnerMenuItems(id));
        } else {
            Menu.push(...projectsMenu.getItems());
        }
    }

    const accountMenu = useAccountMenu();

    if (currentApp.frontName === APPS.Account.frontName) {
        Menu.push(...accountMenu.getItems());
    }

    if (currentApp.frontName === APPS.Time.frontName) {
        Menu.push(...timeMenu.getItems());
    }

    if (currentApp.frontName === APPS.OKR.frontName) {
        Menu.unshift(...goalMenu.getItems());
    }

    if (currentApp.frontName === APPS.People.frontName) {
        Menu.push(...peopleMenu.getItems());
    }

    if (currentApp.frontName === APPS.Organization.frontName) {
        Menu.push(...organizationMenu.getItems());
    }

    if (currentApp.frontName === APPS.Dashboard.frontName) {
        Menu.push(...dashboardMenu.getItems());
    }

    //get current app menu items
    if (hasAnyOrganization === false) {
        Menu = Menu.filter(a => a.appName === APPS.Init.frontName);
    } else {
        Menu = Menu.filter(menu => menu.appName === currentApp.frontName && (!menu.permissions || menu.permissions.length === 0 || permissions.some(a => menu.permissions.some(p => p.toLowerCase() === a.toLowerCase()))));
        Menu = Menu.filter(a => !a.hidden);
    }

    const menuLength = Menu.length;
    useEffect(() => {
        const hideSubMenu = menuLength === 0;
        if (hideSubMenu !== subMenuIsHidden) {
            dispatch(changeSetting({ name: 'subMenuIsHidden', value: hideSubMenu }));
        }
    }, [menuLength, subMenuIsHidden, dispatch]);
    return { Menu: Menu };
}
