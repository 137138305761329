import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import { ReactComponent as CameraIcon } from 'icons/camera-icon.svg';
import { defaultAvatar } from 'components/Common/constants';
import ProjectLogo from '../../Projects/ProjectLogo';

function LogoSelector({ onFileUpload, initLogo, onRemovePhoto, readOnly, width = 140,
    height = 140, marginTop = 30, borderRadius = "48px", addLabel = "", removeLabel = "", caption = "" }) {
    const inputFile = useRef(null)
    const onButtonClick = () => {
        if (readOnly) { return; }
        if (isDefaultImage()) {
            inputFile.current.click();
        } else {
            handleOnRemovePhoto();
        }
    };
    const handleOnRemovePhoto = () => {
        if (readOnly) { return; }
        inputFile.current.value = "";
        onRemovePhoto();
    };
    const handleUploadFile = (e) => {
        if (readOnly) { return; }
        onFileUpload(e.target.files[0])
    }
    const isDefaultImage = () => {
        return !initLogo || defaultAvatar.IsDefaultAvatar(initLogo)
    }
    const { t } = useTranslation();
    return (
        <div>
            <div className="logo-selector-box" style={{ width: width, height: height, marginTop: marginTop }}>
                <Label className='text-primary' style={{ paddingRight: '15px' }}>{t(caption)}</Label>
                <input type='file' id='file' accept="Image/*" ref={inputFile} onChange={handleUploadFile} />
                {!isDefaultImage() ?
                    <>
                        <ProjectLogo logo={initLogo} thumbSize={width * 0.5} />
                        <span onClick={onButtonClick} className='btn logo-selector-botton mt-2'>{removeLabel === '' ? t('removeLogo') : t(removeLabel)}</span>
                    </>
                    :
                    <>
                        {/* <img alt='' className="logo-selector-default-image" style={{ width: 55, height: 55 }} src={initLogo}></img> */}
                        {/* <UserAvatar src={initLogo} name={name} size={55} round={true} />                         */}

                        <div className="text-center logo-selector-default-photo" style={{ borderRadius: borderRadius }}>
                            <CameraIcon style={{ marginTop: '35%' }}></CameraIcon>
                        </div>
                        <span onClick={onButtonClick} className='btn logo-selector-botton mt-2'>{addLabel === '' ? t('addLogo') : t(addLabel)}</span>
                    </>
                }
            </div>

        </div>
    );
}
export default LogoSelector;