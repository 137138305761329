import InviteUserForm from "apps/people/src/Invitation/InviteUserForm";
import MemberRowBody from "apps/time/src/Members/List/MemberRowBody";
import { APPS, PermissionsList } from "components/Common/constants";
import { useDocumentHelper } from "hooks/documentHelperHook";
import { ReactComponent as ArrowLeft } from 'icons/arrow-left-icon.svg';
import { ReactComponent as ArrowRight } from 'icons/arrow-right-icon.svg';
import { ReactComponent as MemberIcon } from 'icons/menu-icons/member-icon.svg';
import { ReactComponent as UserAddIcon } from 'icons/user-add-icon.svg';
import React from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectMemberById } from "store/selectors";

export const usePeopleApp_Menu = () => {

    const getItems = () => {
        const menuItems = [

            {
                name: APPS.PeopleApp.frontName,
                heading: APPS.PeopleApp.displayName,
                customCssClass: 'menu-saved-views-header',
                appName: APPS.PeopleApp.frontName,
            },
            {
                name: 'viewalll',
                appName: APPS.PeopleApp.frontName,
                permissions: [PermissionsList.TIME_MEMBER_VIEW],
                icon: <MemberIcon />,
                translate: 'sidebar.nav.menu.ViewEveryone',
                path: `/${APPS.PeopleApp.frontName}`
            },
            {
                name: 'invite',
                className: "menu-item",
                customContent: <InviteMenu></InviteMenu>,
                appName: APPS.PeopleApp.frontName,
                // icon: <MemberIcon />,
                permissions: [PermissionsList.ORGANIZATION_USERMANAGEMENT_VIEW]
            }

        ];
        return menuItems;
    }
    const getInnerMenuItems = (userId) => {

        const menuItems = [
            {
                name: APPS.PeopleApp.frontName,
                customContent: <MemberTopMenu userId={userId} />,
                appName: APPS.PeopleApp.frontName,
            },
            {
                name: APPS.PeopleApp.frontName,
                className: "menu-item",
                customContent: <ReturnToList />,
                appName: APPS.PeopleApp.frontName,
            },
            {
                name: APPS.PeopleApp.frontName,
                heading: "User",
                customCssClass: 'menu-saved-views-header',
                appName: APPS.PeopleApp.frontName,
            },
            {
                name: 'overview',
                appName: APPS.PeopleApp.frontName,
                // icon: <MemberIcon />,
                translate: 'members.OVERVIEW',
                path: `/${APPS.PeopleApp.frontName}/${userId}/overview`
            },
            {
                name: 'timeline',
                appName: APPS.PeopleApp.frontName,
                // icon: <MemberIcon />,
                translate: 'sidebar.nav.menu.Timeline',
                path: `/${APPS.PeopleApp.frontName}/${userId}/workdiary`
            },
            {
                name: 'settings',
                appName: APPS.PeopleApp.frontName,
                // icon: <MemberIcon />,
                translate: 'members.SETTINGS',
                path: `/${APPS.PeopleApp.frontName}/${userId}/settings`
            },
        ];
        return menuItems;
    }
    return { getItems, getInnerMenuItems }
}

const InviteMenu = () => {
    const { t } = useTranslation();
    return <div className="d-flex nav-item">
        <UserAddIcon className="m-end-1" />
        <InviteUserForm
            newButtonComponent={
                <span>{t('sidebar.nav.menu.InvitePeople')}</span>
            }
        />
    </div>
}

const MemberTopMenu = ({ userId }) => {
    const memberFromSelector = useSelector(state => selectMemberById(state, userId));
    const member = memberFromSelector ? { ...memberFromSelector, isActive: memberFromSelector.isActive === 1 } : null;
    return <div className="m-start-1 p-2">
        <MemberRowBody member={member} titleClassName={"col-md-12"} logoSize={35} />
    </div>;
}

const ReturnToList = () => {
    const { languageIsRightToLeft } = useDocumentHelper();
    return (
        // <div className="menu-item back-to-list-menu">
        <Link className="back-to-list-menu" to={"/people"}>
            {!languageIsRightToLeft && <ArrowLeft></ArrowLeft>}
            {languageIsRightToLeft && <ArrowRight></ArrowRight>}
            View All People
        </Link>
        // </div>
    )
}