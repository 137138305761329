import { createAsyncThunk, createEntityAdapter, createSelector } from "@reduxjs/toolkit"
import httpService from "../../../utils/httpService"
import searchInPropsHelper from "../../../utils/searchInPropsHelper"

const membersAdapter = createEntityAdapter()

export const searchInMembers = createSelector(
    state => state.project.entities,
    (allProjects) =>
        (members, searchingTerm, showDisabledMembers) => members.filter(x =>
            filterProject(x, searchingTerm, showDisabledMembers, allProjects))
    // ,/*cache key for memoize: */(searchingTerm, showArchivedProjects) => "st:" + searchingTerm +
    //     " sa:" + showArchivedProjects
)

export const haveAnyDisabledMember = createSelector(
    [],
    () =>
        (members) => members.some(member => !memberIsActive(member))
)

function filterProject(member, searchingTerm, showDisabledMembers, allProjects) {
    return (searchInPropsHelper(member, ['name', 'location', 'description', '_pivot_role'], searchingTerm) || searchInMemberProjects(member, searchingTerm, allProjects))
        && ((!showDisabledMembers && memberIsActive(member)) || showDisabledMembers)
}

function searchInMemberProjects(member, searchingTerm, allProjects) {
    for (const key in allProjects) {
        if (Object.hasOwnProperty.call(allProjects, key)) {
            const project = allProjects[key];
            const findProject = project.team.some(a => a.id === member.id && searchInPropsHelper(project, ['name', 'code'], searchingTerm));
            if (findProject) {
                return true;
            }
        }
    }
    return false;
}

export function memberIsActive(member) {
    return member.isActive && (!member.status || member.status !== 'pause') && (!member._pivot_status || member._pivot_status === 'active');
}

export const getUserLogsByDate = createAsyncThunk('worklog/user/bydate', async ({ userId, logDate }) => {
    const userLogs = await httpService.get(`/workLogs/users/${userId}/onDate/${logDate}`);
    return userLogs.data.userLogs;
})
export const getDaysHasLogs = createAsyncThunk('worklog/user/bydate', async ({ userId, month, year }) => {
    const userLogs = await httpService.get(`/reports/users/${userId}/daysHasLogs/${year}/${month}`);
    return userLogs.data;
})
export const getUserLogDetailsById = createAsyncThunk('worklog/user/bydate', async ({ userId, logId }) => {
    const userLogDetails = await httpService.get(`/workLogs/users/${userId}/detail/${logId}`);
    return userLogDetails.data.userLogDetails;
})

export const getUserPermissions = createAsyncThunk('member/permissions', async ({ userId, projectId }) => {
    const userPermissions = await httpService.get(`/permissions/projects-users/${projectId}/${userId}`);
    return userPermissions.data;
})

export const saveProjectUserPermissions = createAsyncThunk('member/permissions/savev', async ({ userId, projectId, data }) => {
    const userPermissions = await httpService.put(`/permissions/projects-users/${projectId}/${userId}`, data);
    return userPermissions.data;
})

export const {
    selectById: selectMemberById,
    selectIds: selectMemberIds,
    selectEntities: selectMemberEntities,
    selectAll: selectAllMembers,
    selectTotal: selectTotalMembers
} = membersAdapter.getSelectors(state => state.member)

