import React from 'react'
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';

const Toastify = () => {
    const language = useSelector(a => a.user.language);
    const languageIsRtl = language === 'fa';
    const toastPosition = languageIsRtl ? 'top-right' : 'top-left';

    return (
        <ToastContainer autoClose={3000} hideProgressBar={true} position={toastPosition} rtl={languageIsRtl} style={{ zIndex: 10000 }} />
    )
}

export default Toastify
