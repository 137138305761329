import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { SubscriptionTypes } from 'components/Common/constants';
import { OrganizationModel } from 'apps/organization/models/OrganizationModel';
import { OrganizationRequestModel } from 'apps/organization/models/OrganizationRequestModels';
import httpService from "utils/httpService";
const initialState = {
    loading: false,
    currentOrganization: {
        loading: false,
        lastFetch: null,
        entity: {} as OrganizationModel
    }
}

export const loadCurrentOrganization = createAsyncThunk('organization/current', async (_, { getState }) => {
    const response = await httpService.get(`/organizations/current`)
    return response.data
})

export const createOrganization = createAsyncThunk('organization/create', async (organization: OrganizationRequestModel, { rejectWithValue }) => {
    try {
        const response = await httpService.post(`/organizations`, organization)
        return response.data
    } catch (err: any) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
        return rejectWithValue(error.response.data)
    }
})

export const updateOrganization = createAsyncThunk('organization/update', async (organization, { getState, rejectWithValue }) => {
    try {
        const response = await httpService.put(`/organizations/current`, organization)
        return response.data
    } catch (err: any) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
        return rejectWithValue(error.response.data)
    }
})

export const uploadOrganizationLogo = createAsyncThunk('organization/uploadLogo', async ({ logo, organizatonId }: { logo: any, organizatonId: number }, { getState }) => {
    const formData = new FormData();
    formData.append('avatar', logo);
    const response = await httpService.post(`/organizations/${organizatonId ?? "current"}/picture`, formData)
    return { photo: response.data.data.photo }
})

export const removeOrganizationLogo = createAsyncThunk('organization/removeLogo', async (_, { getState }) => {
    const response = await httpService.delete(`/organizations/current/picture`);
    return { photo: response.data.data.photo }
})

export const changeCurrentOrganization = createAsyncThunk('organization/changeCurrentOrganization', async (organizationUId: string) => {
    const response = await httpService.put(`/me/organizations/changeCurrent/${organizationUId}`);
    return { data: response.data.data }
})

export const getOrganizationMemberInfo = createAsyncThunk('organization/memberInfo', async () => {
    const response = await httpService.get(`/organizations/memberInfo`);
    return { data: response.data }
})

const organizationSlice = createSlice({
    name: 'organization',
    initialState,

    reducers: {
        cancelOrganizationPaymentSubscription(state) {
            if (!state.currentOrganization.entity.subscription) {
                return;
            }
            state.currentOrganization.entity.subscription.status = SubscriptionTypes.Cancelled;
            state.currentOrganization.entity.subscription.subscriptionId = null;
        },
        resetOrganization(state) {
            state.currentOrganization = initialState.currentOrganization;
            state.loading = false;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(loadCurrentOrganization.fulfilled, (state, action) => {
                state.currentOrganization.entity = action.payload
            })
            .addCase(updateOrganization.fulfilled, (state, action) => {
                state.currentOrganization.entity = action.payload.data
            }).addCase(removeOrganizationLogo.fulfilled, (state, action) => {
                state.currentOrganization.entity.photo = action.payload.photo;
            }).addMatcher(isAnyOf(uploadOrganizationLogo.fulfilled), (state, action) => {
                state.currentOrganization.entity.photo = action.payload.photo + "?rand=" + Date.now();
            }).addMatcher(isAnyOf(loadCurrentOrganization.pending, updateOrganization.pending, changeCurrentOrganization.pending), (state) => {
                state.loading = true
                state.currentOrganization.loading = true
            })
            .addMatcher(isAnyOf(loadCurrentOrganization.rejected, updateOrganization.rejected, changeCurrentOrganization.rejected, loadCurrentOrganization.fulfilled, changeCurrentOrganization.fulfilled, updateOrganization.fulfilled)
                , (state) => {
                    state.currentOrganization.loading = false;
                    state.loading = false;
                })
    }
})

export const { cancelOrganizationPaymentSubscription, resetOrganization } = organizationSlice.actions

export default organizationSlice.reducer
