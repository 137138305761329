import { APPS } from 'components/Common/constants';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import httpService from 'utils/httpService';
import { useAppHelper } from 'utils/useAppHelper';

export const TimeAppDownloadNotif = () => {
    const { t } = useTranslation();
    const { currentApp } = useAppHelper();
    const [hasTimeAppReport, setHasTimeAppReport] = useState(true)
    useEffect(() => {
        const getHasTimeAppReport = async () => {
            try {
                const { data } = await httpService.get("/me/desktopApp");
                if (data && data.success) {
                    setHasTimeAppReport(data.data);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getHasTimeAppReport();
    }, [])
    if (currentApp.frontName !== APPS.Time.frontName) {
        return <></>
    }


    if (hasTimeAppReport) {
        return <></>
    }


    return (
        <div className="d-lg time-download-notif-top-navbar d-none d-xl-flex">
            <div>
                {t("Keep_your_focus_on_your_work_not_on_your_timekeeping")}
                <span>
                    <Link to="/time/download">
                        <Button color='link' className='m-start-2'>{t("Install_Desktop_App")}</Button>
                    </Link>
                </span>
            </div>
        </div>
    )
}
