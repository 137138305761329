import React from "react";
import { useAppSelector } from "../store/store";
import { isAuthenticate } from "store/selectors";

type LayoutProps = {
    layout: any;
    isPublic?: boolean;
}
const Layout: React.FC<LayoutProps> = ({ layout: Layout, isPublic, children }) => {
    const isAuth = useAppSelector(state => isAuthenticate(state));

    if (isPublic === true || isAuth)
        return <Layout>{children}</Layout>;
    return <>{children}</>;
}
export default Layout;