import { APPS, PermissionsList } from "components/Common/constants";
import { ReactComponent as DownloadIcon } from 'icons/menu-icons/download-icon.svg';
import { ReactComponent as WorkDiaryIcon } from 'icons/menu-icons/workDiary-icon.svg';
import React from 'react';

export const useTimeMenu = () => {

    // const upgradeMethod = useSelector(state => state.serverConfig.config.upgradeMethod.method);
    // const { t } = useTranslation();
    const getItems = () => {
        const menuItems = [

            {
                name: APPS.Time.frontName,
                heading: APPS.Time.displayName,
                customCssClass: 'menu-saved-views-header',
                appName: APPS.Time.frontName,
                permissions: [PermissionsList.TIME_PROJECT_VIEW, PermissionsList.TIME_PROJECT_VIEWALL, PermissionsList.TIME_MYWORKDAIRY_VIEW,
                PermissionsList.TIME_DOWNLOAD_VIEW]
            },
            {
                name: 'mytime',
                appName: APPS.Time.frontName,
                permissions: [PermissionsList.TIME_MYWORKDAIRY_VIEW],
                icon: <WorkDiaryIcon />,
                translate: 'sidebar.nav.menu.MYTIME',
                path: `/${APPS.Time.frontName}/mytime`
            },
            // {
            //     name: 'project',
            //     appName: APPS.Time.frontName,
            //     permissions: [PermissionsList.TIME_PROJECT_VIEW, PermissionsList.TIME_PROJECT_VIEWALL],
            //     icon: <ProjectIcon />,
            //     translate: 'sidebar.nav.menu.PROJECTS',
            //     path: `/${APPS.Time.frontName}/projects`
            // },
            // {
            //     name: 'member',
            //     appName: APPS.Time.frontName,
            //     permissions: [PermissionsList.TIME_MEMBER_VIEW],
            //     icon: <MemberIcon style={{ fill: 'var(--sidebar-color)' }} />,
            //     translate: 'sidebar.nav.menu.MEMBERS',
            //     path: `/${APPS.Time.frontName}/members`
            // },
            // {
            //     name: 'addManual',
            //     customContent: <AddManualMenu></AddManualMenu>,
            //     appName: APPS.Time.frontName,
            //     permissions: []
            // },
            {
                name: APPS.Time.frontName,
                heading: "Time Desktop App",
                customCssClass: 'menu-saved-views-header',
                appName: APPS.Time.frontName,
                permissions: [PermissionsList.TIME_DOWNLOAD_VIEW]
            },
            {
                name: 'download',
                appName: APPS.Time.frontName,
                permissions: [PermissionsList.TIME_DOWNLOAD_VIEW],
                icon: <DownloadIcon />,
                translate: 'sidebar.nav.menu.DOWNLOAD',
                path: `/${APPS.Time.frontName}/download`,
                className: 'd-none d-md-block'
            },
            // {
            //     name: 'bookmarked_projects',
            //     heading: t("sidebar.bookmarked_projects"),
            //     customCssClass: 'menu-saved-views-header',
            //     appName: APPS.Time.frontName,
            //     permissions: [PermissionsList.TIME_PROJECT_VIEW],
            // },
            // {
            //     name: 'latest_active_members',
            //     heading: "Last Active Members",
            //     customCssClass: 'menu-saved-views-header',
            //     appName: APPS.Time.frontName,
            //     permissions: [PermissionsList.TIME_MEMBER_VIEW, PermissionsList.TIME_MEMBER_UPDATE],
            // },
            // {
            //     name: 'upgrade_plans',
            //     heading: t("sidebar.Upgrade_Plans"),
            //     customCssClass: 'upgrade_header',
            //     appName: APPS.Time.frontName,
            //     permissions: [PermissionsList.ORGANIZATION_SUBSCRIPTION_VIEW],
            //     hidden: upgradeMethod === 'hidden'
            // },
            // {
            //     name: 'Upgrade Time',
            //     appName: APPS.Time.frontName,
            //     permissions: [PermissionsList.ORGANIZATION_SUBSCRIPTION_VIEW],
            //     icon: <UpgradeIcon />,
            //     translate: 'sidebar.Upgrade',
            //     path: `/${APPS.Time.frontName}/upgrade`,
            //     hidden: upgradeMethod === 'hidden'
            // },

        ];

        // const indexOfBookmarkedProjects = menuItems.map(a => a.name).indexOf('bookmarked_projects');
        // const bookmarkedProjects = allProjects.filter(a => a.bookmark);

        // if (bookmarkedProjects && bookmarkedProjects.length > 0) {
        //     bookmarkedProjects.forEach(bookmarkedProject => {
        //         if (bookmarkedProject && bookmarkedProject.id && bookmarkedProject.name) {
        //             menuItems.splice(indexOfBookmarkedProjects + 1, 0, {
        //                 name: bookmarkedProject.name,
        //                 customCssClass: 'time-project-bookmark-item',
        //                 appName: APPS.Time.frontName,
        //                 permissions: [PermissionsList.TIME_PROJECT_VIEW],
        //                 icon: <UserAvatar src={bookmarkedProject.photo} name={bookmarkedProject.name} size={22} round={true} />,
        //                 path: `/time/projects/${bookmarkedProject.id}`,

        //             })
        //         }

        //     })
        // } else {
        //     menuItems.splice(indexOfBookmarkedProjects + 1, 0, {
        //         name: 'empty_bookmarked_projects',
        //         heading: t('sidebar.Bookmark_a_project_from_Projects_page_for_easy_access'),
        //         appName: APPS.Time.frontName,
        //         translate: "sidebar.Bookmark_a_project_from_Projects_page_for_easy_access",
        //         customCssClass: 'menu-saved-views-empty-comment',
        //         permissions: [PermissionsList.TIME_PROJECT_VIEW],
        //     })
        // }

        // const latestActiveMembersItem = menuItems.map(a => a.name).indexOf('latest_active_members');
        // const latestActiveMembers = [];

        // if (latestActiveMembers && latestActiveMembers.length > 0) {
        //     latestActiveMembers.forEach(bookmarkedProject => {
        //         if (bookmarkedProject && bookmarkedProject.id && bookmarkedProject.name) {
        //             menuItems.splice(latestActiveMembersItem + 1, 0, {
        //                 name: bookmarkedProject.name,
        //                 customCssClass: 'time-project-bookmark-item',
        //                 appName: APPS.Time.frontName,
        //                 permissions: [PermissionsList.TIME_PROJECT_VIEW],
        //                 icon: <UserAvatar src={bookmarkedProject.photo} name={bookmarkedProject.name} size={22} round={true} />,
        //                 path: `/time/project/${bookmarkedProject.id}`,

        //             })
        //         }

        //     })
        // } else {
        //     menuItems.splice(latestActiveMembersItem + 1, 0, {
        //         name: 'empty_bookmarked_projects',
        //         appName: APPS.Time.frontName,
        //         heading: "Members who have work time will be displayed here",
        //         customCssClass: 'menu-saved-views-empty-comment',
        //         permissions: [PermissionsList.TIME_PROJECT_VIEW],
        //     })
        // }

        return menuItems;
    }

    // const AddManualMenu = () => {

    //     return <>
    //         <div className="">
    //             {<AddManualWorkTimeModal monthChange={handleMonthChange}
    //                 projects={projects} date={logDate} reloadDiary={refreshDiary}>

    //                 </AddManualWorkTimeModal>}
    //         </div>
    //     </>
    // }
    return { getItems }
}