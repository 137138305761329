import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from "../../../utils/httpService";

export const createPaymentIntent = createAsyncThunk('createpaymentintent', async () => {
    const response = await httpService.post(`/createpaymentintent`);
    return response.data
})

export const getSubscriptionSecret = createAsyncThunk('subscription/secret', async () => {
    const response = await httpService.get(`/subscription/secret`);
    return response.data
})

export const updateCustomerPaymentMethod = createAsyncThunk('subscription/updateCustomerPaymentMethod', async (id) => {
    const response = await httpService.put(`/subscrption/customer/payment-method/${id}`);
    return response.data
})

export const getSubscriptionManagement = createAsyncThunk('subscription/management', async () => {
    const response = await httpService.get("/subscription/management");
    return response.data
})

export const getPaymentMethods = createAsyncThunk('subscription/payment-methods', async () => {
    const response = await httpService.get("/subscription/payment-method");
    return response.data
})

export const getSubscriptionInvoiceDetail = createAsyncThunk('subscription/invoice', async (id) => {
    const response = await httpService.get(`/subscription/invoice/${id}`);
    return response.data
})

export const getBillingHistory = createAsyncThunk('subscription/billing-history', async () => {
    const response = await httpService.get(
        `/subscription/invoice/history`
    );
    return response.data
})


//plan apis
export const getSubscriptionPrices = createAsyncThunk('subscription/prices', async (appName) => {
    const response = await httpService.get(`/subscription/${appName}/plans`);
    return response.data
})

export const downgradePlan = createAsyncThunk('subscription/downgrade', async ({ plan, appName }: { plan: string, appName: string }) => {
    const response = await httpService.post(`/subscription/${appName}/plan/downgrade`, { plan });
    return response.data
})

export const upgradePlan = createAsyncThunk('subscription/upgrade', async ({ plan, appName }: { plan: string, appName: string }) => {
    const response = await httpService.post(`/subscription/${appName}/plan/upgrade`, { plan });
    return response.data
})

export const getDowngradeToFreeDetails = createAsyncThunk('subscription/getDowngradeToFreeDetails', async (appName: string) => {
    const response = await httpService.get(`/subscription/${appName}/plan/downgrade/free`);
    return response.data
})

export const cancelDowngrade = createAsyncThunk('subscription/cancel-downgrade', async (appName: string) => {
    const response = await httpService.delete(`/subscription/${appName}/plan/downgrade`);
    return response.data
})