import { getDisplayDateGregorian, getDisplayDateShamsi as getDisplayDatePersian } from './dateHelper/getDisplayDate';
import { getSeasonNameGregorian, getSeasonNamePersian } from './dateHelper/getSeasonName';
import { getMonthNameFromMonthNumberGregorian, getMonthNameFromMonthNumberPersian } from './dateHelper/getMonthName';
import { dateExtractorGregorian, dateExtractorPersian } from './dateHelper/dateExtractor';
import moment from 'moment';
import jalaliMoment from 'moment-jalaali';
import store from 'store/store';
import numeral from 'numeral';

jalaliMoment.loadPersian();
export const Calendars = {
    Gregorian: 'Gregorian',
    PersianCalendar: 'Persian Calendar'
}

export function getCurrentCalendar() {
    const organizationCalendarType = store.getState().organization.currentOrganization.entity.calendarType;
    switch (organizationCalendarType) {
        case "shamsi":
            return Calendars.PersianCalendar;
        default:
            return Calendars.Gregorian;
    }

}

export function getDisplayDate(date) {

    const displayDate = getCurrentCalendar() === Calendars.Gregorian ? getDisplayDateGregorian(date) : getDisplayDatePersian(date);
    return displayDate;
}

export const getSeasonName = (season) => {
    const displayDate = getCurrentCalendar() === Calendars.Gregorian ? getSeasonNameGregorian(season) : getSeasonNamePersian(season);
    return displayDate;
}


export const getMonthNameFromMonthNumber = (month) => {
    const displayDate = getCurrentCalendar() === Calendars.Gregorian ? getMonthNameFromMonthNumberGregorian(month) : getMonthNameFromMonthNumberPersian(month);
    return displayDate;
}
export const getDaysInMonth = (date) => {
    return getCurrentCalendar() === Calendars.Gregorian ? date.daysInMonth() : jalaliMoment.jDaysInMonth(date.jYear(), date.jMonth());
}
export const dateExtractor = (date) => {
    const extractedDate = getCurrentCalendar() === Calendars.Gregorian ? dateExtractorGregorian(date) : dateExtractorPersian(date);
    return extractedDate;
}
export class dateBuilder {
    #year;
    #month;
    #monthName;
    #day;
    withYear(year) {
        this.#year = parseInt(year);
        return this;
    };
    withMonth(month) {
        if (this.#monthName) {
            throw new Error("you should set only month or only month name");
        }
        this.#month = parseInt(month);
        return this;
    };
    withMonthName(monthName) {
        if (this.#month) {
            throw new Error("you should set only month or only month name");
        }
        this.#monthName = monthName;
        return this;
    }
    withDay(day) {
        this.#day = parseInt(day);
        return this;
    };
    build() {
        let persianMoment = jalaliMoment();
        const gregorianMoment = moment();
        if (this.#year) {
            this.isGregorian() ? gregorianMoment.year(this.#year) : persianMoment.jYear(this.#year);
        }
        if (this.#month) {
            this.isGregorian() ? gregorianMoment.month(this.#month - 1) : persianMoment.jMonth(this.#month - 1);
        }
        if (this.#monthName) {
            this.isGregorian() ? gregorianMoment.month(this.#monthName) : persianMoment.jMonth(this.#monthName);

        }
        if (this.#day) {
            this.isGregorian() ? gregorianMoment.date(this.#day) : persianMoment.jDate(this.#day);

        }
        return this.isGregorian() ? dateExtractor(gregorianMoment.toDate()) : dateExtractor(persianMoment.toDate());
    }

    isGregorian() {
        return getCurrentCalendar() === Calendars.Gregorian;
    }
}

export const formatTime = (hour, minute) => {
    return numeral(hour).format("00") + ":" + numeral(minute).format("00")
}