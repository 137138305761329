
import React from 'react'
import { useSelector } from 'react-redux'

const ProductBrand = ({ forHeader = false }) => {
    // const { productName, productSubTitleName } = useDocumentHelper();
    const product = useSelector((state: any) => state.product)
    if (!forHeader) {
        return (
            <div className="mt-2 d-flex m-start-1">
                <div>
                    <img className="product-logo" src={product.logo} alt="App Logo" />
                </div>
                {/* <div className="p-start-1">
                    <CardTitle tag="h3" className="mb-1" >
                        {productName}
                    </CardTitle>
                    <CardSubtitle tag="small" className=" text-muted text-bold" style={{ fontSize: '12px' }}>
                        {productSubTitleName}
                    </CardSubtitle>
                </div> */}
            </div >
        )
    }

    return (
        <div className="d-flex m-start-1 navbar-header">
            <div >
                <img className="navbar-product-logo" src={product.navLogo} alt="App Logo" />
            </div>
            {/* <div className='p-start-1 d-flex align-items-center d-none d-md-flex'>
                <CardTitle tag="h4" className="mb-0">
                    {productName}
                </CardTitle>
            </div> */}
        </div >
    )
}

export default ProductBrand
