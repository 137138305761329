/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row
} from 'reactstrap';
import { addContractToMember } from 'store/actions';
import { selectMemberById, selectProjectById } from 'store/selectors';
import showToast from 'utils/toaster';

const HourlyRateUpdate = ({ className = '', modalIsOpen, toggle, projectId, userId, onHourlyRateUpdated, contract }) => {
    const user = useSelector(state => selectMemberById(state, userId));
    const project = useSelector(state => selectProjectById(state, projectId));
    const dispatch = useDispatch();
    const organization = useSelector(state => state.organization.currentOrganization.entity);


    const { t } = useTranslation();
    const [hourlyRate, setHourlyRate] = useState(contract?.rate);

    const handleHourlyRateChange = ({ currentTarget: input }) => {
        setHourlyRate(input.value)
    }
    async function handleSave(e) {
        e.preventDefault();

        var dataToRequest = {
            userIds: [userId],
            rate: hourlyRate,
            isOverwritten: true
        };
        await dispatch(addContractToMember({ projectId: projectId, dataToRequest: dataToRequest }))

        showToast("Member hourly rate updated successfully");
        onHourlyRateUpdated();
        toggle();
    }

    if (!modalIsOpen) {
        return (<></>)
    }
    return (
        <>

            <Row form>
                <Modal isOpen={modalIsOpen} toggle={toggle} className={className} size={'lg'}>
                    <Form onSubmit={handleSave}>
                        <ModalHeader>
                            {t('Update Hourly Rate')}
                            {` (${project.name} - ${user.name})`}
                        </ModalHeader>
                        <ModalBody>
                            <div>
                                <label>{t("members.HourlyRate")}</label>
                                <div className="input-group  col-md-4">
                                    <input type="text" className="form-control"
                                        id="rate" placeholder={t("members.HourlyRate")}
                                        aria-describedby="ratePrepend"
                                        onChange={handleHourlyRateChange}
                                        value={hourlyRate}
                                        required></input>
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="ratePrepend">{organization.currency} </span>
                                    </div>
                                </div>

                            </div>
                        </ModalBody>
                        <ModalFooter className="action-box">
                            <Button color="secondary" onClick={toggle}>
                                {t('cancel')}
                            </Button>
                            <Button color="primary" type="submit" value={t("Update Settings")} id="saveInModal">{t("Update Settings")}</Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </Row>
        </>
    );
}

export default HourlyRateUpdate



