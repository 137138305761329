import ProjectRowBody from "apps/time/src/Projects/List/ProjectRowBody";
import NewProjectForm from "apps/time/src/Projects/NewProjectForm/NewProjectForm";
import { APPS, PermissionsList } from "components/Common/constants";
import UserAvatar from "components/Common/UserAvatar";
import { useDocumentHelper } from "hooks/documentHelperHook";
import { ReactComponent as ArrowLeft } from 'icons/arrow-left-icon.svg';
import { ReactComponent as ArrowRight } from 'icons/arrow-right-icon.svg';
import { ReactComponent as ProjectIcon } from 'icons/menu-icons/projects-icon.svg';
import { ReactComponent as PlusIcon } from 'icons/plus-icon.svg';
import React from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectAllProjects, selectProjectById } from "store/selectors";

export const useProjectsMenu = () => {

    const { t } = useTranslation();
    const allProjects = useSelector(selectAllProjects);

    const getItems = () => {
        const menuItems = [

            {
                name: APPS.Projects.frontName,
                heading: APPS.Projects.displayName,
                customCssClass: 'menu-saved-views-header',
                appName: APPS.Projects.frontName,
            },
            {
                name: 'viewalll',
                appName: APPS.Projects.frontName,
                permissions: [PermissionsList.TIME_PROJECT_VIEW],
                icon: <ProjectIcon />,
                translate: 'sidebar.nav.menu.ViewAll',
                path: `/${APPS.Projects.frontName}`
            },
            {
                name: 'createProject',
                customCssClass: 'menu-item',
                customContent: <CreateProjectMenu></CreateProjectMenu>,
                appName: APPS.Projects.frontName,
                permissions: [PermissionsList.TIME_PROJECT_CREATE]
            },
            {
                name: 'bookmarked_projects',
                heading: t("sidebar.bookmarked_projects"),
                customCssClass: 'menu-saved-views-header',
                appName: APPS.Projects.frontName,
                permissions: [PermissionsList.TIME_PROJECT_VIEW],
            },

        ];

        const indexOfBookmarkedProjects = menuItems.map(a => a.name).indexOf('bookmarked_projects');
        const bookmarkedProjects = allProjects.filter(a => a.bookmark);

        if (bookmarkedProjects && bookmarkedProjects.length > 0) {
            bookmarkedProjects.forEach(bookmarkedProject => {
                if (bookmarkedProject && bookmarkedProject.id && bookmarkedProject.name) {
                    menuItems.splice(indexOfBookmarkedProjects + 1, 0, {
                        name: bookmarkedProject.name,
                        customCssClass: 'time-project-bookmark-item',
                        appName: APPS.Projects.frontName,
                        permissions: [PermissionsList.TIME_PROJECT_VIEW],
                        icon: <UserAvatar src={bookmarkedProject.photo} name={bookmarkedProject.name} size={24} round={true} />,
                        path: `/projects/${bookmarkedProject.id}`,

                    })
                }

            })
        } else {
            menuItems.splice(indexOfBookmarkedProjects + 1, 0, {
                name: 'empty_bookmarked_projects',
                heading: t('sidebar.Bookmark_a_project_from_Projects_page_for_easy_access'),
                appName: APPS.Projects.frontName,
                translate: "sidebar.Bookmark_a_project_from_Projects_page_for_easy_access",
                customCssClass: 'menu-saved-views-empty-comment',
                permissions: [PermissionsList.TIME_PROJECT_VIEW],
            })
        }

        return menuItems;
    }
    const getInnerMenuItems = (projectId) => {

        const menuItems = [
            {
                name: APPS.Projects.frontName,
                customContent: <ProjectTopMenu projectId={projectId} />,
                appName: APPS.Projects.frontName,
            },
            {
                name: APPS.Projects.frontName,
                className: "menu-item",
                customContent: <ReturnToList />,
                appName: APPS.Projects.frontName,
            },
            {
                name: APPS.Projects.frontName,
                heading: "Project",
                customCssClass: 'menu-saved-views-header',
                appName: APPS.Projects.frontName,
            },
            {
                name: 'overview',
                appName: APPS.Projects.frontName,
                // icon: <MemberIcon />,
                translate: 'projects.OVERVIEW',
                path: `/${APPS.Projects.frontName}/${projectId}/overview`
            },
            {
                name: 'timeline',
                appName: APPS.Projects.frontName,
                // icon: <MemberIcon />,
                translate: 'projects.team.TITLE',
                path: `/${APPS.Projects.frontName}/${projectId}/team`
            },
            {
                name: 'settings',
                appName: APPS.Projects.frontName,
                // icon: <MemberIcon />,
                translate: 'projects.SETTINGS',
                path: `/${APPS.Projects.frontName}/${projectId}/settings`
            },
        ];
        return menuItems;
    }
    return { getItems, getInnerMenuItems }
}

const CreateProjectMenu = () => {
    const { t } = useTranslation();
    return <div className="nav-item d-flex">
        <PlusIcon className="m-end-1" />
        <NewProjectForm newButtonComponent={
            <span>{t('sidebar.nav.menu.AddProject')}</span>
        } />
    </div>
}

const ProjectTopMenu = ({ projectId }) => {
    const projectFromSelector = useSelector(state => selectProjectById(state, projectId));
    const project = { ...projectFromSelector, isActive: projectFromSelector?.status === 'Active' };
    return <div className="m-start-1 p-2">
        <ProjectRowBody project={project} titleClassName={"col-md-12"} logoSize={35} />
    </div>;
}

const ReturnToList = () => {
    const { languageIsRightToLeft } = useDocumentHelper();
    return (
        // <div className="menu-item back-to-list-menu">
        <Link className="back-to-list-menu" to={"/projects"}>
            {!languageIsRightToLeft && <ArrowLeft></ArrowLeft>}
            {languageIsRightToLeft && <ArrowRight></ArrowRight>}
            View All Projects
        </Link>
        // </div>
    )
}