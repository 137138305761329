import axios from "axios";
import logger from "./logService";
import history from "../history";


axios.defaults.withCredentials = true

axios.interceptors.response.use(null, error => {
    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;
    if (error.response && error.response.status === 401) {
        if (!['/logout', '/organization-not-found', '/organization-access-denied'].some(a => a === history.location.pathname)) {
            const weburl = encodeURIComponent(window.location.href);
            window.location.href = axios.defaults.baseURL + "/sso/team/?weburl=" + weburl;
        }
    }
    if (!expectedError) {
        logger.log(error);
    }

    return Promise.reject(error);
});

const initUrl = (url, apiPort) => {
    console.log("Location: ", window.location);
    let port = "";
    switch (window.location.port) {
        case 80:
            port = "";
            break;
        case 443:
            port = "";
            break;
        case "":
            port = "";
            break;
        default:
            port = ":" + apiPort;
            break;
    }

    // console.log("Location: ", window.location.protocol + "//" + window.location.hostname + port + "/api/v1");
    axios.defaults.baseURL = url ? url : window.location.protocol + "//" + window.location.hostname + port + "/api/v1";
}

export default {
    initUrl,
    get: axios.get,
    post: axios.post,
    put: axios.put,
    patch: axios.patch,
    delete: axios.delete,
    request: axios.request
};
