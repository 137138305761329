import React from 'react'
const ProjectLogo = ({ logo, status, thumbSize = 40 }) => {
    const classNames = `member-logo align-self-center image rounded-50`;
    const IMG = () => <img src={logo} alt="Avatar"
        className={classNames}
        style={{ width: thumbSize, height: thumbSize }} />;

    return (
        <>
            {status === 'pause' ?
                <div className="pausedMemberLogoContainer">
                    <IMG></IMG>
                    <div className="middle">
                        <i className="fa fa-pause text"></i>
                    </div>
                </div>
                : <IMG></IMG>}
        </>
    )
}
export default ProjectLogo