import React from 'react';
import { APPS } from "components/Common/constants"
import { ReactComponent as MemberIcon } from 'icons/menu-icons/member-icon.svg'
export const usePeopleAppMenu = () => {
    const getItems = () => {
        return [
            {
                title: "People",
                appRoute: APPS.PeopleApp.frontName,
                defaultRoute: APPS.PeopleApp.frontName,
                icon: <MemberIcon style={{ fill: 'var(--sidebar-color)' }} />
            }
        ]
    }
    return { getItems }
}