import { APPS } from "components/Common/constants";
import { ReactComponent as MemberIcon } from 'icons/menu-icons/member-icon.svg';
import { ReactComponent as ChangePasswordIcon } from "icons/change-password-icon.svg";
import React from 'react';

export const useAccountMenu = () => {

    const getItems = () => {
        const menuItems = [

            {
                name: APPS.Account.frontName,
                heading: "My Account",
                customCssClass: 'menu-saved-views-header',
                appName: APPS.Account.frontName,
            },
            {
                name: 'profile',
                appName: APPS.Account.frontName,
                permissions: [],
                icon: <MemberIcon />,
                translate: 'sidebar.nav.menu.Profile',
                path: `/${APPS.Account.frontName}/accountSetting`
            },
            {
                name: 'Password',
                appName: APPS.Account.frontName,
                permissions: [],
                icon: <ChangePasswordIcon />,
                translate: 'sidebar.nav.menu.Password',
                path: `/${APPS.Account.frontName}/changePassword`
            }

        ];
        return menuItems;
    }
    return { getItems }
}