import { createSelector } from 'reselect'
import memoize from 'lodash.memoize'
import searchInPropsHelper from 'utils/searchInPropsHelper'
import { createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import httpService from 'utils/httpService'

const projectsAdapter = createEntityAdapter()
const emptyWorkLog = { today: 0, thisWeek: 0, thisMonth: 0, total: 0 };

export const {
    selectById: selectProjectById,
    selectIds: selectProjectIds,
    selectEntities: selectProjectEntities,
    selectAll: selectAllProjects,
    selectTotal: selectTotalProjects
} = projectsAdapter.getSelectors(state => state.project)

export const searchInProjects = createSelector(
    (state) => state.member.entities,
    (allMembers) =>
        (projects, searchingTerm, showArchivedProjects, memberId) => projects.filter(x =>
            filterProjectByMemberStatus(x, searchingTerm, showArchivedProjects, memberId, allMembers))
)
export const haveAnyDisabledProjects = createSelector(
    [],
    () => (projects, memberId) => projects.some(x => !projectIsArchivedOrMemberIsPause(x, memberId))
)
export function filterProjectByMemberStatus(project, searchingTerm, showArchivedProjects, memberId, allMembers) {
    return (searchInPropsHelper(project, ['name', 'location', 'description', 'code'], searchingTerm) || searchInProjectTeam(project, searchingTerm, allMembers))
        && ((!showArchivedProjects && projectIsArchivedOrMemberIsPause(project, memberId)) || showArchivedProjects)
}

function searchInProjectTeam(project, searchingTerm, allMembers) {
    return project.team.some(a => searchInPropsHelper(allMembers[a.id], ['name', 'email'], searchingTerm))
}

function projectIsArchivedOrMemberIsPause(project, memberId) {
    return project.status !== 'Archived' &&
        (
            !memberId || project.team.find(a => parseInt(a.id) === parseInt(memberId)).status !== 'pause'
        );
}

export const selectProjectTeam = createAsyncThunk('project/getTeam', async ({ projectId }, { getState }) => {
    const state = getState();
    var project = state.project.entities[projectId];
    var members = state.member.entities;
    var team = project.team || [];
    var teamMembers = [];


    const userLogs = await httpService.get(`/workLogs/projects/${projectId}/perMembers`);
    const allUsersLog = userLogs.data.allUsersLogs;

    team.filter(a => a.status !== "removed").forEach(teamMember => {
        var member = members[teamMember.id];
        member = { ...member, status: teamMember.status, projectRole: "project " + teamMember.role };
        const workLog = allUsersLog.find(a => a.userId === member.id);
        member.workLog = workLog ? workLog : emptyWorkLog;
        member.projectId = projectId;
        member.contract = teamMember.contract;
        teamMembers.push(member);
    });
    return teamMembers;
})

export const selectProjectsByMember = createAsyncThunk('project/getProjectsByMember', async ({ memberId }, { getState }) => {
    const allProjectEntities = getState().project.entities;
    var projects = [];
    const userLogs = await httpService.get(`/workLogs/users/${memberId}/perProjects`);
    const allUserProjectLogs = userLogs.data.allUserProjectLogs;
    for (var projectId in allProjectEntities) {
        const project = { ...allProjectEntities[projectId] };
        if (project.team.some(a => parseInt(a.id) === parseInt(memberId))) {
            const projectLog = allUserProjectLogs.find(a => a.projectId === project.id);
            project.workLog = projectLog ? projectLog : emptyWorkLog;
            project.userId = memberId;
            project.contract = project.team.find(a => parseInt(a.id) === parseInt(memberId)).contract;
            projects.push(project);
        }
    }
    return projects;
})

export const selectProjectsHasLogByMember = createAsyncThunk('project/getProjectsByMember', async ({ memberId }, { getState }) => {
    const allProjectEntities = getState().project.entities;
    var projects = [];
    const userLogs = await httpService.get(`/workLogs/users/${memberId}/perProjects`);
    const allUserProjectLogs = userLogs.data.allUserProjectLogs;
    allUserProjectLogs.forEach(projectLog => {
        const project = { ...allProjectEntities[projectLog.projectId] };
        project.workLog = projectLog;
        project.userId = memberId;
        projects.push(project);
    })
    return projects;
})

export const saveProjectPermissions = createAsyncThunk('project/permissions/savev', async ({ projectId, data }) => {
    const projectPermissions = await httpService.put(`/permissions/project/${projectId}`, data);
    return projectPermissions.data;
})


export const isProjectAdmin = createSelector(
    [selectAllProjects],
    (allProjects) => memoize(
        (projectId) => {
            if (!projectId) return false;
            var isAdmin = allProjects.some(a => a.id === parseInt(projectId) && a.roleInProject && a.roleInProject.toLowerCase() === 'admin');
            return isAdmin;
        })
)


