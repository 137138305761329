import moment from 'moment'
import jajaliMoment from 'moment-jalali'

export const getMonthNameFromMonthNumberGregorian = (month) => {

    return moment(`2020-${month}-01`, 'YYYY-MM-DD').locale("en").format('MMMM');
}

export const getMonthNameFromMonthNumberPersian = (month) => {
    jajaliMoment.loadPersian({ usePersianDigits: true })
    return jajaliMoment(`1400-${month}-01`, 'jYYYY-jMM-jDD').format('jMMMM')
}

export const getMonthNameGregorian = (date) => {
    return getMonthNameFromMonthNumberGregorian(moment(date).month() + 1);
}

export const getMonthNamePersian = (date) => {
    jajaliMoment.loadPersian({ usePersianDigits: true })
    return jajaliMoment(date).format('jMMMM')
}
