import moment from 'moment-jalali'

export function getDisplayDateGregorian(date) {
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const d = new Date(date);
    return d.getDate() + " " + monthNames[d.getMonth()] + " " + d.getFullYear();
}

export function getDisplayDateShamsi(date) {
    const m = moment(date).format('jYYYY/jMM/jDD');
    return m;
}

