import React, { lazy } from 'react';
import { Switch } from 'react-router';
import PrivateRoute from 'routes/PrivateRoute';
const DashboardApp = lazy(() => import("apps/dashboard/src"));
export const DashboardRoutesList = [
    "/dashboard",
]
const waitFor = (Tag) => (props) => <Tag {...props} />;

const DashboardRoutes = () => {
    return (
        <>
            <Switch>
                <PrivateRoute
                    exact
                    path="/dashboard"
                    component={waitFor(DashboardApp)}
                />
            </Switch>
        </>
    )
}

export default DashboardRoutes