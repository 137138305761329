import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { addContractToMember, addMemberToProjectTeam, addProject, loadMeProjects, makeUserAdminInProjectTeam, uploadProjectLogo } from 'store/actions';
import showToast from 'utils/toaster';
import { defaultAvatar } from 'components/Common/constants';
import InputWithLabel from 'components/Common/InputWithLabel';
import { NewItemInModal } from 'components/Common/NewItemInModal';
import LogoSelector from 'apps/time/src/Members/NewMemberForm/LogoSelector';
const getNewProject = () => {
    return { name: "", description: "", location: "", archived: false, photo: defaultAvatar.defaultAvatars.PROJECT, addMeAsAnAdministrator: true };
}

const NewProjectForm = ({ showInModal = true, controlledShowForm = false, newButtonText = "", newButtonComponent = null }) => {
    return (
        <>
            {showInModal && <NewProjectModal newButtonText={newButtonText} newButtonComponent={newButtonComponent} />}
            {!showInModal && controlledShowForm && <NewProjectFormContent showInModal={false} />}
        </>

    )
}
export default NewProjectForm;

export const NewProjectFormContent = ({ showInModal = true, modal, setModal, handleAfterAddNewProject }) => {

    const dispatch = useDispatch();
    const organizationID = useSelector(state => state.organization.currentOrganization.entity.id);
    const userInfo = useSelector(state => state.user.info)
    const [project, setproject] = useState(getNewProject())
    const [loading, setLoading] = useState(false);
    const [codeHasChanged, setCodeHasChanged] = useState(false)
    const { t } = useTranslation();

    function handleChange({ currentTarget: input }) {
        var newProject = { ...project };
        if (input.type === 'checkbox') {
            newProject = { ...newProject, [input.id]: input.checked ? 1 : 0 };
        } else {
            newProject = { ...newProject, [input.id]: input.value };
        }

        if (input.id === "name" && !codeHasChanged) {
            newProject.code = input.value.replace(/ /g, "-").replace(/[^a-zA-Z0-9-]+/g, "").replace(/-{2,}/g, "-").replace(/^-+/, '').replace(/-+$/, '').toLowerCase();
        }

        if (input.id === "code") {
            setCodeHasChanged(true);
        }

        setproject(newProject);
    }
    const handleFileUpload = file => {
        var newProject = { ...project };
        newProject.avatar = file;
        newProject.photo = URL.createObjectURL(newProject.avatar);
        setproject(newProject);
    }

    const handleSaveProject = async (e) => {
        e.preventDefault();
        setLoading(true);
        const result = await dispatch(addProject({
            userId: userInfo.id,
            orgId: organizationID,
            name: project.name,
            code: project.code,
            description: project.description,
            location: project.location,
            billable: project.billable,
            status: "Active"
        }));
        const hasError = false;
        if (!result.error) {
            if (project.avatar) {
                const uploadProjectLogoResult = await dispatch(uploadProjectLogo({
                    projectId: result.payload.data.id,
                    logo: project.avatar
                }));
                if (!uploadProjectLogoResult.error) {
                    showToast("MESSAGES.PROJECT_ADD_SAVED");
                }
            } else {

            }
            if (project.addMeAsAnAdministrator) {
                await dispatch(addMemberToProjectTeam({ projects: [result.payload.data.id], members: [userInfo.id] }));
                var dataToRequest = {
                    userIds: [userInfo.id],
                    rate: 0,
                    isOverwritten: false
                };
                await dispatch(makeUserAdminInProjectTeam({ projectId: result.payload.data.id, memberId: userInfo.id }));
                await dispatch(addContractToMember({ projectId: result.payload.data.id, dataToRequest: dataToRequest }));
            }
            await dispatch(loadMeProjects());
            if (!hasError) {
                showToast("MESSAGES.PROJECT_ADD_SAVED");
            }
        } else if (result.payload.message === "Project with this name is already existed") {
            showToast("MESSAGES.PROJECT_ADD_DUPLICATE", 'error');
            setLoading(false);
            return false;
        } else if (result.payload.message === 'EmailNotVerified') {
            showToast("MESSAGES.LOGIN_FAILED_EMAIL_NOT_VERIFIED", 'error');
            setLoading(false);
            return false;
        }
        if (handleAfterAddNewProject)
            await handleAfterAddNewProject(result.payload.data.id);

        toggle();
        setLoading(false);
        return result.payload.data.id
    }

    const handleRemovePhoto = async () => {
        var newProject = { ...project };
        if (newProject.avatar) {
            newProject.avatar = undefined;
            newProject.photo = defaultAvatar.defaultAvatars.PROJECT;
        }
        setproject(newProject)
    }

    const toggle = () => setModal(!modal);

    return (
        <Form onSubmit={handleSaveProject}>
            <div className={showInModal ? "p-3" : ''}>
                <Row >
                    <Col xs={7}>
                        {/* <div className='d-sm-block d-md-none'>
                            <LogoSelector width={170} height={170} marginTop={12}
                                onRemovePhoto={handleRemovePhoto} initLogo={project.photo}
                                addLabel='upload'
                                removeLabel='remove'
                                caption='projects.Photo'
                                borderRadius="4px"
                                onFileUpload={handleFileUpload} title={t('projects.Photo')} />
                        </div> */}

                        <InputWithLabel
                            autoFocus={true}
                            label={t('projects.NameEn')}
                            type="text" id="name" value={project.name}
                            required={true}
                            onChange={handleChange} />
                        <InputWithLabel label={t('projects.Code')}
                            className="english-field"
                            type="text" id="code" value={project.code}
                            required={true}
                            onChange={handleChange} />
                        <InputWithLabel label={t('projects.Location')}
                            type="text" id="location" value={project.location}
                            required={false}
                            onChange={handleChange} />

                    </Col>
                    <Col xs={5} >

                        <LogoSelector width={170} height={170} marginTop={12}
                            onRemovePhoto={handleRemovePhoto} initLogo={project.photo}
                            addLabel='upload'
                            removeLabel='remove'
                            caption='projects.Photo'
                            borderRadius="4px"
                            onFileUpload={handleFileUpload} title={t('projects.Photo')} />

                    </Col>
                </Row>

                <InputWithLabel label={t('projects.Description')}
                    type="textarea" id="description" value={project.description}
                    required={false}
                    onChange={handleChange} />
                <FormGroup check>
                    <Input id="addMeAsAnAdministrator" type="checkbox" onChange={handleChange}
                        checked={project.addMeAsAnAdministrator} />
                    {' '}
                    <Label className="custom-checkbox-label text-primary" htmlFor="addMeAsAnAdministrator" >
                        {t("projects.Add_me_to_the_project_as_an_administrator")}
                    </Label>
                </FormGroup>
                {/* <FormGroup check>
                    <Input id="billable" type="checkbox" onChange={handleChange}
                        checked={project.billable === 1} />
                    {' '}
                    <Label className="custom-checkbox-label text-primary" htmlFor="billable" >
                        {t('projects.BILLABLE')}
                    </Label>
                </FormGroup> */}
            </div>
            <div className={showInModal ? 'action-box modal-footer' : ''}>
                {showInModal && <Button color="secondary" onClick={toggle}>{t('cancel')}</Button>}
                <Button color="primary" disabled={loading} type="submit"
                    className='btn-save' value={t('Save')} id="saveInModal">
                    {!loading && t("Save")}
                    {loading && <i className="fas fa-spinner fa-spin"></i>}
                </Button>
            </div>
        </Form>
    );
}

const NewProjectModal = ({ newButtonText, newButtonComponent }) => {
    const [modal, setModal] = useState(false);
    return (
        <NewItemInModal
            modal={modal} setModal={setModal}
            newButtonComponent={newButtonComponent}
            newButtonText={newButtonText ? newButtonText : "New Project"}>
            <NewProjectFormContent modal={modal} setModal={setModal} />
        </NewItemInModal>
    );
}