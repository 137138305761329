import { Rating } from '@material-ui/lab';
import { ReactComponent as CloseIcon } from 'icons/close-icon.svg';
import { ReactComponent as FeedbackStarIcon } from "icons/feedback-star-icon.svg";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import validator from "validator";

import {
    Button,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
import httpService from "../../utils/httpService";
import showToast from "../../utils/toaster";

const FeedBackForm = ({ isOpen, setIsOpen, formType }) => {
    const { t } = useTranslation();
    const [formValue, setFormValue] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [rateValue, setRateValue] = useState(null);


    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setErrorMessage("");
    }, [formValue, formType]);

    const handleShowModal = () => {
        setIsOpen(!isOpen);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validator.isEmpty(formValue)) {
            setLoading(true);
            try {
                await httpService.post("/contact-us", {
                    text: formValue,
                    type: formType,
                    feedbackStars: rateValue
                });

                setFormValue("");
                setRateValue(null);
                setErrorMessage("");
                setLoading(false);
                handleShowModal();
                showToast("toast success");
            } catch (error) {
                setLoading(false);
                setErrorMessage(error.message);
                console.log(errorMessage);
            }
        } else {
            setLoading(false);
            showToast("toast error", "error");
        }
    };

    return (
        <>
            <form>
                <Modal
                    size="lg"
                    isOpen={isOpen}
                    toggle={handleShowModal}
                    className="mx-auto mt-5"
                >
                    <ModalHeader className="modal-title text-primary">
                        <div onClick={() => handleShowModal()} >
                            <CloseIcon className="modal-close-button" />
                        </div>
                        <span className="text-capitalize">{t(formType)}</span>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-12 col-lg-6">
                                <div className="d-flex align-items-center">
                                    <Label className="text-primary m-end-1">{`Rate:`}</Label>
                                    <Rating
                                        name="customized-empty"
                                        emptyIcon={<FeedbackStarIcon className='feedback-star-empty-icon' />}
                                        icon={<FeedbackStarIcon className='feedback-star-fill-icon' />}
                                        onChange={(event, newValue) => {
                                            setRateValue(newValue);
                                        }}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-2">
                                        <Label className="text-primary">{`FeedBack`}</Label>
                                    </div>

                                </div>
                                <Input
                                    autoFocus={true}
                                    type="textarea"
                                    rows={15}
                                    onChange={(e) => setFormValue(e.target.value)}
                                    value={formValue}
                                    required
                                ></Input>
                            </div>
                            <div className="col-md-6 col-lg-0">
                                <img src="img/feedback-image.svg" alt="" className="thumb350 d-none d-lg-block" />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className="border-0">
                        <div className="w-100 d-flex action-box">

                            <Button
                                className="mx-2"
                                color="secondary"
                                onClick={handleShowModal}
                            >
                                {t("cancel")}
                            </Button>
                            <Button
                                type="button"
                                onClick={handleSubmit}
                                color="primary"
                            >
                                {!loading && t("send")}
                                {loading && (
                                    <i className="fas fa-spinner fa-spin"></i>
                                )}
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </form>
        </>
    );
};

export default FeedBackForm;
