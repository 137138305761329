import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    /* Layout fixed. Scroll content only */
    isFixed: false,
    /* Sidebar collapsed */
    isCollapsed: false,
    /* Boxed layout */
    isBoxed: false,
    /* Floating sidebar */
    isFloat: false,
    /* Sidebar show menu on hover only */
    asideHover: false,
    /* Show sidebar scrollbar (dont' hide it) */
    asideScrollbar: false,
    /* Sidebar collapsed with big icons and text */
    isCollapsedText: false,
    /* Toggle for the offsidebar */
    offsidebarOpen: false,
    /* Toggle for the sidebar offcanvas (mobile) */
    asideToggled: false,
    /* Toggle for the sidebar user block */
    showUserBlock: false,
    /* Enables layout horizontal */
    horizontal: false,
    /* Full size layout */
    useFullLayout: false,
    /* Hide footer */
    hiddenFooter: false,
    ShowArchivedProjects: true,
    ShowDisabledMembers: true,
    Showpausedaccessprojects: true,
    workDiaryViewMode: 'tile',
    languages: [],
    calendarTypes: [],
    appsMenuIsHidden: false,
    subMenuIsHidden: true
};

const settingSlice = createSlice({
    name: 'setting',
    initialState,
    reducers: {
        toggleSetting(state, action) {
            state[action.payload] = !state[action.payload];
        },
        changeSetting(state, action) {
            state[action.payload.name] = action.payload.value
        }
    }
})

export const { toggleSetting, changeSetting } = settingSlice.actions

export default settingSlice.reducer