import React from 'react';

const MemberBillRate = ({ rate, isOverWritten , className = "",label}: { rate: string, isOverWritten: boolean,className?: string,label?: string }) => {   
    return (
        <div className={`d-flex  ${className} flex-column`}>                                   
            <div className='timeprogress-time mb-1' >
                <span style={{ color: isOverWritten ? "black" : "gray"}}>
                {rate}</span></div>                      
            {label &&
                <div className='timeprogress-label'>{label}</div>
              }
        </div>
    )
}
export default MemberBillRate