import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useState } from 'react';
import TagInput from 'components/Common/TagInput';
import { NewItemInModal } from 'components/Common/NewItemInModal';
import { useAppDispatch } from 'store/hooks';
import { inviteMembers } from 'store/actions';
import showToast from 'utils/toaster';
import InvitationList from './InvitationList';
import { Trans, useTranslation } from 'react-i18next';
import { Button, FormGroup, Label, Nav, NavItem, NavLink, TabPane, Form } from 'reactstrap';
import { useAppSelector } from 'store/store';
import SelectComponent from 'components/Common/SelectComponent';
import { Link } from 'react-router-dom';
import PermissionComponent from 'components/Common/PermissionComponent';
import { PermissionsList } from 'components/Common/constants';
import { selectAllAllowedRoles, selectTotalTeams } from 'store/selectors';
import TeamSelector from 'components/Common/TeamSelector';
import { useSelector } from 'react-redux';

const InviteUserForm = ({ reload, newButtonComponent }: { reload: any, newButtonComponent: any }) => {
    const roles = useAppSelector(selectAllAllowedRoles);
    const { t } = useTranslation();
    const totalTeams = useSelector(selectTotalTeams);
    const [modal, setModal] = useState(false);
    const [activeTab, setActiveTab] = useState('newInvite');
    const [loading, setLoading] = useState(false);

    const changeTab = (newTab: string) => {
        if (activeTab !== newTab) {
            setActiveTab(newTab);
        };
    }
    const dispatch = useAppDispatch();
    const [emailsToInvite, setNewProjectsToAddToProject] = useState<any[]>([]);
    const [role, setRole] = useState<any>();
    const [team, setTeam] = useState<number>();
    function handleDelete(i: number) {
        setNewProjectsToAddToProject(emailsToInvite.filter((_, index) => index !== i));
    }

    function handleAddition(tag: any) {
        if (!isEmailValid(tag.text)) {
            return false;
        }
        setNewProjectsToAddToProject([...emailsToInvite, tag]);
    }

    function isEmailValid(email: string) {
        const regexp = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        return regexp.test(email)
    }

    function handleDrag(tag: string, currPos: number, newPos: number) {
        const tags = [...emailsToInvite];
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
        setNewProjectsToAddToProject(newTags);
    }
    const placeHolder = t("Email_Addresses");

    const setParentTeam = useCallback((team: { value: number }) => {
        if (team) {
            setTeam(team.value);
        } else {
            setTeam(undefined);
        }
    }, [])

    const handleSaveAddNewMembers = async (e: any) => {
        e.preventDefault();

        if (emailsToInvite.length === 0) {
            showToast("Please enter at leaset one email", 'error');
            return;
        }
        setLoading(true);
        const request = {
            emails: emailsToInvite.map(a => a.text),
            roleId: role?.value,
            teamId: team
        }

        const result = await dispatch<any>(inviteMembers(request));
        if (result.error) {
            if (result.payload.msg === "User limitation excceded") {
                showToast("Invite_User_Limitation_Error", "error")
            } else {
                showToast("Invite_Error", 'error');
            }

        } else {
            showToast("Invite_Success");
            setNewProjectsToAddToProject([])
        }
        setLoading(false);
        if (reload) {
            reload();
        }
        toggle();
    }
    const options = roles.map((roleType: any) => {
        return {
            value: roleType.id,
            label: roleType.name,
        };
    });
    const handleRoleChange = (selectedOption: any) => {
        setRole(selectedOption);
    };
    const optionsJson = JSON.stringify(options);
    useEffect(() => {
        const options = JSON.parse(optionsJson);
        if (options && options.length > 0) {
            setRole(options[0])
        }
    }, [optionsJson])

    const toggle = () => setModal(!modal);

    return (
        <NewItemInModal
            newButtonComponent={newButtonComponent}
            className=""
            modal={modal} setModal={setModal}
            modalSize='lg'
            newButtonText='InviteExistingUser'>
            <Form onSubmit={handleSaveAddNewMembers}>
                <div className={"p-3"}>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={"nav-link " + (activeTab === 'newInvite' ? 'active' : '')}
                                onClick={() => { changeTab('newInvite'); }}>
                                <Trans i18nKey="Invite New Members"></Trans>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={"nav-link " + (activeTab === 'sentInvites' ? 'active' : '')}
                                onClick={() => { changeTab('sentInvites'); }}>
                                <Trans i18nKey="Invitations"></Trans>

                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabPane tabId="newInvite" className="pt-3">
                        {activeTab === 'newInvite' &&
                            <div>
                                <FormGroup className='mt-2'>
                                    <Label className='text-dark'>{placeHolder}</Label>
                                    <TagInput
                                        handleInputBlur={(a: string) => handleAddition({ id: a, text: a })}
                                        tags={emailsToInvite}
                                        placeholder={placeHolder}
                                        suggestions={[]}
                                        handleAddition={handleAddition}
                                        handleDelete={handleDelete}
                                        handleDrag={handleDrag}
                                        filterSuggesstionFields={[]}
                                        creatable={true}
                                    />

                                </FormGroup>
                                <div className='mb-3'>
                                    <div className='mb-1'>
                                        <Label className='text-dark'>{t("members.Role")}</Label>
                                        <SelectComponent
                                            placeholder={t("members.SelectRole")}
                                            classNames="timezone-select w-full"
                                            options={options}
                                            onChange={handleRoleChange}
                                            selectedOption={role} styles="" />
                                    </div>
                                    <PermissionComponent permissions={[PermissionsList.ORGANIZATION_USERMANAGEMENT_VIEW]}>
                                        <PermissionComponent permissions={[PermissionsList.ORGANIZATION_USERMANAGEMENT_ROLE]}>
                                            <div className='inviteLinks'>
                                                <Link to='/user-management/access-roles' target='_blank'>
                                                    {t('Manage_roles')}
                                                </Link>
                                            </div>
                                        </PermissionComponent>
                                    </PermissionComponent>
                                </div>
                                {totalTeams > 0 &&
                                    <div >
                                        <TeamSelector
                                            labelClassName='text-dark'
                                            className='mb-1'
                                            isMulti={false}
                                            label="Team"
                                            selected={team}
                                            setSelected={setParentTeam}
                                        />

                                        <PermissionComponent permissions={[PermissionsList.ORGANIZATION_USERMANAGEMENT_VIEW]}>
                                            <PermissionComponent permissions={[PermissionsList.ORGANIZATION_USERMANAGEMENT_TEAM]}>
                                                <div className='inviteLinks'>
                                                    <Link to='/user-management/teams' target='_blank'>
                                                        {t('Manage_Teams')}
                                                    </Link>
                                                </div>
                                            </PermissionComponent>
                                        </PermissionComponent>

                                    </div>
                                }
                            </div>
                        }
                    </TabPane>
                    <TabPane tabId="sentInvites">
                        {activeTab === 'sentInvites' &&
                            <InvitationList></InvitationList>
                        }
                    </TabPane>
                </div>
                {activeTab === 'newInvite' &&
                    <div className={'action-box modal-footer'}>
                        <Button color="secondary" onClick={toggle}>{t('cancel')}</Button>

                        <Button color="primary" disabled={loading} type="submit"
                            className='btn-save' value={t('Save')} id="saveInModal">
                            {!loading && t("Save")}
                            {loading && <i className="fas fa-spinner fa-spin"></i>}
                        </Button>
                    </div>}
            </Form>
        </NewItemInModal>
    )
}

InviteUserForm.propTypes = {
    onNewProjectsChange: PropTypes.func
}

export default InviteUserForm
