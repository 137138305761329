import { Classes, Popover2 } from "@blueprintjs/popover2";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
    Badge,
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    NavItem
} from "reactstrap";
import { InvitationStatuses } from "apps/people/models/InviteModel";
import { notificationsIsEnableInUI } from "store/selectors";
import { useAppSelector } from "store/store";
import MyInvitations from "apps/people/src/Invitation/MyInvitations";

const NotificationsMenu = () => {
    const { t } = useTranslation();

    const invites = useAppSelector((a) => a.user.invites);
    const notificationsCount = useAppSelector(
        (a) => a.notification.notificationsStatistic
    );
    const notificationsIsEnable = useAppSelector(notificationsIsEnableInUI);

    const [showMyInvitations, setShowMyInvitations] = useState(false);
    const pendingInvites =
        !invites
            ? []
            : invites.filter((a) => a.status === InvitationStatuses.Pending);
    const messageCount = pendingInvites.length + notificationsCount;

    const unreadNotificationsCountBadge = messageCount > 0 && (
        <Badge color="danger" className="mx-1">
            {messageCount}
        </Badge>
    );

    const Content = () => {
        return (
            <div className="d-flex flex-column">
                {invites.length > 0 ? (
                    <span
                        className=""
                        style={{ cursor: "pointer" }}
                        onClick={(a) => setShowMyInvitations(true)}
                    >
                        <span className="icon-envelope m-end-1"></span>
                        <Trans i18nKey="notifications.notifications"></Trans>
                        {unreadNotificationsCountBadge}
                    </span>
                ) : (
                    <div>
                        <span>{t("No new notifications")}</span>
                        {notificationsIsEnable && (
                            <>
                                <hr></hr>
                                <Link to="/notifications">
                                    <Button
                                        className={`mt-1 py-2 px-0 ${Classes.POPOVER2_DISMISS}`}
                                        color="link"
                                    >
                                        <span className="m-start-1 text-bold">
                                            <Trans i18nKey="notifications.notifications"></Trans>
                                        </span>
                                    </Button>
                                </Link>
                            </>
                        )}
                    </div>
                )}
            </div>
        );
    };

    function UserDropDownToggle() {
        return (
            <>
                <Popover2
                    minimal={true}
                    interactionKind="click"
                    popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
                    placement="bottom"
                    content={<Content></Content>}
                    renderTarget={({
                        isOpen,
                        ref,
                        ...targetProps
                    }: {
                        ref: any;
                        isOpen: boolean;
                    }) => (
                        <div
                            {...targetProps}
                            ref={ref}
                            className="notification-hints p-end-1 d-flex justify-content-between align-items-center"
                        >
                            <NavItem className="d-flex align-items-center justify-content-center">
                                <div className="m-end-3 d-flex align-items-center justify-content-center">
                                    <svg
                                        width="20"
                                        height="22"
                                        viewBox="0 0 20 22"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M10 0.25C6.15377 0.25 2.84221 2.96483 2.08789 6.7364L0.834101 13.0054C0.431378 15.019 1.50028 17.0431 3.3676 17.873C4.04942 18.176 4.74924 18.4298 5.46121 18.6343L5.56497 18.8678C6.34394 20.6205 8.08203 21.75 10 21.75C11.918 21.75 13.6561 20.6205 14.4351 18.8678L14.5388 18.6343C15.2508 18.4298 15.9506 18.176 16.6325 17.873C18.4998 17.0431 19.5687 15.019 19.166 13.0054L17.9122 6.7364C17.1578 2.96483 13.8463 0.25 10 0.25ZM13.8893 17.2581C13.8353 17.2662 13.7824 17.2802 13.7316 17.2997C11.2912 17.9284 8.70888 17.9283 6.26845 17.2997C6.21766 17.2802 6.16476 17.2662 6.11072 17.2581C5.3837 17.0623 4.66987 16.8103 3.97681 16.5023C2.74162 15.9533 2.04186 14.6151 2.30497 13.2995L3.55877 7.03057C4.17285 3.96014 6.86879 1.75 10 1.75C13.1313 1.75 15.8272 3.96014 16.4413 7.03057L17.6951 13.2995C17.9582 14.6151 17.2584 15.9533 16.0232 16.5023C15.3302 16.8103 14.6164 17.0623 13.8893 17.2581ZM7.44878 19.0731C9.13751 19.3372 10.8625 19.3372 12.5513 19.0731C11.9242 19.808 10.9973 20.25 10 20.25C9.00272 20.25 8.07582 19.808 7.44878 19.0731Z"
                                            fill="white"
                                        />
                                    </svg>
                                    <span className="notification-hints-counts">
                                        {unreadNotificationsCountBadge}
                                    </span>
                                </div>
                            </NavItem>
                        </div>
                    )}
                />
            </>
        );
    }

    return (
        <>
            <UserDropDownToggle></UserDropDownToggle>
            <Modal isOpen={showMyInvitations} size="lg">
                <ModalHeader>{t("myInvitations")}</ModalHeader>
                <ModalBody className="d-flex justify-content-center align-items-center flex-column">
                    <MyInvitations closeModal={() => setShowMyInvitations(false)}></MyInvitations>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="secondary"
                        onClick={() => setShowMyInvitations(false)}
                    >
                        {t("cancel")}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

NotificationsMenu.propTypes = {};

export default NotificationsMenu;
