import React from "react";
import UserAvatar from "./UserAvatar";

const DEFAULT_MAX_AVATARS = 5;

function renderRemaining(props: any) {
    const { avatars = [], maxAvatars = DEFAULT_MAX_AVATARS, ...other } = props;
    const remaining = avatars.length - maxAvatars;

    if (remaining < 1) return null;
    return <UserAvatar {...other} value={`+${remaining}`} color="gray" />;
}

const StackedAvatars = (props: any) => {
    const { avatars = [], maxAvatars = DEFAULT_MAX_AVATARS, ...others } = props;

    const style = {
        border: "1px solid white",
        ...props.style,
        marginLeft: -(props.size / 2) + "px"
    };
    return (
        <div className="d-inline" style={{ marginLeft: props.size / 2 }}>
            {avatars.slice(0, maxAvatars).map((avatar: any, idx: any) => (


                <UserAvatar {...avatar} {...others} key={idx} style={style} />
            ))}
            {renderRemaining({ ...props, style })}
        </div>
    );
}

export default StackedAvatars;