import { APPS, PermissionsList } from "components/Common/constants";
import { ReactComponent as MemberIcon } from 'icons/menu-icons/member-icon.svg';
import { ReactComponent as AllOKRIcon } from 'icons/menu-icons/okr-all-icon.svg';
import { ReactComponent as SavedViewsIcon } from 'icons/menu-icons/okr-saved-views-icon.svg';
import { ReactComponent as PersonalIcon } from 'icons/user-icon.svg';
import React from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { getAllUserTeams, selectAllOkrSavedViews } from 'store/selectors';

export const useGoalMenu = () => {
    const { t } = useTranslation();
    const allOkrSavedViews = useSelector(selectAllOkrSavedViews);
    const userId = useSelector(a => a.user.info.id);
    const userTeams = useSelector(state => getAllUserTeams(state)(userId));

    const hasTeam = userTeams.length > 0;
    // const upgradeMethod = useSelector(state => state.serverConfig.config.upgradeMethod.method);

    const getItems = () => {
        const menuItems = [
            {
                name: 'OKR',
                heading: "Goals",
                customCssClass: 'menu-saved-views-header',
                appName: APPS.OKR.frontName,
                permissions: [PermissionsList.OKR_GOALS_VIEW],
            },
            {
                name: 'Mine',
                appName: APPS.OKR.frontName,
                translate: "OKR.Mine",
                permissions: [PermissionsList.OKR_GOALS_VIEW],
                path: '/goals/list/mine',
                icon: <PersonalIcon />,
            }, {
                name: 'Team',
                appName: APPS.OKR.frontName,
                translate: "OKR.Team",
                permissions: [PermissionsList.OKR_GOALS_VIEW],
                path: '/goals/list/team',
                icon: <MemberIcon style={{ fill: 'var(--sidebar-color)' }} />,
                hidden: !hasTeam
            }, {
                name: 'All',
                translate: "OKR.All",
                appName: APPS.OKR.frontName,
                permissions: [PermissionsList.OKR_GOALS_VIEW],
                icon: <AllOKRIcon />,
                path: '/goals/list/all',
            },
            {
                name: 'saved_goals_views',
                heading: t("OKR.SavedGoalViews"),
                customCssClass: 'menu-saved-views-header',
                appName: APPS.OKR.frontName,
                permissions: [PermissionsList.OKR_GOALS_VIEW],

            },
            // {
            //     name: 'Upgrade Goals',
            //     appName: APPS.OKR.frontName,
            //     permissions: [PermissionsList.ORGANIZATION_SUBSCRIPTION_VIEW],
            //     icon: <UpgradeIcon />,
            //     translate: 'OKR.UpgradeGoals',
            //     path: '/goals/upgrade',
            //     hidden: upgradeMethod === 'hidden'
            // },
        ];
        const indexOfOkrSavedViewsMenu = menuItems.map(a => a.name).indexOf('saved_goals_views');

        if (allOkrSavedViews && allOkrSavedViews.length > 0) {
            allOkrSavedViews.forEach(savedView => {
                if (savedView && savedView.id && savedView.title) {


                    menuItems.splice(indexOfOkrSavedViewsMenu + 1, 0, {
                        name: savedView.title,
                        appName: APPS.OKR.frontName,
                        permissions: [PermissionsList.OKR_GOALS_VIEW],
                        icon: <SavedViewsIcon />,
                        path: `/goals/list/${savedView.id}`,

                    })
                }

            })
        } else {
            menuItems.splice(indexOfOkrSavedViewsMenu + 1, 0, {
                name: 'empty_saved_okr_views',
                heading: t('OKR.Create_a_custom_set_of_filters_and_save_it_for_future_reference'),
                appName: APPS.OKR.frontName,
                translate: "OKR.Create_a_custom_set_of_filters_and_save_it_for_future_reference",
                customCssClass: 'menu-saved-views-empty-comment',
                permissions: [PermissionsList.OKR_GOALS_VIEW],
            })
        }
        return menuItems;
    }
    return { getItems }
}