
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label, Tooltip } from 'reactstrap';
import { ReactComponent as EyeIcon } from 'icons/eye-icon.svg';
import { ReactComponent as EyeSlashIcon } from 'icons/eye-slash-icon.svg';

const InputWithLabel = ({ enableCopyOnClick, label, showLabel = true, type, required, id, value, onChange, autoFocus = false, readOnly,
    minLength, maxLength, style, className, tabindex, inline, placeholder,
    disabled, formGroupClass = '', max = null, min = null, pattern = null }: any) => {
    const ref = useRef() as any;
    const { t } = useTranslation();
    if (value === undefined) {
        value = '';
    }
    const [hidePassword, setHidePassword] = useState(true);
    const [focusSetted, setFocuesSetted] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [tooltipText, setTooltipText] = useState("Click to copy!");
    const setAutoFocus = useCallback(
        () => {
            if (!autoFocus || disabled || focusSetted) return
            if (ref && ref.current) {
                ref.current.focus();
                setFocuesSetted(true);
            }
        },
        [autoFocus, focusSetted, ref, disabled],
    )

    useEffect(() => {
        setAutoFocus();
    }, [setAutoFocus])
    const copyToClipboard = () => {
        if (!enableCopyOnClick) return;
        navigator.clipboard.writeText(value);
        setTooltipText("Copied!")
        setTimeout(() => {
            setTooltipText("Click to copy!")
        }, 2000)
    }
    return (
        <FormGroup style={{ marginBottom: "0px !important" }} className={formGroupClass} inline={true}>

            {/* {showLabel && <Label htmlFor={id} className="text-primary">{`${label} ${!required && !readOnly ? '(' + t('OPTIONAL') + ')' : ''}`}</Label>} */}
            {showLabel && <Label htmlFor={id} className="text-primary">{`${label} ${!required && !readOnly ? '' : ''}`}</Label>}
            {!readOnly ?
                type === "password" ?
                    <div className="d-flex">
                        <input ref={ref} autoFocus={autoFocus} disabled={disabled} tabIndex={tabindex}
                            min={4}
                            data-testid={id} required={required}

                            type={hidePassword ? "password" : "text"} id={id} value={value} onChange={onChange} style={style} className={'form-control ' + className}
                            placeholder={placeholder ? placeholder : `${t('PLACEHOLDERPREFIX')} ${label} ${t('PLACEHOLDERPOSTFIX')}`}
                        />
                        <div className="clickable d-flex" onClick={() => { setHidePassword(!hidePassword); setFocuesSetted(false); }} style={{ marginInlineStart: "-32px" }}>
                            <span className='align-self-center'>
                                {hidePassword ? <EyeIcon width={20} height={20} fill="#00000070" /> :
                                    <EyeSlashIcon width={20} height={20} fill="#00000070" />
                                }
                            </span>
                        </div>
                    </div>
                    :
                    <input pattern={pattern} min={min} max={max} ref={ref} autoFocus={autoFocus} disabled={disabled} tabIndex={tabindex} minLength={minLength} maxLength={maxLength} data-testid={id} required={required}
                        type={type} id={id} value={value} onChange={onChange} style={style} className={`form-control className`}
                        placeholder={placeholder ? placeholder : `${t('PLACEHOLDERPREFIX')} ${!label ? "" : label} ${t('PLACEHOLDERPOSTFIX')}`}
                    />
                :
                <>
                    <Label id={id} className={className + " form-control readonly-label" + (enableCopyOnClick ? ' clickable' : '')} onClick={copyToClipboard}>{value}</Label>

                    {enableCopyOnClick &&
                        <Tooltip placement="bottom" isOpen={tooltipOpen} target={id} toggle={() => setTooltipOpen(!tooltipOpen)}>
                            {tooltipText}
                        </Tooltip>
                    }
                </>

            }
        </FormGroup>
    )
}

export default InputWithLabel
