import React from 'react';
import { useAppSelector } from 'store/store';
import { selectMemberEntities } from 'store/selectors';
import StackedAvatars from 'components/Common/StackedAvatars';

const UsersAvatars = ({ userIds, size = 30 }: { userIds: number[], size?: number }) => {
    const members = useAppSelector(selectMemberEntities);
    const team = userIds.map((teamMember: any) => members[teamMember]);
    const avatars = team.map((member: any) => {

        return { src: member ? member.photo : "", name: member ? member.name : "" }

    });
    return <StackedAvatars maxAvatars={3} round={true} size={size} avatars={avatars} ></StackedAvatars>;
}

export default UsersAvatars;