import React from 'react';
import { APPS } from "components/Common/constants"
import { ReactComponent as ProjectIcon } from 'icons/menu-icons/projects-icon.svg';
export const useProjectAppMenu = () => {
    const getItems = () => {
        return [
            {
                title: "Projects",
                appRoute: APPS.Projects.frontName,
                defaultRoute: APPS.Projects.frontName,
                icon: <ProjectIcon />
            }
        ]
    }
    return { getItems }
}