import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { TeamModel } from "apps/people/models/TeamModel";
import { memoize } from "lodash";
import { selectAllMembers } from "./member.selector";

const teamViewAdapter = createEntityAdapter<TeamModel>()
export const {
    selectById: selectTeamById,
    selectIds: selectTeamIds,
    selectEntities: selectAllTeamEntities,
    selectAll: selectAllTeams,
    selectTotal: selectTotalTeams
} = teamViewAdapter.getSelectors((state: any) => state.teams)


export const getAllUserTeams = createSelector(
    [selectAllTeams],
    (allTeams) => memoize(
        (userId) => {
            if (!userId) return [];
            var allteams = allTeams.filter(a => a.managerId === parseInt(userId) || a.members.some(b => b.userId === userId));
            return allteams;
        })
)

export const getAllUserTeammates = createSelector(
    selectAllTeams,
    selectAllMembers,
    (allTeams, members) => memoize(
        (userId) => {
            if (!userId) return [];
            var allUserTeams = allTeams.filter(a => a.managerId === parseInt(userId) || a.members.some(b => b.userId === userId));
            var teamsMembers = allUserTeams.flatMap(a => a.members);
            var teamsManagers = allUserTeams.map(a => a.managerId);

            return {
                teammates: members.filter(a => teamsMembers.some(b => b.userId === a.id) || teamsManagers.some(b => b === a.id)),
                teams: allUserTeams
            };
        })
)

