import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { normalize, schema } from 'normalizr'
import httpService from 'utils/httpService'

const rolesAdapter = createEntityAdapter({})

const roleEntity = new schema.Entity('roles')

export const loadRoles = createAsyncThunk('roles/load', async (_) => {
    const response = await httpService.get(`/roles`);
    const normalized = normalize(response.data.roles, [roleEntity])
    return normalized.entities
})

export const addRole = createAsyncThunk('roles/add', async (role: any, { rejectWithValue }) => {
    try {
        const response = await httpService.post('/roles', role)
        return { data: response.data.role }
    } catch (err: any) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
        return rejectWithValue(error.response.data)
    }
})

export const updateRole = createAsyncThunk('roles/update', async (role: any, { rejectWithValue }) => {
    try {
        const response = await httpService.put(`/roles/${role.id}`, role)
        return response.data.role
    } catch (err: any) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
        return rejectWithValue(error.response.data)
    }
})

const memberSlice = createSlice({
    name: 'role',
    initialState: rolesAdapter.getInitialState(),
    reducers: {

    },

    extraReducers: builder => {
        builder
            .addCase(loadRoles.fulfilled, (state, action) => {
                rolesAdapter.setAll(state, action.payload.roles || [])
            }).addCase(addRole.fulfilled, (state, action) => {
                rolesAdapter.addOne(state, {
                    ...action.payload.data
                })
            }).addCase(updateRole.fulfilled, (state, action) => {
                rolesAdapter.updateOne(state, {
                    id: action.payload.id,
                    changes: action.payload
                })
            })
    }
})

export default memberSlice.reducer