import { PermissionsList } from 'components/Common/constants'
import PermissionComponent from 'components/Common/PermissionComponent'
import SinceDate from 'components/Common/SinceDate'
import TimeProgress from 'components/Common/TimeProgress'
import UserAvatar from 'components/Common/UserAvatar'
import { useQueryOnMedia } from 'hooks/useQueryOnMediaHook'
import { ReactComponent as FavoriteIcon } from 'icons/favorite-icon.svg'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CardSubtitle, CardTitle, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { bookmarProject, deleteBookmarkProject } from 'store/actions'
import MemberBillRate from '../../Members/MemberBillRate'
import ProjectAccessSetting from '../../ProjectAccessSetting/ProjectAccessSetting'
import ProjectArchivedBadge from './ProjectArchivedBadge'
import TeamAvatars from './TeamAvatars'


const ProjectRowBody = ({ project, projectActionHandlers, showMenu, projectAccessStatus, showMetaData, showrate, logoSize = 42 }) => {
    const dispatch = useDispatch();
    const isProjectBookmarked = project.bookmark; //useSelector(state => isUserBookmarkedThisProject(state)(project.id));
    const organization = useSelector(state => state.organization.currentOrganization.entity);

    const [showFavorite, setShowFavorite] = useState(false);

    const projectToShow = { ...project }
    function CreatedAtDisplayDate() {
        return (
            <SinceDate date={project.createdAt}></SinceDate>
        )
    }
    const { t } = useTranslation();
    const { isMobileOrTablet } = useQueryOnMedia();
    const isArchived = projectToShow.status === 'Archived';
    const opacityWithProjectStatus = isArchived && showMetaData ? "disabled" : "";
    const [isBookmarked, setIsBookmarked] = useState(false)
    useEffect(() => {
        // setShowFavorite()
        if (isMobileOrTablet) {
            setShowFavorite(true);
        }
        setIsBookmarked(!showMenu && isProjectBookmarked);
    }, [isMobileOrTablet, isProjectBookmarked, showMenu, showMetaData])


    function showMemberAvatars(project) {
        return <TeamAvatars project={project} />
    }
    const memberBillRate = project?.contract?.rate;
    const isOverWritten = project?.contract?.isOverwritten;

    const bookmark = async (e) => {
        e.stopPropagation();
        await dispatch(bookmarProject(project.id));
    };
    const deleteBookmark = async (e) => {
        e.stopPropagation();
        await dispatch(deleteBookmarkProject(project.id));
    };

    return (
        <div className="row" onMouseOver={() => setShowFavorite(!showMenu && showMetaData ? true : false)} onMouseLeave={() => setShowFavorite(false)}>
            <div className={`col ${showMenu ? 'col-md-auto' : 'col-md-auto'} flex-grow-1 flex-row`}>
                <div className={"row project-row " + (showMetaData ? "" : " p-start-2 ") + opacityWithProjectStatus}>
                    <div className={showMetaData ? "col-md-12  align-items-center" : "row"}>
                        <div className={showMetaData ? '' : 'col col-md  d-flex flex-row align-items-center'}>
                            <div className={showMetaData ? 'pt-15 text-align-center' : 'd-i'}>
                                <div className={(showMenu || ((showFavorite || isBookmarked) && showMetaData)) ? 'p-l-41 d-i' : ''}>
                                    {/* <ProjectLogo logo={projectToShow.photo}
                                        status={projectAccessStatus}
                                        thumbSize={showMetaData ? 42 : 42} /> */}

                                    <UserAvatar src={projectToShow.photo} name={projectToShow.name} size={logoSize} round={true} />

                                </div>
                                {showMetaData && !showMenu && (showFavorite || isBookmarked) && <div onClick={!isBookmarked ? bookmark : deleteBookmark} className={`add-favorite-button ${isBookmarked ? 'active' : ''}`}>
                                    <FavoriteIcon />
                                </div>
                                }

                                <PermissionComponent permissions={[PermissionsList.TIME_PROJECT_UPDATE]} projectId={project.id}>
                                    {
                                        showMenu &&
                                        <ProjectHumbergerMenu projectActionHandlers={projectActionHandlers} project={project} projectAccessStatus={projectAccessStatus}></ProjectHumbergerMenu>
                                    }
                                </PermissionComponent>

                            </div>
                        </div>

                        <div className={"project-row-name " + (showMetaData ? ' pt-3 align-items-center col-md-12 pt-15 text-align-center' : 'd-i')}>
                            <CardTitle tag="h4" className={`${!showMetaData ? 'mb-0' : ''}`}>
                                <div className={`list-item-title ${!showMetaData && !showMenu ? ' cut-text-110 ' : ''}`}>
                                    {projectToShow.name}
                                </div>
                                {(showMetaData || showMenu) &&
                                    <ProjectArchivedBadge archived={isArchived} />
                                }
                            </CardTitle>
                            <CardSubtitle tag="h6" className="project-form-card-subtitle">
                                <CreatedAtDisplayDate></CreatedAtDisplayDate>
                            </CardSubtitle>
                        </div>
                        {/* {!showMetaData && !showMenu &&
                            <ProjectArchivedBadge archived={isArchived} />
                        } */}
                        {!showMetaData && !showMenu &&
                            <div onClick={!isBookmarked ? bookmark : deleteBookmark} className={`add-favorite-button ${isBookmarked ? 'active' : ''}`}>
                                <FavoriteIcon />
                            </div>
                        }
                        <div className="project-row-statistics text-align-center d-flex d-row pt-15 m-t-20">
                            {
                                (showrate && memberBillRate) &&
                                <MemberBillRate rate={`${memberBillRate ? organization.currency + ' ' + memberBillRate : '-'}`}
                                    isOverWritten={isOverWritten}
                                    label={t('members.HourlyRate')}
                                    className="mx-2 align-items-center"></MemberBillRate>
                            }
                            {showMetaData &&
                                <div className={`d-flex d-row justify-content-center ${showrate && memberBillRate ? 'col-8' : 'col-12'}`}>
                                    <TimeProgress time={project.workLog.today} label={t('Today')} progress={project.workLog.todayPercent || 0} className="mx-2 m-end-2 align-items-center"></TimeProgress>
                                    <TimeProgress time={project.workLog.thisWeek} label={t('ThisWeek')} progress={project.workLog.thisWeekPercent || 0} className="mx-2 align-items-center"></TimeProgress>
                                </div>
                            }
                        </div>
                        {
                            (showMetaData && !showMenu) &&
                            <div className='pt-15 text-align-center m-t-20'>
                                {showMemberAvatars(project)}
                            </div>
                        }
                    </div>
                </div>
                {!showMetaData && !showMenu &&
                    <div className='m-start-6'>
                        <ProjectArchivedBadge archived={isArchived} />
                    </div>
                }
            </div>
        </div>
    )
}

export default ProjectRowBody

const ProjectHumbergerMenu = ({ projectActionHandlers, project, projectAccessStatus }) => {
    const [projectAccessSettingModal, setProjectAccessSettingModal] = useState(false);

    const { t } = useTranslation();
    function openProjectAccessSettingModal() {
        setProjectAccessSettingModal(true);
    }

    const [dropdownOpen, setDropdownOpen] = useState(false);
    function removeMemberFromProject() {
        projectActionHandlers.onRemove(project.id)
    }
    function pauseMemberFromProjectTeam() {
        projectActionHandlers.onPause(project.id)
    }
    function resumeMemberFromProjectTeam() {
        projectActionHandlers.onResume(project.id)
    }
    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <>
            <Dropdown className="m-start-2 nested-link f-r"
                isOpen={dropdownOpen} toggle={toggle} tag="span"
            >
                <DropdownToggle tag="span"
                    data-toggle="dropdown"
                    aria-expanded={dropdownOpen}
                    style={{ cursor: "pointer", fontSize: "17px" }}
                    className="btn-hover-light-primary  py-1 px-2 rounded"
                >
                    <i className="fa fa-ellipsis-v" ></i>
                </DropdownToggle>

                <DropdownMenu>
                    <DropdownItem onClick={openProjectAccessSettingModal}>
                        <i className="fas fa-cog m-end-1"></i>
                        {t('Project Access Setting')}
                    </DropdownItem>
                    {projectAccessStatus === 'active' ?
                        <DropdownItem onClick={pauseMemberFromProjectTeam}>
                            <i className="fa fa-pause m-end-1"></i>
                            {t("members.projects.PAUSE_ACCESS_TO_PROJECT")}
                        </DropdownItem>
                        : <DropdownItem onClick={resumeMemberFromProjectTeam}>
                            <i className="fas fa-lock-open m-end-1"></i>
                            {t("members.projects.RESUME_ACCESS_TO_PROJECT")}
                        </DropdownItem>
                    }
                    <DropdownItem divider />
                    <DropdownItem onClick={removeMemberFromProject} style={{ color: 'red' }}>
                        <i className="fas fa-trash-alt m-end-1"></i>
                        {t("members.projects.REMOVE_FROM_PROJECT")}
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
            <ProjectAccessSetting
                className=""
                modalIsOpen={projectAccessSettingModal}
                userId={project.userId}
                projectId={project.id}
                toggle={() => setProjectAccessSettingModal(!projectAccessSettingModal)}
            />
        </>
    )
}