import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckBox } from './CheckBox';

export const ProjectAccessSettingWorkingHours = ({ projectConfigToShow, handleChange, toggleProp }) => {
    const { t } = useTranslation();
    return (
        <div>

            <div>
                <CheckBox
                    inline={true}
                    checked={projectConfigToShow.enableLimitUserHours}
                    handleChange={toggleProp}
                    label={'Limit User Hours To'}
                    fieldName="enableLimitUserHours" />
                <input type="text" id="limitUserHours"
                    disabled={!projectConfigToShow.enableLimitUserHours}
                    name="enableLogManually"
                    className="form-control inline input-fixer"
                    style={{ width: 40, textAlign: "center" }}
                    value={projectConfigToShow.limitUserHours}
                    onChange={handleChange} />
                <label className="control-label mx-1">
                    {t('hours per')}
                </label>

                <select className="form-control inline w-auto input-fixer"
                    disabled={!projectConfigToShow.enableLimitUserHours}
                    id="limitUserHoursPer"
                    value={projectConfigToShow.limitUserHoursPer}
                    onChange={handleChange}>
                    <option value={"Day"}>{t('day')}</option>
                    <option value={"Week"}>{t('week')}</option>
                    <option value={"Month"}>{t('month')}</option>
                </select>
            </div>
            <CheckBox
                inline={true}
                checked={projectConfigToShow.userCanRemoveTimeSlot}
                handleChange={toggleProp}
                label={'User can remove time slot'}
                fieldName="userCanRemoveTimeSlot" />
            <div>
                <CheckBox
                    // inline={true}
                    checked={projectConfigToShow.enableLogManually}
                    handleChange={toggleProp}
                    label={'Log Manually'}
                    fieldName="enableLogManually" />
                <div className="m-start-3" style={{ marginTop: -8 }}>
                    <small className="text-muted">{t('User can log working time manually')}</small>
                </div>
            </div>

        </div>
    );
};
