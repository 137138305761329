import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { getGoogleAnalytics, getHotjarConfig } from 'store/selectors';
import { hotjar } from "react-hotjar";
import ReactGA from 'react-ga4';

export const ThirdPartyIntegrations = () => {
    return (
        <>
            <Hotjar></Hotjar>
            <GoogleAnalytics></GoogleAnalytics>
        </>
    )
}

const Hotjar = () => {
    const hotjarConfig = useSelector(getHotjarConfig);
    const hotjarConfigJson = hotjarConfig ? JSON.stringify(hotjarConfig) : null;
    const inithotjar = useCallback(
        () => {
            if (hotjarConfigJson) {
                const hotjarConfiguration = JSON.parse(hotjarConfigJson);
                if (hotjarConfiguration && hotjarConfiguration.hotjarId && hotjarConfiguration.hotjarJsVersion) {
                    hotjar.initialize(hotjarConfiguration.hotjarId, hotjarConfiguration.hotjarJsVersion);
                    console.log("config hotjar: ", hotjarConfiguration)
                }
            }
        },
        [hotjarConfigJson],
    );

    useEffect(() => {
        inithotjar();
    }, [inithotjar])

    return <></>;
}

const GoogleAnalytics = () => {
    const ga = useSelector(getGoogleAnalytics);

    const gaJson = ga ? JSON.stringify(ga) : null;
    const initGoogleAnalytics = useCallback(
        () => {
            if (gaJson) {
                const gaConfig = JSON.parse(gaJson);
                if (gaConfig && gaConfig.gaId) {
                    console.log(gaConfig.gaId)
                    ReactGA.initialize(gaConfig.gaId);
                    ReactGA.send("pageview");
                }
            }

        },
        [gaJson],
    );

    useEffect(() => {
        initGoogleAnalytics()
    }, [initGoogleAnalytics])
    return <></>
}