import { createAsyncThunk, createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';
import httpService from "utils/httpService";

const membersAdapter = createEntityAdapter({ sortComparer: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt) })

const userEntity = new schema.Entity('members')
const emptyWorkLog = { today: 0, thisWeek: 0, thisMonth: 0, total: 0 };
export const loadMembers = createAsyncThunk('members/load', async (_, { getState }) => {
    const response = await httpService.get(`/organizations/members`);

    const workLogs = await httpService.get('/workLogs/users/all');
    response.data.forEach((member: any) => {
        let workLog = workLogs.data.allUsersLogs.find((a: any) => a.userId === member.id);
        if (!workLog) {
            workLog = emptyWorkLog;
        }
        member.workLog = workLog;
    });
    const normalized = normalize(response.data, [userEntity])
    return normalized.entities
})

export const loadAllUsersWorklogs = createAsyncThunk('members/loadWorklogs', async () => {
    const workLogs = await httpService.get('/workLogs/users/all');
    return workLogs.data.allUsersLogs;
})

export const loadUserWorklogs = createAsyncThunk('members/loadUserWorklogs', async (userId) => {
    const workLogs = await httpService.get(`/workLogs/users/${userId}`);
    return workLogs.data.userLogs;
})

export const addMember = createAsyncThunk('members/add', async (member: any, { getState }) => {
    const response = await httpService.post(`/organizations/members`, member)
    response.data.data._pivot_roleId = member.role;
    return response.data
})

export const activateMember = createAsyncThunk('members/activate', async (userId: number, { rejectWithValue }) => {
    try {
        await httpService.put(`/organizations/members/${userId}/active`)
        return { userId }
    } catch (err: any) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
        return rejectWithValue(error.response.data)
    }
})

export const deactivateMember = createAsyncThunk('members/deactivate', async (userId: number) => {
    await httpService.delete(`/organizations/members/${userId}`)
    return { userId }
})

export const changeMemberRole = createAsyncThunk('members/changeRole', async ({ userId, role }: { userId: number, role: string }) => {
    await httpService.put(`/organizations/members/${userId}/role`, { role })
    return { userId, role }
})

export const changeMemberBillRate = createAsyncThunk('members/billingrate', async ({ userId, billingRate }: { userId: number, billingRate: any }) => {
    await httpService.put(`/organizations/members/${userId}/billingrate`, { billingRate })
    return { userId, billingRate }
})

export const changeMemberTeam = createAsyncThunk('members/team', async ({ userId, teamId }: { userId: number, teamId: any }) => {
    await httpService.put(`/organizations/members/${userId}/team`, { teamId: teamId ? teamId : null })
    return { userId, teamId }
})

export const getInvites = createAsyncThunk('members/invites', async () => {
    const response = await httpService.get(`/organizations/invites`)
    return response.data
})

export const cancelInvitation = createAsyncThunk('members/invites/cancel', async ({ inviteId }: { inviteId: number }) => {
    const response = await httpService.put(`/invites/cancel/${inviteId}`)
    return response.data
})

export const inviteMembers = createAsyncThunk('members/invite', async (members: any, { rejectWithValue }) => {
    try {
        const response = await httpService.post(`/invites`, members)
        return response.data
    } catch (err: any) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
        return rejectWithValue(error.response.data)
    }
})

export const resendInvitation = createAsyncThunk('members/invite/resend', async (invite: any, { rejectWithValue }) => {
    try {
        const response = await httpService.post(`/invites/resend/${invite.id}`, { email: invite.email })
        return response.data
    } catch (err: any) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
        return rejectWithValue(error.response.data)
    }
})

export const uploadMemberAvatar = createAsyncThunk('members/uploadAvatar', async ({ logo, userId }: { logo: any, userId: any }) => {
    const formData = new FormData();
    formData.append('avatar', logo);
    const response = await httpService.post(`/users/${userId}/picture`, formData)
    return { userId, photo: response.data.user.photo }
})

export const removeMember = createAsyncThunk('members/delete', async (member, { getState }) => {
    const response = await httpService.delete(`/organizations/members`, { data: member });
    return response.data
})

const memberSlice = createSlice({
    name: 'member',
    initialState: membersAdapter.getInitialState(),
    reducers: {
        currentUserModified(state, action) {
            membersAdapter.updateOne(state, {
                id: action.payload.id,
                changes: action.payload
            })
        },
        resetMembers(state) {
            membersAdapter.setAll(state, []);
        }
    },

    extraReducers: builder => {
        builder
            .addCase(loadMembers.fulfilled, (state, action) => {
                membersAdapter.setAll(state, action.payload.members || [])
            }).addCase(addMember.fulfilled, (state, action) => {
                delete action.payload.data.password;
                membersAdapter.addOne(state, { ...action.payload.data, workLog: emptyWorkLog })
            }).addCase(activateMember.fulfilled, (state, action) => {
                membersAdapter.updateOne(state, {
                    id: action.payload.userId,
                    changes: { _pivot_status: 'active' }
                })
            }).addCase(deactivateMember.fulfilled, (state, action) => {
                membersAdapter.updateOne(state, {
                    id: action.payload.userId,
                    changes: { _pivot_status: 'removed' }
                })
            }).addCase(changeMemberRole.fulfilled, (state, action) => {
                membersAdapter.updateOne(state, {
                    id: action.payload.userId,
                    changes: { _pivot_roleId: action.payload.role }
                })
            }).addCase(changeMemberBillRate.fulfilled, (state, action) => {
                membersAdapter.updateOne(state, {
                    id: action.payload.userId,
                    changes: { _pivot_billingRate: action.payload.billingRate }
                })
            }).addCase(changeMemberTeam.fulfilled, (state, action) => {
                membersAdapter.updateOne(state, {
                    id: action.payload.userId,
                    changes: { _pivot_teamId: action.payload.teamId }
                })
            }).addCase(uploadMemberAvatar.fulfilled, (state, action) => {
                const { userId, photo } = action.payload
                membersAdapter.updateOne(state, {
                    id: userId,
                    changes: {
                        photo: photo + "?rand=" + Date.now()
                    }
                })
            })
            .addCase(loadAllUsersWorklogs.fulfilled, (state, action) => {
                membersAdapter.updateMany(state, action.payload.map((worklog: any) => {
                    return {
                        id: worklog.userId, changes: {
                            workLog: worklog
                        }
                    }
                }))
            })
            .addCase(loadUserWorklogs.fulfilled, (state, action) => {
                membersAdapter.updateOne(state, {
                    id: action.payload.userId, changes: {
                        workLog: action.payload
                    }
                }
                )
            })
            .addCase(removeMember.fulfilled, (state, action) => {
                membersAdapter.removeOne(state, action.payload.data.user_id);
            })
            .addMatcher(isAnyOf(loadMembers.pending,
                inviteMembers.pending,
                loadUserWorklogs.pending,
                addMember.pending), (state: any) => { state.loading = true })
            .addMatcher(isAnyOf(loadMembers.rejected, addMember.rejected,
                loadMembers.fulfilled,
                inviteMembers.fulfilled,
                inviteMembers.rejected,
                loadUserWorklogs.fulfilled,
                loadUserWorklogs.rejected,
                addMember.fulfilled)
                , (state: any) => { state.loading = false })
    }
})
export const { currentUserModified, resetMembers } = memberSlice.actions;

export default memberSlice.reducer