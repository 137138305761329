import history from 'history.js';
import { ReactComponent as SupportIcon } from "icons/support-icon.svg";
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { isAuthenticate } from 'store/selectors';
import ContactUsForm from './ContactUsForm';
import { useTranslation } from 'react-i18next';

const NotFound = ({ resourceName = "page", showLinks = false }) => {
    const [isContactUsOpen, setIsContactUsOpen] = useState(false);
    const isAuth = useSelector(isAuthenticate);
    const { t } = useTranslation();
    return (
        <div className='d-flex align-items-center justify-content-center h-100 w-100 flex-column'>
            <img alt="" src="img/404 Error.svg" className='thumb350' />
            <h3 className='mt-3'>{t('It_looks_like_this')+ " " + t(resourceName) + " " + t('doesnt_exist')}</h3>
            <div className='d-flex mt-3'>
                {showLinks &&
                    <Button color='link' className='m-end-3' onClick={() => { history.push("/") }}>{t('NavigateToHome')}</Button>
                }
                {isAuth &&
                    <Button color='primary' onClick={() => setIsContactUsOpen(true)}>
                        <SupportIcon color='white' fill='white' />
                        {t('ContactSupport')}
                    </Button>
                }
            </div>
            <ContactUsForm
                formType={"SUPPORT"}
                isOpen={isContactUsOpen}
                setIsOpen={setIsContactUsOpen}
            />
        </div>);

}

export default NotFound;