import numeral from 'numeral'
import React from 'react'
export const getWorkTimeDisplay = (time: any) => {
    function timeConvert(n: number) {
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        return { rhours, rminutes };
    }
    const timeToDisplay = numeral(timeConvert(time).rhours).format("00") + ":" +
        numeral(timeConvert(time).rminutes).format("00");
    return timeToDisplay;
}
const TimeProgress = ({ time, label = "", progress, className = "", timeColor = "", isVertical = true, progressHeight = 8, progressColor = 'var(--branding)' }:
    { time: number, label?: string, progress?: number, className?: string, timeColor?: string, isVertical?: boolean, progressHeight?: number, progressColor?: string }) => {
    // const timeToDisplay = numeral(Math.abs(time / 60).format("00") + ":"
    const timeStyle = { color: "" };
    if (timeColor) {
        timeStyle.color = timeColor;
    }

    if (isVertical) {
        return (
            <div className={`d-flex  ${className} flex-column`} style={{ textAlign: 'center' }} >
                {(time === 0 || time) &&
                    <div className='timeprogress-time ' style={timeStyle}>{getWorkTimeDisplay(time)}</div>
                }
                {label &&
                    <div className='timeprogress-label mb-1 text-center'>{label}</div>
                }
                {progress !== undefined &&
                    <div className="progress" style={{ height: progressHeight, minWidth: 45 }}>
                        <div className="progress-bar" role="progressbar" style={{ width: `${progress}%`, backgroundColor: progressColor }}
                            aria-valuenow={progress} aria-valuemin={0} aria-valuemax={100}></div>
                    </div>
                }
            </div>
        )
    } else {
        return (
            <div className={`${className} row`} >
                {(time === 0 || time) &&
                    <div className='timeprogress-time col-md-3' style={timeStyle}>{getWorkTimeDisplay(time)}</div>
                }
                <div className="mt-1 col-md-9">
                    <div className="progress" style={{ height: progressHeight + 2, backgroundColor: 'var( --light-gray)' }}>
                        <div className="progress-bar" role="progressbar" style={{ width: `${progress ? progress : 0}%`, backgroundColor: progressColor }}
                            aria-valuenow={progress} aria-valuemin={0} aria-valuemax={100}></div>
                    </div>
                </div>

                {label &&
                    <div className='timeprogress-label mb-1 text-center col-md-3'>{label}</div>
                }

            </div>
        )
    }

}

TimeProgress.propTypes = {
}

export default TimeProgress
