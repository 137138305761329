import { PermissionsList } from 'components/Common/constants';
import React, { lazy } from 'react';
import { Switch } from 'react-router';
import PrivateRoute from 'routes/PrivateRoute';
const UsersManagement = lazy(() =>
    import("apps/people/src/People/PeopleManagement")
);
const Teams = lazy(() =>
    import("apps/people/src/Team/TeamManagement")
);
const AccessRolesManagement = lazy(() =>
    import("apps/people/src/AccessRole/AccessRolesManagement")
);
export const PeopleRoutesList = [
    // "/organization",
    "/organization/user-management",
    "/organization/teams",
    "/organization/access-roles",
]
const waitFor = (Tag) => (props) => <Tag {...props} />;

const PeopleRoutes = () => {
    return (
        <>
            <Switch>
                <PrivateRoute
                    permissions={[PermissionsList.ORGANIZATION_USERMANAGEMENT_VIEW]}
                    exact
                    path="/organization/user-management"
                    component={waitFor(
                        UsersManagement
                    )}
                />
                <PrivateRoute
                    permissions={[PermissionsList.ORGANIZATION_USERMANAGEMENT_VIEW]}
                    exact
                    path="/organization/teams"
                    component={waitFor(
                        Teams
                    )}
                />
                <PrivateRoute
                    permissions={[PermissionsList.ORGANIZATION_USERMANAGEMENT_ROLE]}
                    exact
                    path="/organization/access-roles"
                    component={waitFor(
                        AccessRolesManagement
                    )}
                />
                {/* <Redirect from="/organization" to="/organization/user-management" /> */}
            </Switch>
        </>
    )
}

export default PeopleRoutes