import { createEntityAdapter, createSelector } from "@reduxjs/toolkit"
const roleAdapter = createEntityAdapter()

const selectSelf = (state: any) => state


export const selectAllAllowedRoles = createSelector(
    selectSelf,
    (state) => {
        const roles = roleAdapter.getSelectors((state: any) => state.roles).selectAll(state);
        if (state.user.info.roleName === "Administrator") {
            return roles;
        }
        return roles.filter((a: any) => a.name !== "Administrator");
    }
)

export const {
    selectById: selectRoleById,
    selectIds: selectRolesIds,
    selectEntities: selectRolesEntities,
    selectAll: selectAllRoles,
    selectTotal: selectTotalRoless
} = roleAdapter.getSelectors((state: any) => state.roles)

