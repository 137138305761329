import { useCallback, useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export function useDocumentHelper(pageTitle) {
    const { t } = useTranslation()
    var language = useSelector(state => state.user.language);


    if (!language) {
        language = "en";
    }
    var languageIsRightToLeft = false;
    if (language === 'fa') {
        languageIsRightToLeft = true;
    }
    const [productName, setProductName] = useState("");
    const [productSubTitleName, setProductSubTitleName] = useState("");
    const product = useSelector(state => state.product);
    useEffect(() => {
        if (!product || !product.name) {
            return;
        }
        setProductName(product.name[language]);
        setProductSubTitleName(product.subtitle[language]);
    }, [product, language])
    const setDocumentTitle = useCallback((title) => {
        let titles = [title];
        titles.push(productName);
        titles = titles.map(a => t(a));
        titles = titles.filter(a => a);
        const newTitle = document.title = titles.join(' . ');
        if (document.title === newTitle) return;

        document.title = titles.join(' . ')
    }, [productName, t])
    useEffect(() => {
        if (!pageTitle || !productName) return;
        setDocumentTitle(t(pageTitle));
    }, [language, productName, pageTitle, setDocumentTitle, t])
    return { productName, setDocumentTitle, productSubTitleName, language, languageIsRightToLeft };

}

