import React from 'react';
import { APPS } from "components/Common/constants"
import { ReactComponent as TimeAppIcon } from 'icons/app-icons/time-icon.svg'

export const useTimeAppMenu = () => {
    const getItems = () => {
        return [
            {
                title: APPS.Time.displayName,
                appRoute: APPS.Time.frontName,
                defaultRoute: APPS.Time.frontName + "/mytime",
                icon: <TimeAppIcon />
            },
        ]
    }
    return { getItems }
}