import React from 'react';
import { APPS } from "components/Common/constants"
import { ReactComponent as DashboardAppMenu } from 'icons/app-icons/dashboard-icon.svg'

export const useDashboardAppMenu = () => {
    const getItems = () => {
        return [
            {
                title: "Dashboard",
                appRoute: APPS.Dashboard.frontName,
                defaultRoute: APPS.Dashboard.frontName + "/",
                icon: <DashboardAppMenu />
            }
        ]
    }
    return { getItems }
}