import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'reactstrap';
import { useQueryOnMedia } from 'hooks/useQueryOnMediaHook';
import { GetStatusColor, InviteModel } from 'apps/people/models/InviteModel';
import { getInvites } from 'store/actions';
import { useAppDispatch } from 'store/hooks';
import { getDisplayDate } from 'utils/dateHelper';
import { paginate } from 'utils/paginate';
import Paginate from 'components/Common/Paginate';

const InvitationList = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [invites, setInvites] = useState<InviteModel[]>([])
    const [currentPage, setCurrentPage] = useState(1);
    const { isMobileOrTablet } = useQueryOnMedia();
    const pageSize = 5;
    useEffect(() => {
        (async () => {
            const invitesResponse = await dispatch<any>(getInvites());
            if (!invitesResponse.error) {
                setInvites(invitesResponse.payload.data);
            }
        })();
    }, [dispatch])
    function onPageChange(currentPage: any) {
        setCurrentPage(currentPage.selected + 1)
    };

    const invitesToShow = paginate(invites, currentPage, pageSize) as InviteModel[]
    return (
        <div>
            {invites.length > 0 ?
                <div>
                    <Table responsive bordered striped style={{ fontSize: 12 }}>
                        <thead>
                            <tr>
                                <th>{t('members.Email')}</th>
                                {!isMobileOrTablet && <th>{t('inviteDate')}</th>}
                                <th>{t('inviteStatus')}</th>
                                {!isMobileOrTablet && <th>{t('invitedByUser')}</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {invitesToShow.map(invitation => <tr key={invitation.id}>
                                <td>{invitation.email}

                                </td>
                                {!isMobileOrTablet &&
                                    <td>
                                        {getDisplayDate(invitation.createdAt)}
                                    </td>
                                }
                                <td className='text-capitalize' style={{ color: GetStatusColor(invitation.status) }}>
                                    {t(invitation.status)}
                                </td>
                                {!isMobileOrTablet &&
                                    <td>
                                        {invitation.invitedByUser}
                                    </td>
                                }
                            </tr>)}
                        </tbody>
                    </Table>
                    <Paginate
                        pageSize={pageSize}
                        itemsCount={invites.length}
                        onPageChange={onPageChange}
                        currentPage={currentPage} />
                </div>
                : <div className="center-container">
                    <div className="center-content empty-list-placeholder">
                        <div className="">
                            <img alt="" src='img/invite-user-empty-state.svg' className='thumb350'></img>
                            <div className='text-center mt-2'> {t("members.You_have_yet_to_invite_any_person_to_your_organization")}</div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

InvitationList.propTypes = {

}

export default InvitationList
