import jalaliMoment from 'moment-jalaali'
import moment from 'moment'
import { getMonthNameGregorian, getMonthNamePersian } from './getMonthName';

export const dateExtractorPersian = (date) => {
    return {
        year: () => {
            return jalaliMoment(date).jYear()
        },
        season: () => {
            return Math.ceil((jalaliMoment(date).jMonth() + 1) / 3)
        },
        month: () => {
            return jalaliMoment(date).jMonth() + 1
        },
        add: (val, unit) => {
            if (unit === 'year') {
                return jalaliMoment(date).add(val, 'jYear');
            } else if (unit === 'month') {
                return jalaliMoment(date).add(val, 'jMonth');
            }
            return jalaliMoment(date).add(val, unit);

        },
        dayOfMonth: () => {
            return parseInt(jalaliMoment(date).format('jD'))
        },
        monthName: () => {
            return getMonthNamePersian(date);

        },
        formattedDay: () => {
            return jalaliMoment(date).format('jDD');
        },
        date: () => {
            return date;
        },
        momentDate: () => {
            return jalaliMoment(date);
        },
        toISOString: () => {
            return moment(date).toISOString();
        },
        formattedDate: () => {
            // if (jalaliMoment.isMoment(date)) {
            //     return date.format('jYYYY-jMM-jDD');
            // } else {
            return jalaliMoment(date).format('jYYYY-jMM-jDD');
            // }
        },
        gregorianFormattedDate: () => {
            return jalaliMoment(date).format('YYYY-MM-DD');
        }
    }
};

export const dateExtractorGregorian = (date) => {
    return {
        year: () => {
            return moment(date).year()
        },
        season: () => {
            return moment(date).quarter();
        },
        month: () => {
            return moment(date).month() + 1
        },
        dayOfMonth: () => {
            return parseInt(jalaliMoment(date).format('D'))
        },
        monthName: () => {
            return getMonthNameGregorian(date);
        },
        add: (val, unit) => {
            return moment(date).add(val, unit)
        },
        formattedDay: () => {
            return moment(date).format('DD');
        },
        date: () => {
            return date;
        },
        momentDate: () => {
            return moment(date).locale("en");
        },
        toISOString: () => {
            return moment(date).toISOString();
        },
        formattedDate: () => {
            return moment(date).format('YYYY-MM-DD');
        },
        gregorianFormattedDate: () => {
            return moment(date).format('YYYY-MM-DD');
        }
    }
};