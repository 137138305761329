import { APPS, PermissionsList } from "components/Common/constants";
import { ReactComponent as BillingIcon } from 'icons/menu-icons/billing-icon.svg';
import { ReactComponent as SettingsIcon } from 'icons/menu-icons/settings-icon.svg';
import { ReactComponent as BillingHistoryIcon } from 'icons/billing-history-icon.svg';
import React from 'react';

export const useOrganizationMenu = () => {
    const getItems = () => {
        const menuItems = [
            {
                name: 'Billing Header',
                permissions: [PermissionsList.ORGANIZATION_SUBSCRIPTION_VIEW],
                heading: "Billing",
                translate: "sidebar.Billing",
                customCssClass: 'menu-saved-views-header',
                appName: APPS.Organization.frontName
            },
            {
                name: 'Billing',
                appName: APPS.Organization.frontName,
                permissions: [PermissionsList.ORGANIZATION_SUBSCRIPTION_VIEW],
                icon: <BillingIcon />,
                translate: 'sidebar.Subscriptions',
                path: '/organization/subscription',

            },
            {
                name: 'BillingHistory',
                appName: APPS.Organization.frontName,
                permissions: [PermissionsList.ORGANIZATION_SUBSCRIPTION_VIEW],
                icon: <BillingHistoryIcon />,
                translate: 'sidebar.BillingHistory',
                path: '/organization/billing-history',

            },
            {
                name: 'Organization',
                permissions: [PermissionsList.ORGANIZATION_SETTING_UPADTE],
                heading: "Organization",
                translate: "sidebar.Organization",
                customCssClass: 'menu-saved-views-header',
                appName: APPS.Organization.frontName
            },
            {
                name: 'Settings',
                appName: APPS.Organization.frontName,
                permissions: [PermissionsList.ORGANIZATION_SETTING_UPADTE],
                icon: <SettingsIcon />,
                translate: 'sidebar.Settings',
                path: '/organization/settings',

            }
        ];

        return menuItems;
    }
    return { getItems }
}