import React, { useEffect, useState } from 'react';

import Header from './Header'
import Sidebar from './Sidebar'
import Toastify from 'components/Common/Toastify';
import { Suspense } from 'react';
import PageLoader from 'components/Common/PageLoader';
import { useHistory } from 'react-router';
import { useAppSelector } from 'store/store';
import { isAuthenticate } from 'store/selectors';

const Base = (props: { children: any, hideSidebar: boolean }) => {
    const isAuth = useAppSelector(isAuthenticate);
    const loading = useAppSelector(state => state.user.info.loading);
    const history = useHistory();
    useEffect(() => {
        // if (!loading && !isAuth) {
        //     history.push("/login?returnUrl=" + history.location.pathname + encodeURIComponent(history.location.search));
        // }
    }, [history, isAuth, loading])
    if (loading || !isAuth) {
        return <PageLoader></PageLoader>
    }
    return (
        <div className="wrapper scroll-hidden">

            <Toastify />
            <Suspense fallback={<PageLoader></PageLoader>}>
                <Header />
                <div className={'flex-grow-1 flex-row d-flex scroll-hidden'}>
                    {!props.hideSidebar && <Sidebar />}
                    <section className="section-container flex-grow-1">
                        {/* <div className="sidebar-backdrop sidebar-backdrop-fade show">
                        </div> */}
                        <SidebarBackDrop />
                        {props.children}
                    </section>
                </div>


            </Suspense>



            {/* <Footer /> */}
        </div>
    )

}

export default Base;

const SidebarBackDrop = () => {
    const asideToggled = useAppSelector((state: any) => state.settings.asideToggled);
    const [displayBackdrop, setDisplayBackdrop] = useState<boolean>(asideToggled);

    useEffect(() => {
        let timeOutId: any = null;
        if (asideToggled) {
            setDisplayBackdrop(true);
        } else {
            timeOutId = setTimeout(() => {
                setDisplayBackdrop(false);
            }, 300);
        }
        return () => { if (timeOutId) clearTimeout(timeOutId) };
    }, [asideToggled])
    return (<div
        className={`sidebar-backdrop  ${asideToggled ? 'show' : 'sidebar-backdrop-fade'}`}
        style={{ display: displayBackdrop ? 'block' : 'none' }}>
    </div>)
}

export const BaseWithoutSidebar = (props: { children: any }) => {
    return <Base hideSidebar={true} children={props.children} />
}