import React from 'react';
import { APPS } from "components/Common/constants"
import { ReactComponent as OKRIcon } from 'icons/app-icons/okr-icon.svg'

export const useGoalAppMenu = () => {
    const getItems = () => {
        return [
            {
                title: "Goals",
                appRoute: APPS.OKR.frontName,
                defaultRoute: APPS.OKR.frontName + "/list/mine",
                icon: <OKRIcon />
            }
        ]
    }
    return { getItems }
}