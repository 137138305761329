import { PermissionsList } from 'components/Common/constants';
import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import PrivateRoute from 'routes/PrivateRoute';
import { isSystemModePublic } from 'store/selectors';
const Settings = lazy(() => import("../src/Settings/Settings"));
// const Subscription = lazy(() =>
//     import("../src/Subscription/Subscription")
// );
const SubscriptionManager = lazy(() =>
    import("../src/Subscription/Billing/ManageSubscription")
);
const BillingHistory = lazy(() =>
    import("../src/Subscription/Billing/BillingHistory")
);
const Upgrade = lazy(() =>
    import("../src/Subscription/Upgrade")
);
const NewOrganization = lazy(() =>
    import("../src/NewOrganization")
);
const OrganizationNotFound = lazy(() =>
    import("../src/OrganizationNotFound")
);
const OrganizationAccessDenied = lazy(() =>
    import("../src/OrganizationAccessDenied")
);
const ChangeOrganization = lazy(() =>
    import("../src/ChangeOrganization")
);
const InvoiceDetail = lazy(() =>
    import("../src/Subscription/InvoiceDetail")
);
// const BillingPreview = lazy(() =>
//     import("../src/Subscription/Billing/BillingPreview")
// );
export const OrganizationRoutesList = [
    "/organization",
    "/organization/settings",
    "/organization/create/:status?",
    "/organization/upgrade",
    "/organization/subscription/:tab?",
    "/organization/billing-history",
    "/organization/subscription/:setup_intent_client_secret?",
    // "/organization/billing-preview",
]
const waitFor = (Tag) => (props) => <Tag {...props} />;

const OrganizationRoutes = () => {


    return (
        <>
            <Switch>
                <PrivateRoute
                    permissions={[PermissionsList.ORGANIZATION_SETTING_UPADTE]}
                    exact
                    path="/organization/settings"
                    component={waitFor(Settings)}
                />
                <PrivateRoute
                    permissions={[PermissionsList.ORGANIZATION_SUBSCRIPTION_VIEW]}
                    exact
                    path="/organization/upgrade"
                    component={waitFor(Upgrade)}
                />
                <PrivateRoute
                    permissions={[PermissionsList.ORGANIZATION_SUBSCRIPTION_VIEW]}
                    exact
                    path="/organization/subscription/:tab?"
                    component={waitFor(SubscriptionManager)}
                />
                <PrivateRoute
                    permissions={[PermissionsList.ORGANIZATION_SUBSCRIPTION_VIEW]}
                    exact
                    path="/organization/billing-history"
                    component={waitFor(BillingHistory)}
                />
                <PrivateRoute
                    permissions={[PermissionsList.ORGANIZATION_SUBSCRIPTION_VIEW]}
                    exact
                    path="/organization/subscription/:setup_intent_client_secret?"
                    component={waitFor(SubscriptionManager)}
                />
                <PrivateRoute
                    permissions={[PermissionsList.ORGANIZATION_SUBSCRIPTION_VIEW]}
                    exact
                    path="/organization/subscription/invoicedetail/:invoiceId"
                    component={waitFor(InvoiceDetail)}
                />
                <PrivateRoute
                    exact
                    path="/organization/create/:status?"
                    component={waitFor(NewOrganization)}
                />

                {/* <PrivateRoute
                    exact
                    path="/organization/billing-preview"
                    component={waitFor(BillingPreview)}
                /> */}
                <Redirect from="/organization" to="/organization/settings" exact />
            </Switch>
        </>
    )
}

export default OrganizationRoutes

export const OrganizationRoutesWithoutLayoutList = [
    "/organization/subscription/invoicedetail/:invoiceId",
    "/organization-not-found",
    "/organization-access-denied"
]

export const OrganizationRoutesWithoutLayout = () => {
    return (
        <>
            <Switch>
                <PrivateRoute
                    permissions={[PermissionsList.ORGANIZATION_SUBSCRIPTION_VIEW]}
                    exact
                    path="/organization/subscription/invoicedetail/:invoiceId"
                    component={waitFor(InvoiceDetail)}
                />
                <Route
                    exact
                    path="/organization-not-found"
                    component={waitFor(OrganizationNotFound)}
                />
                <Route
                    exact
                    path="/organization-access-denied"
                    component={waitFor(OrganizationAccessDenied)}
                />
            </Switch>
        </>
    )
}

export const OrganizationRoutesWithoutSidebarList = [
    "/organization/select"
]

export const OrganizationRoutesWithoutSidebar = () => {
    const isSystemPublic = useSelector(isSystemModePublic);
    return (
        <>
            <Switch>
                {isSystemPublic &&
                    <PrivateRoute
                        exact
                        path="/organization/select"
                        component={waitFor(ChangeOrganization)}
                    />
                }
            </Switch>
        </>
    )
}