import { PermissionsList } from 'components/Common/constants';
import React, { lazy } from 'react';
import { Redirect, Switch } from 'react-router';
import PrivateRoute from 'routes/PrivateRoute';
const OKR = lazy(() => import("apps/goals/src/OKR"));
export const GoalsRoutesList = [
    "/okr",
    "/goals",
    "/goals/list/:savedview?",
    "/goals/upgrade",
]
const Upgrade = lazy(() =>
    import("apps/organization/src/Subscription/Upgrade")
);
const waitFor = (Tag) => (props) => <Tag {...props} />;

const GoalsRoutes = () => {
    return (
        <>
            <Switch>
                <PrivateRoute
                    exact
                    path="/goals/list/:savedview"
                    component={waitFor(OKR)}
                />
                <PrivateRoute
                    permissions={[PermissionsList.ORGANIZATION_SUBSCRIPTION_VIEW]}
                    exact
                    path="/goals/upgrade"
                    component={waitFor(Upgrade)}
                />
                <Redirect from="/goals" to="/goals/list/mine" />
                <Redirect path="/goals/list" to="/goals/list/all" />
                <Redirect path="/okr" to="/goals" />
            </Switch>
        </>
    )
}

export default GoalsRoutes