import { APPS } from "components/Common/constants"

export const useSignupLoginMenu = () => {
    const getItems = () => {
        const menuItems = [
            {
                name: 'Initiate Organization',
                appName: APPS.Init.frontName,
                path: '/init',
            },
        ];

        return menuItems;
    }
    return { getItems }
}