import React from 'react'
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
const Paginate = ({ itemsCount, onPageChange, currentPage, pageSize = 10 }) => {
    const { t } = useTranslation();
    const pageCount = Math.ceil(itemsCount / pageSize);
    if (pageCount <= 1) {
        return <></>;
    }
    return (
        <div className="">
            <ReactPaginate
                pageClassName={'page-item'}
                pageLinkClassName={'page-link  mx-1 rounded wd-xxxs text-center text-bold'}
                previousLinkClassName={'page-link   mx-1 rounded wd-xxxs text-center text-bold'}
                nextLinkClassName={'page-link mx-1 rounded wd-xxxs text-center text-bold'}
                previousLabel={t('paginate.prev')}
                nextLabel={t('paginate.next')}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}

                onPageChange={onPageChange}
                containerClassName={'pagination pagination-sm d-flex justify-content-center'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={currentPage - 1}
            />
        </div>
    );
}
export default Paginate;
