/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as PlusIcon } from 'icons/plus-icon.svg';
import { ReactComponent as CloseIcon } from 'icons/close-icon.svg';

import {
    Button,
    Modal, ModalBody, ModalHeader, Row
} from "reactstrap";

export const NewItemInModal = ({
    className,
    children,
    newButtonText,
    newButtonComponent = null,
    modalTitle = '',
    modalSize = "md",
    defaultIsOpen = false,
    modal = false,
    setModal,
    newButtonFontSize = "inherited"
}) => {
    const toggle = () => setModal(!modal);
    const { t } = useTranslation();

    useEffect(() => {
        if (defaultIsOpen) {
            setModal(true)
        }
    }, [defaultIsOpen, setModal])

    return (
        <>
            <div onClick={toggle}>
                {!newButtonComponent &&
                    <Button
                        color="primary"
                        id="newItem"

                        size="sm"
                        className="px-4"
                        style={{ maxHeight: 35, fontSize: newButtonFontSize }}
                    >
                        <PlusIcon />
                        {/* <i className="fas fa-plus m-end-1"></i> */}
                        <span className="d-none d-md-unset" >{t(newButtonText)}</span>
                    </Button>
                }
                {!!newButtonComponent && newButtonComponent}
            </div>
            <Row form>
                <Modal
                    isOpen={modal}
                    toggle={toggle}
                    className={className}
                    size={modalSize}>
                    <ModalHeader className="modal-title text-primary">
                        <div onClick={() => toggle()} >
                            <CloseIcon className="modal-close-button" />
                        </div>
                        {t(modalTitle !== '' ? modalTitle : newButtonText)}
                    </ModalHeader>
                    <ModalBody className="no-padding">{children}</ModalBody>
                </Modal>
            </Row>
        </>
    );
};
