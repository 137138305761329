import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

export function useQueryOnMedia() {
    const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const handleViewModeInMobile = (matches) => {
        setIsMobile(matches);
        setIsMobileOrTablet(matches);
    };

    const mobileOrTablet = useMediaQuery({ maxWidth: 1224 }, undefined, handleViewModeInMobile)
    const mobile = useMediaQuery({ maxWidth: 576 }, undefined, handleViewModeInMobile)

    useEffect(() => {
        setIsMobileOrTablet(mobileOrTablet);
        setIsMobile(mobile);
    }, [mobileOrTablet, mobile])
    return { isMobileOrTablet, isMobile };
}