export const getSeasonNameGregorian = (season) => {
    const seasons = [
        { index: 1, name: 'Quarter 1' },
        { index: 2, name: 'Quarter 2' },
        { index: 3, name: 'Quarter 3' },
        { index: 4, name: 'Quarter 4' },
    ];
    return seasons.find(a => a.index === season).name;
}

export const getSeasonNamePersian = (season) => {
    const seasons = [
        { index: 1, name: 'بهار' },
        { index: 2, name: 'تابستان' },
        { index: 3, name: 'پاییز' },
        { index: 4, name: 'زمستان' },
    ];
    return seasons.find(a => a.index === season).name;
}
