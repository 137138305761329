import React from 'react';
import { useSelector } from 'react-redux';
import { getScreenshotEnabled } from 'store/selectors';
import { CheckBox } from "./CheckBox";

export const ProjectAccessSettingActivityTracking = ({ projectConfigToShow, handleChange }) => {
    const isScreenshotEnabled = useSelector(getScreenshotEnabled);
    return (
        <div>
            <CheckBox
                checked={projectConfigToShow.enableActivityTracking}
                handleChange={handleChange}
                label={'Enable Activity Tracking'}
                fieldName="enableActivityTracking" />

            <div className="m-start-3">
                <CheckBox
                    disabled={!projectConfigToShow.enableActivityTracking}
                    checked={projectConfigToShow.activeWindow}
                    handleChange={handleChange}
                    label={'Active window'}
                    fieldName="activeWindow" />
                <CheckBox
                    disabled={!projectConfigToShow.enableActivityTracking}
                    checked={projectConfigToShow.keyboardAndMouse}
                    handleChange={handleChange}
                    label={'Keyboard & Mouse'}
                    fieldName="keyboardAndMouse" />
                {isScreenshotEnabled &&
                    <>
                        <CheckBox
                            disabled={!projectConfigToShow.enableActivityTracking}
                            checked={projectConfigToShow.captureScreenshot}
                            handleChange={handleChange}
                            label={'Screen (via capturing screenshots)'}
                            fieldName="captureScreenshot" />

                        <div className="m-start-3">
                            <CheckBox
                                disabled={!projectConfigToShow.enableActivityTracking || !projectConfigToShow.captureScreenshot}
                                checked={projectConfigToShow.userCanDisableScreenshot}
                                handleChange={handleChange}
                                label={'User can disable screenshots'}
                                fieldName="userCanDisableScreenshot" />

                            <CheckBox
                                disabled={!projectConfigToShow.enableActivityTracking || !projectConfigToShow.captureScreenshot}
                                checked={projectConfigToShow.userCanRemoveScreenshot}
                                handleChange={handleChange}
                                label={'User can remove screenshot'}
                                fieldName="userCanRemoveScreenshot" />
                        </div>
                    </>
                }


            </div>
        </div>
    );
};
