/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button, Form, FormGroup,
    Label, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem,
    NavLink, TabPane
} from 'reactstrap';
import { loadMeProjects } from 'store/actions';
import { getUserPermissions, saveProjectUserPermissions, selectMemberById, selectProjectById } from 'store/selectors';
import showToast from 'utils/toaster';
import { ProjectAccessSettingActivityTracking } from './ProjectAccessSettingActivityTracking';
import { ProjectAccessSettingWorkingHours } from './ProjectAccessSettingWorkingHours';

const ProjectAccessSetting = ({ className = '', modalIsOpen, toggle, projectId, userId }) => {

    const [overwritePermissions, setOverwritePermissions] = useState(false)
    const [projectConfigToShow, setProjectConfigToShow] = useState({})
    const user = useSelector(state => selectMemberById(state, userId));
    const project = useSelector(state => selectProjectById(state, projectId));
    const dispatch = useDispatch();
    useEffect(() => {
        (async () => {
            if (userId && projectId && modalIsOpen) {
                const permissions = await dispatch(getUserPermissions({ projectId: projectId, userId: userId }));
                if (permissions.payload && permissions.payload.permissions) {
                    setProjectConfigToShow(permissions.payload.permissions);
                    setOverwritePermissions(permissions.payload.overwrite_permissions)
                } else {
                    setProjectConfigToShow({});
                }

            }
        })();


    }, [projectId, userId, modalIsOpen, dispatch])

    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState("workingHours");

    const changeTab = newTab => {
        if (!overwritePermissions) return;
        if (activeTab !== newTab) {
            setActiveTab(newTab);
        };
    }

    function toggleProp(e) {
        var newConfig = { ...projectConfigToShow };
        newConfig[e.currentTarget.id] = !newConfig[e.currentTarget.id];
        setProjectConfigToShow(newConfig)
    }

    function handleChange(e) {
        var newConfig = { ...projectConfigToShow };
        if (e.currentTarget.type === 'checkbox') {
            newConfig[e.currentTarget.id] = !newConfig[e.currentTarget.id];
        } else if (e.target.type === 'radio') {
            newConfig[e.currentTarget.id] = e.currentTarget.value === "true" || e.currentTarget.value === "false" ? e.currentTarget.value === "true" : e.currentTarget.value;
        } else {
            newConfig[e.currentTarget.id] = e.currentTarget.value;
        }
        setProjectConfigToShow(newConfig)

    }

    async function handleSave(e) {
        e.preventDefault();
        await dispatch(saveProjectUserPermissions({ projectId: projectId, userId: userId, data: { ...projectConfigToShow, overwrite_permissions: overwritePermissions } }));
        showToast("Project access settings updated successfully");
        dispatch(loadMeProjects());
        toggle();

    }

    if (!modalIsOpen) {
        return (<></>)
    }
    return (
        <>


            <Modal isOpen={modalIsOpen} toggle={toggle} className={className} size={'lg'}>
                <Form onSubmit={handleSave}>
                    <ModalHeader>
                        {t('Project Access Setting')}
                        {` (${project.name} - ${user.name})`}
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <FormGroup check className="mb-2">
                                <input type="radio"
                                    id="notOverwritePermissions"
                                    value={false}
                                    checked={!overwritePermissions}
                                    onChange={e => setOverwritePermissions(e.currentTarget.value === "true")} />{' '}
                                <Label className='m-start-1' onClick={() => setOverwritePermissions(false)}>
                                    <span> {t('Inherit from project setting')}</span>
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <input type="radio"
                                    id="overwritePermissions"
                                    value={false}
                                    checked={overwritePermissions}
                                    onChange={e => setOverwritePermissions(e.currentTarget.value !== "true")} />

                                <Label className='m-start-1' onClick={() => setOverwritePermissions(true)}>
                                    <span> {t('Overwrite Permissions')}</span>
                                </Label>
                            </FormGroup>
                        </div>
                        <div>
                            {overwritePermissions ?
                                <fieldset className="p-4">
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === 'workingHours' })}
                                                onClick={() => { changeTab('workingHours'); }}>
                                                <Trans i18nKey="Working Hours"></Trans>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === 'activityTracking' })}
                                                onClick={() => { changeTab('activityTracking'); }}>
                                                <Trans i18nKey="Activity Tracking"></Trans>

                                            </NavLink>
                                        </NavItem>
                                    </Nav>

                                    <TabPane tabId="workingHours" className="pt-3">
                                        {activeTab === 'workingHours' &&
                                            <ProjectAccessSettingWorkingHours handleChange={handleChange} toggleProp={toggleProp} projectConfigToShow={projectConfigToShow} />
                                        }
                                    </TabPane>
                                    <TabPane tabId="activityTracking">
                                        {activeTab === 'activityTracking' &&
                                            <ProjectAccessSettingActivityTracking handleChange={toggleProp} projectConfigToShow={projectConfigToShow} />
                                        }
                                    </TabPane>
                                </fieldset>
                                : <></>
                            }
                        </div>
                    </ModalBody>
                    <ModalFooter className="action-box">
                        <Button color="secondary" onClick={toggle}>
                            {t('cancel')}
                        </Button>
                        <Button color="primary" type="submit" value={t("Update Settings")} id="saveInModal">{t("Update Settings")}</Button>

                    </ModalFooter>
                </Form>
            </Modal>


        </>
    );
}

export default ProjectAccessSetting



