import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { CardSubtitle, CardTitle, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { memberIsActive } from 'store/selectors'
import { useAppSelector } from 'store/store'
import { PermissionsList } from 'components/Common/constants'
import PermissionComponent from 'components/Common/PermissionComponent'
import SinceDate from 'components/Common/SinceDate'
import TimeProgress from 'components/Common/TimeProgress'
import ProjectAccessSetting from 'apps/time/src/ProjectAccessSetting/ProjectAccessSetting'
import HourlyRateUpdate from '../../Projects/HourlyRateUpdate'
import MemberBillRate from '../MemberBillRate'
import MemberLogo from '../MemberLogo'
import MemberDisabledBadge from './MemberDisabledBadge'
import MemberRoleBadge from './MemberRoleBadge'

const MemberRowBody = ({ member, memberActionHandlers, showMetaData, showMenu, showtimes, showrate, linkToMemberPage, titleClassName = "col-md-6", logoSize = undefined }:
    { member: any, memberActionHandlers: any, showMenu: boolean, showMetaData: boolean, showtimes: boolean, showrate: boolean, linkToMemberPage: boolean, titleClassName: string, logoSize: number | undefined }) => {
    const memberToShow = { ...member }
    const organization = useAppSelector(state => state.organization.currentOrganization.entity);
    function CreatedAtDisplayDate() {
        return (
            <SinceDate date={member.createdAt}></SinceDate>
        )
    }

    const opacityWithMemberStatus = !memberIsActive(memberToShow) && showMetaData ? "disabled" : "";
    const { t } = useTranslation();
    const history = useHistory();
    const memberBillRate = member.contract ? member.contract.rate : member._pivot_billingRate;
    const isOverWritten = member?.contract?.isOverwritten;
    return (
        <div className="row">
            <div className={`col ${showMenu ? 'col-md-auto' : 'col-md-auto'} flex-grow-1 flex-row`}>
                <div className={"member-row " + (showMetaData ? " row " : " p-start-2 ") + opacityWithMemberStatus}>
                    <div className={showMetaData ? "col-md-12  align-items-center" : "row"}>
                        <div className={showMetaData ? '' : 'col col-md-auto  d-flex flex-row align-items-center p-0'}>
                            <div className={(showMetaData ? 'pt-15' : 'd-i d-flex justify-content-between') + (showMenu ? " d-flex justify-content-between" : " text-align-center")}>
                                {showMetaData &&
                                    <div>
                                        &nbsp;
                                    </div>
                                }
                                <div className={showMenu ? 'd-i' : ''}>
                                    <MemberLogo thumbSize={logoSize} logo={memberToShow.photo} name={memberToShow.name} status={memberToShow.status} />
                                </div>
                                <PermissionComponent permissions={[PermissionsList.TIME_PROJECT_UPDATE]} projectId={member.projectId}>
                                    {showMenu &&
                                        <MemberHumbergerMenu showrate={showrate} member={memberToShow} memberActionHandlers={memberActionHandlers} showMenu={showMenu}></MemberHumbergerMenu>
                                    }
                                </PermissionComponent>
                            </div>
                        </div>
                        <div className={'col member-row-name ' + (showMetaData ? ' pt-3 align-items-center col-md-12 pt-15 text-align-center' : 'd-i')}>
                            <CardTitle tag="h3" className={`flex-column ${!showMetaData ? 'p-t-13' : ''}`}>
                                <div className="list-item-title">
                                    {memberToShow.name}
                                    <MemberDisabledBadge disabled={!memberIsActive(member)} />
                                </div>

                            </CardTitle>

                            {showtimes &&
                                <>
                                    <CardSubtitle tag="h4" className=" text-muted mt-1 ">
                                        <MemberRoleBadge roleId={member._pivot_roleId} projectRole={member.projectRole} className="p-0" />
                                    </CardSubtitle>
                                    <CardSubtitle tag="h6" className=" text-muted mt-1">
                                        <CreatedAtDisplayDate></CreatedAtDisplayDate>
                                    </CardSubtitle>

                                </>
                            }

                        </div>

                        <div className="member-row-statistics text-align-center d-flex d-row pt-3">
                            {(showrate && memberBillRate) &&
                                <MemberBillRate rate={`${memberBillRate ? organization.currency + ' ' + memberBillRate : '-'}`}
                                    isOverWritten={isOverWritten}
                                    label={t('members.HourlyRate')}
                                    className="mx-1 p-1 align-items-center"></MemberBillRate>
                            }
                            {showtimes &&
                                <>
                                    <div className={`d-flex d-row justify-content-center ${showrate && memberBillRate ? 'col-8' : 'col-12'}`}>
                                        <div className={`${linkToMemberPage ? 'clickable nested-link background-hover underline-hover' : ''}`} onClick={() => linkToMemberPage && history.push(`/people/${member.id}/workdiary`)}>
                                            <TimeProgress time={member.workLog.today}
                                                label={t('Today')}
                                                progress={member.workLog.todayPercent || 0}
                                                className="mx-1   p-2-1 rounded align-items-center" />
                                        </div>
                                        <TimeProgress time={member.workLog.thisWeek}
                                            label={t('ThisWeek')} progress={member.workLog.thisWeekPercent || 0}
                                            className="mx-1 p-1 align-items-center"></TimeProgress>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MemberRowBody


const MemberHumbergerMenu = ({ member, memberActionHandlers, showrate }: { member: any, showMenu: boolean, memberActionHandlers: any, showrate: boolean }) => {
    const { t } = useTranslation();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const [projectAccessSettingModal, setProjectAccessSettingModal] = useState(false);
    const [hourlyRateUpdateModal, setHourlyRateUpdateModal] = useState(false);
    function removeMemberFromProject() {
        memberActionHandlers.onRemove(member.id)
    }
    function pauseMemberFromProjectTeam() {
        memberActionHandlers.onPause(member.id)
    }
    function resumeMemberFromProjectTeam() {
        memberActionHandlers.onResume(member.id)
    }
    function makeUserAdminInProjectTeam() {
        memberActionHandlers.onConvertToAdmin(member.id)
    }
    function makeUserMemberInProjectTeam() {
        memberActionHandlers.onConvertToMember(member.id)
    }
    function openProjectAccessSettingModal() {
        setProjectAccessSettingModal(true);
    }
    function openHourlyRateUpdateModal() {
        setHourlyRateUpdateModal(true);
    }
    return (
        <>
            <Dropdown className="nested-link f-r" isOpen={dropdownOpen} toggle={toggle} tag="span">
                <DropdownToggle tag="span"
                    data-toggle="dropdown"
                    aria-expanded={dropdownOpen}
                    style={{ cursor: "pointer", fontSize: "17px" }}
                    className="btn-hover-light-primary px-1 py-1 rounded "
                >
                    <i className="fa fa-ellipsis-v" ></i>
                </DropdownToggle>
                <DropdownMenu style={{ opacity: "1 !important" }}>
                    <DropdownItem onClick={openProjectAccessSettingModal}>
                        <span className="fa-stack">
                            <i className="fas fa-cog m-end-2"></i>
                        </span>
                        {t('Project Access Setting')}
                    </DropdownItem>
                    {showrate && <DropdownItem onClick={openHourlyRateUpdateModal}>
                        <span className="fa-stack">
                            <i className="fas fa-cog m-end-2"></i>
                        </span>
                        {t('Update Hourly Rate')}
                    </DropdownItem>
                    }
                    <DropdownItem divider />

                    {member.projectRole === 'project admin' ?
                        <DropdownItem onClick={makeUserMemberInProjectTeam}>
                            <span className="fa-stack">
                                <i className="fas fa-times fa-stack-1x" style={{ marginTop: -10 }}></i>
                                <i className="fas fa-shield-alt "></i>
                            </span>
                            {t('Remove admin access to project')}
                        </DropdownItem>
                        : <DropdownItem onClick={makeUserAdminInProjectTeam}>
                            <span>
                                <i className="fas fa-shield-alt m-end-2"></i>
                            </span>
                            {t('Grant admin access to project')}
                        </DropdownItem>
                    }
                    {member.status === 'active' ?
                        <DropdownItem onClick={pauseMemberFromProjectTeam}>
                            <span className="fa-stack">
                                <i className="fa fa-pause m-end-2"></i>
                            </span>
                            {t('Pause access to project')}
                        </DropdownItem>
                        : <DropdownItem onClick={resumeMemberFromProjectTeam}>
                            <span className="fa-stack">
                                <i className="fas fa-lock-open m-end-2"></i>
                            </span>
                            {t('Resume access to project')}
                        </DropdownItem>
                    }

                    <DropdownItem onClick={removeMemberFromProject} style={{ color: 'red' }}>
                        <span className="fa-stack">
                            <i className="fas fa-trash m-end-2"></i>
                        </span>
                        {t('Remove from project')}
                    </DropdownItem>
                </DropdownMenu>

            </Dropdown>
            <ProjectAccessSetting
                modalIsOpen={projectAccessSettingModal}
                userId={member.id}
                projectId={member.projectId}
                toggle={() => setProjectAccessSettingModal(!projectAccessSettingModal)}
            />
            <HourlyRateUpdate
                modalIsOpen={hourlyRateUpdateModal}
                contract={member.contract}
                userId={member.id}
                projectId={member.projectId}
                toggle={() => setHourlyRateUpdateModal(!hourlyRateUpdateModal)}
                onHourlyRateUpdated={memberActionHandlers.onHourlyRateUpdated}
            />
        </>
    )
}