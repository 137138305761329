import { APPS, PermissionsList } from 'components/Common/constants';
import React, { lazy } from 'react';
import { Redirect, Switch } from 'react-router';
import PrivateRoute from 'routes/PrivateRoute';

const Projects = lazy(() => import("apps/time/src/Projects/Projects"));
const ProjectForm = lazy(() =>
    import("apps/time/src/Projects/ProjectForm/ProjectForm")
);

const frontName = APPS.Projects.frontName;
export const ProjectsRoutesList = [
    `/${frontName}/:id/:tab?`,
    `/${frontName}`,
]
const waitFor = (Tag) => (props) => <Tag {...props} />;

const ProjectsRoutes = () => {
    return (
        <>
            <Switch>
                <Redirect
                    exact
                    path={`/${frontName}/:id`}
                    to={`/${frontName}/:id/overview`}
                />
                <PrivateRoute
                    permissions={[PermissionsList.TIME_PROJECT_VIEW, PermissionsList.TIME_PROJECT_VIEWALL]}
                    exact
                    path={`/${frontName}/:id/:tab?`}
                    component={waitFor(ProjectForm)}
                />
                <PrivateRoute
                    permissions={[PermissionsList.TIME_PROJECT_VIEW, PermissionsList.TIME_PROJECT_VIEWALL]}
                    exact
                    path={`/${frontName}`}
                    component={waitFor(Projects)}
                />


            </Switch>
        </>
    )
}

export default ProjectsRoutes
