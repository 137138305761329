import { APPS } from "components/Common/constants";

import {
    getAllOKRSavedView, loadAllTeams, loadCurrentOrganization, loadCurrentUser, loadMeInvites, loadMembers, loadMeOrganizations, loadMeProjects,
    loadMessageStatistic, loadProjects, loadRoles, resetMembers, resetOrganization, resetProjects, resetReports,
    resetUser
} from "store/actions";
import store from 'store/store';
import { getAppsByPermissions } from "utils/useAppHelper";

export const loadInitData = (dispatch) => {


    dispatch(loadCurrentOrganization()).then(() => {

        dispatch(loadCurrentUser()).then(() => {
            dispatch(loadMeOrganizations())
            dispatch(loadMeInvites());
            dispatch(loadMessageStatistic());


            dispatch(loadMembers());
            dispatch(loadAllTeams());
            dispatch(loadRoles());

            dispatch(loadProjects());
            dispatch(loadMeProjects());

            const userApps = getAppsByPermissions(store.getState().user.info.permissions);
            if (userApps.some(a => a.serverName === APPS.OKR.serverName)) {
                dispatch(getAllOKRSavedView());
            }

        })










    });


}

export const removeInitData = async (dispatch) => {
    await dispatch(resetProjects());
    await dispatch(resetMembers());
    await dispatch(resetReports());
    await dispatch(resetOrganization());
    await dispatch(resetUser());
}

