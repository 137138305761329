import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Label } from "reactstrap";
import { selectAllTeams } from "store/selectors";
import SelectComponent from "./SelectComponent";

const TeamSelector = ({ selected, setSelected, isMulti, label, teamsQuery = (a) => true, className = "", labelClassName = "" }) => {
    const { t } = useTranslation();

    function getAllteams(state) {
        const allteams = selectAllTeams(state).filter(teamsQuery).map((a) => ({ value: a.id, label: a.name }));
        return allteams;
    }
    let allteams = useSelector(getAllteams);
    const handleChange = useCallback(selected => {
        setSelected(selected);
    }, [setSelected]);

    const selectedOption = allteams.find(a => !isMulti ? selected === a.value : selected.some(b => b === a.value));
    return <>
        {allteams.length > 0 &&
            <div id="">
                <div className={className}>
                    <Label className={labelClassName}>{t(label)}</Label>
                    <SelectComponent
                        isClearable={true}
                        options={allteams}
                        onChange={handleChange}
                        selectedOption={selectedOption} />
                </div>
            </div>
        }
    </>
}

export default TeamSelector;