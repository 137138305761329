import { APPS, PermissionsList } from 'components/Common/constants';
import React, { lazy } from 'react';
import { Redirect, Switch } from 'react-router';
import PrivateRoute from 'routes/PrivateRoute';

const Members = lazy(() => import("apps/time/src/Members/Members"));
const MemberForm = lazy(() =>
    import("apps/time/src/Members/MemberForm/MemberForm")
);


const frontName = APPS.PeopleApp.frontName;
export const PeopleAppRoutesList = [
    `/${frontName}/:id/:tab?`,
    `/${frontName}`,
]
const waitFor = (Tag) => (props) => <Tag {...props} />;

const PeopleAppRoutes = () => {
    return (
        <>
            <Switch>
                <Redirect
                    exact
                    path={`/${frontName}/:id`}
                    to={`/${frontName}/:id/overview`}
                />
                <PrivateRoute
                    permissions={[PermissionsList.TIME_MEMBER_VIEW]}
                    exact
                    path={`/${frontName}/:id/:tab?`}
                    component={waitFor(MemberForm)}
                />
                <PrivateRoute
                    permissions={[PermissionsList.TIME_MEMBER_VIEW]}
                    exact
                    path={`/${frontName}`}
                    component={waitFor(Members)}
                />


            </Switch>
        </>
    )
}

export default PeopleAppRoutes
