import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectRoleById } from 'store/selectors'

const MemberRoleBadge = ({ roleId, className, seperator = '', projectRole }) => {
    const role = useSelector(state => selectRoleById(state, roleId));
    return (

        <>
            <span className={` ${className} text-capitalize`}>
                <span style={{ backgroundColor: 'inherit' }} color='none' size='sm'>{role && role.name}</span>
            </span>
            {projectRole &&

                <div>
                    <span className={` ${className} text-capitalize`}>
                        <span style={{ backgroundColor: 'inherit' }} color='none' size='sm'>{projectRole}</span>
                    </span>
                </div>
            }
            {seperator && role && role.name &&
                <span className='mx-1'>{'.'}</span>
            }
        </>
    )
}

MemberRoleBadge.propTypes = {
    role: PropTypes.string
}

export default MemberRoleBadge
