import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import httpService from "../../utils/httpService";

export const loadNotifications = createAsyncThunk('messages/load', async () => {
    const response = await httpService.get(`/messages`);
    return response.data
})

export const readMessage = createAsyncThunk('messages/read', async (id: number) => {
    await httpService.put(`/messages/read/${id}`);
    return { id }
})

export const loadMessageStatistic = createAsyncThunk('messages/statistic', async () => {
    const response = await httpService.get(`/messages/statistic`)
    return response.data
})

const initialState = {
    loading: false,
    notificationsStatistic: 0
}
const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: initialState,
    reducers: {
    },

    extraReducers: builder => {
        builder
            .addCase(loadMessageStatistic.fulfilled, (state, action) => {
                state.notificationsStatistic = action.payload.stat.unread;
            })
            .addCase(readMessage.fulfilled, (state) => {
                if (state.notificationsStatistic > 0) {
                    state.notificationsStatistic = state.notificationsStatistic - 1;
                }
            })
            .addMatcher(isAnyOf(loadMessageStatistic.pending), (state: any) => { state.loading = true })
            .addMatcher(isAnyOf(loadMessageStatistic.rejected, readMessage.rejected,
                loadMessageStatistic.fulfilled)
                , (state: any) => { state.loading = false })
    }
})

export default notificationsSlice.reducer