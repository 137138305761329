import React, { useState } from 'react'
import { Button, Modal, ModalBody, ModalFooter, Table } from 'reactstrap'
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import Paginate from 'components/Common/Paginate';
import { paginate } from 'utils/paginate';
import { GetStatusColor, InvitationStatuses, InviteModel } from 'apps/people/models/InviteModel';
import { getDisplayDate } from 'utils/dateHelper'
import { useAppSelector } from 'store/store';
import '../../styles/invitations.scss'
import { confirmInvite, rejectInvite } from 'store/actions';
import showToast from 'utils/toaster';
const MyInvitations = ({ closeModal }: { closeModal: Function }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const invites = useAppSelector(a => a.user.invites);
    const [currentPage, setCurrentPage] = useState(1);
    const [showConfirmNotice, setShowConfirmNotice] = useState(false);
    const [showRejectNotice, setShowRejectNotice] = useState(false);
    const [selectedInvitation, setSelectedInvitation] = useState<InviteModel>({} as InviteModel);
    const [confirming, setConfirming] = useState(false);
    const pageSize = 5;
    function onPageChange(currentPage: any) {
        setCurrentPage(currentPage.selected + 1)
    };

    const invitesToShow = paginate(invites, currentPage, pageSize) as InviteModel[]
    const confirm = () => {
        setConfirming(true);
        const confirmResult = dispatch<any>(confirmInvite(selectedInvitation.validationCode));
        if (!confirmResult.error) {
            showToast("confirm_succes_message")
        } else {
            showToast("confirm_succes_error", 'error')
        }

        setConfirming(false);
        setShowConfirmNotice(false);
        closeModal();
    }

    const reject = () => {
        setConfirming(true);
        const confirmResult = dispatch<any>(rejectInvite(selectedInvitation.validationCode));
        if (!confirmResult.error) {
            showToast("reject_succes_message")
        } else {
            showToast("reject_succes_error", 'error')
        }
        setConfirming(false);
        setShowRejectNotice(false);
        closeModal();
    }

    return (
        <div className="invitations">
            {invites.length > 0 ?
                <div>
                    <Table responsive bordered striped style={{ fontSize: 12 }}>
                        <thead>
                            <tr>
                                <th>{t('Organization Name')}</th>
                                <th>{t('inviteDate')}</th>
                                <th>{t('inviteStatus')}</th>
                                <th>{t('invitedByUser')}</th>
                                <th>{t('Operations')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invitesToShow.map(invitation => <tr key={invitation.id}>
                                <td> {invitation.organizationName} </td>
                                <td>

                                    {getDisplayDate(invitation.createdAt)}
                                </td>

                                <td style={{ color: GetStatusColor(invitation.status) }}>
                                    {t(invitation.status)}
                                </td>
                                <td>
                                    {invitation.invitedByUser}
                                </td>
                                <td>
                                    <div className="d-flex align-items-center ">

                                        <>

                                            <Button disabled={invitation.status === InvitationStatuses.Confirmed} className="text-success" onClick={() => {
                                                setShowConfirmNotice(true);
                                                setSelectedInvitation(invitation);
                                            }}>
                                                <i className='fa fa-check'></i>

                                            </Button>
                                            <Button disabled={invitation.status !== InvitationStatuses.Pending} className="text-danger" onClick={() => {
                                                setShowRejectNotice(true);
                                                setSelectedInvitation(invitation);
                                            }}>
                                                <i className='fa fa-times'></i>

                                            </Button>
                                        </>
                                    </div>

                                </td>
                            </tr>)}
                        </tbody>
                    </Table>
                    <Paginate
                        pageSize={pageSize}
                        itemsCount={invites.length}
                        onPageChange={onPageChange}
                        currentPage={currentPage} />
                </div>
                : <div className="center-container">
                    <div className="center-content empty-list-placeholder">
                        <div className="empty-list-content">
                            {t('There is no invitation')}
                        </div>
                    </div>

                </div>
            }
            <Modal isOpen={showConfirmNotice}>
                <ModalBody className="d-flex justify-content-center align-items-center flex-column">
                    <h4 >
                        {selectedInvitation.organizationName}
                    </h4>
                    <div>
                        {t('confirmInvitationMessage')}
                    </div>

                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setShowConfirmNotice(false)}>{t('cancel')}</Button>
                    <Button disabled={confirming} color="success" onClick={confirm}>
                        {t('confirm_button_text')}
                        {confirming ? <i className="m-start-1 fas fa-spinner fa-spin"></i> : ''}
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={showRejectNotice}>
                <ModalBody className="d-flex justify-content-center align-items-center flex-column">
                    <h4 >
                        {selectedInvitation.organizationName}
                    </h4>
                    <div>
                        {t('rejectInvitationMessage')}
                    </div>

                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setShowRejectNotice(false)}>{t('cancel')}</Button>
                    <Button disabled={confirming} color="danger" onClick={reject}>
                        {t('reject_button_text')}
                        {confirming ? <i className="m-start-1 fas fa-spinner fa-spin"></i> : ''}
                    </Button>
                </ModalFooter>
            </Modal>
        </div >
    )
}

MyInvitations.propTypes = {

}

export default MyInvitations
