import React from 'react'
import Select from 'react-select'
import { useTranslation } from 'react-i18next';

const SelectComponent = ({ isDisabled, isClearable = false, isMulti = false, placeholder, classNames = "", styles = {},
    selectedOption, onChange, options, customSelectStyles, maxMenuHeight = 140 }:
    {
        isDisabled?: boolean, placeholder: string, isClearable?: boolean, isMulti?: boolean, classNames: string, styles: {}, selectedOption: any,
        maxMenuHeight?: number | null,
        onChange: any, options: any, customSelectStyles?: any
    }) => {
    const { t } = useTranslation();
    let selectStyles = {
        option: (provided: any) => ({
            ...provided,
            height: 32,
            minHeight: 32,
            fontSize: 12
        }),
        input: (provided: any) => ({
            ...provided,
            paddingTop: -10
        }),
        control: (base: any, state: any) => ({
            ...base,
            ...styles,
            width: 'auto',
            flexWrap: 'nowrap',
            height: !isMulti ? 32 : null,
            minHeight: 32,
            fontSize: 12,
            border: `${state.isFocused ? '1px solid var(--primary)' : ''}`,
            boxShadow: 'none',
            '&:hover': {
                border: '',
            }
        })
    };
    if (customSelectStyles) {
        selectStyles = customSelectStyles;
    }




    let components = {};
    if (isMulti) {
        components = { DropdownIndicator: () => null, IndicatorSeparator: () => null }
    }
    return (
        <Select
            // menuIsOpen={true}
            maxMenuHeight={maxMenuHeight ? maxMenuHeight : 15000}
            components={components}
            isDisabled={isDisabled}
            isMulti={isMulti}
            isClearable={isClearable}
            className={classNames}
            placeholder={<div className="select-placeholder-text">{placeholder ? placeholder : t('Select')}</div>}
            value={selectedOption}
            onChange={onChange}
            options={options}
            styles={selectStyles}
            noOptionsMessage={() => null}
        />
    )
}

SelectComponent.propTypes = {}

export default SelectComponent