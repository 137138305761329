
export enum InvitationStatuses {
    Pending = 'pending',
    Confirmed = 'confirmed',
    Rejected = 'rejected',
    Removed = 'removed',
    Inactive = 'Inactive',
    Active = "active",
    Canceled = "canceled"
}

export type InviteModel = {
    id: number,
    email: string,
    createdAt: string
    status: InvitationStatuses,
    validationCode: string,
    organizationName: string,
    invitedByUser: string
}

export const GetStatusColor = (status: InvitationStatuses) => {
    const colors = [
        { status: InvitationStatuses.Pending, color: 'orange' },
        { status: InvitationStatuses.Confirmed, color: 'green' },
        { status: InvitationStatuses.Rejected, color: 'red' },
        { status: InvitationStatuses.Active, color: 'black' },
        { status: InvitationStatuses.Removed, color: 'red' },
        { status: InvitationStatuses.Inactive, color: 'red' },
        { status: InvitationStatuses.Canceled, color: 'red' }
    ];
    return colors.find(a => a.status === status)?.color;
}